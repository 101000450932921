import React, { useMemo } from 'react';
import { Form, Input } from 'antd';
import queryString from 'query-string';

import { useLocation } from 'react-router';
import { FILTER_PARAMS_PREFIX } from 'appConstants';
import UserAutoComplete from 'containers/UserAutoComplete';

const UserAutoCompleteFilter = ({ form }) => {
  const { search } = useLocation();

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const createdBy = useMemo(() => {
    if (
      !searchParams[FILTER_PARAMS_PREFIX + 'createdBy'] ||
      !searchParams.createdByName
    )
      return null;

    return {
      guid: searchParams[FILTER_PARAMS_PREFIX + 'createdBy'],
      name: searchParams.createdByName
    };
  }, [searchParams]);

  const onSelect = (_, record) => {
    form.setFieldsValue({ createdByName: record.label || record.children });
  };

  const onClear = () => {
    form.resetFields(['createdByName']);
  };

  return (
    <>
      <Form.Item
        name={FILTER_PARAMS_PREFIX + 'createdBy'}
        label="Biên tập viên"
      >
        <UserAutoComplete
          allowClear
          user={createdBy}
          onSelect={onSelect}
          onClear={onClear}
        />
      </Form.Item>

      <Form.Item name="createdByName" style={{ display: 'none' }}>
        <Input readOnly />
      </Form.Item>
    </>
  );
};

export default UserAutoCompleteFilter;
