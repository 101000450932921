import { Button, Drawer, Space } from 'antd';
import { SortAscendingOutlined } from '@ant-design/icons';
import React, { useCallback, useRef, useState } from 'react';
import SubjectOrderContent from './SubjectOrderContent';
import { useSubject } from 'stores/useSubject';
import shallow from 'zustand/shallow';

const SubjectOrder = () => {
  const childRef = useRef(null);
  const { updateSubjectOrder } = useSubject(
    useCallback(
      ({ updateSubjectOrder }) => ({
        updateSubjectOrder
      }),
      []
    ),
    shallow
  );

  const [expand, setExpand] = useState(false);

  const closeDrawer = () => setExpand(false);
  const openDrawer = () => setExpand(true);

  const onSave = async () => {
    const list = childRef.current?.subjects;

    if (!list) {
      return;
    }

    await updateSubjectOrder(list.map(({ id }) => ({ id })).reverse());

    closeDrawer();
  };

  return (
    <>
      <Button onClick={openDrawer} icon={<SortAscendingOutlined />}>
        Độ ưu tiên
      </Button>

      <Drawer
        title="Độ ưu tiên môn học"
        visible={expand}
        placement="right"
        width={450}
        onClose={closeDrawer}
        closable={false}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={closeDrawer}>Đóng</Button>

            <Button type="primary" onClick={onSave}>
              Lưu
            </Button>
          </Space>
        }
      >
        <SubjectOrderContent ref={childRef} />
      </Drawer>
    </>
  );
};

export default SubjectOrder;
