import React from 'react';
import { Button, Drawer, Space } from 'antd';

const UpdateCreateModal = ({ onClose, children, onOk, ...props }) => {
  return (
    <Drawer
      placement="right"
      width={600}
      onClose={onClose}
      destroyOnClose
      closable={false}
      extra={
        <Space>
          <Button onClick={onClose}>Hủy</Button>
          <Button type="primary" onClick={onOk}>
            OK
          </Button>
        </Space>
      }
      {...props}
    >
      {React.cloneElement(children)}
    </Drawer>
  );
};

export default UpdateCreateModal;
