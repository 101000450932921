import { Select } from 'antd';
import { useServices } from 'hooks/useServices';
import React, { useCallback, useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

const MasterDataSelect = ({ placeholder, groupCode, ...props }) => {
  const [options, setOptions] = useState([]);
  const { searchForPermissionCode } = useServices(
    useCallback(
      ({ searchForPermissionCode }) => ({
        searchForPermissionCode
      }),
      []
    ),
    shallow
  );

  const handleFetchMasterData = useCallback(async () => {
    const data = await searchForPermissionCode(null, groupCode);
    setOptions(data?.map(({ code, name }) => ({ value: code, label: name })));
  }, [searchForPermissionCode, groupCode]);

  useEffect(() => {
    handleFetchMasterData();

    return () => {
      setOptions([]);
    };
  }, [handleFetchMasterData]);

  return (
    <Select
      options={options}
      placeholder={placeholder}
      style={{ width: '100%' }}
      allowClear
      showSearch
      optionFilterProp="label"
      {...props}
    />
  );
};

export default MasterDataSelect;
