import React, { useCallback, useContext } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Collapse,
  Typography
} from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
// import Upload from 'components/Upload';
import { ArticleDetailContext } from 'pages/ArticleDetail';

import { useModule } from 'stores/useModule';
import shallow from 'zustand/shallow';
import { useSection } from 'stores/useSection';
import { useArticleDetail } from 'stores/useArticleDetail';
import ArticleSyllabusAutoComplete from './ArticleSyllabusAutoComplete';
import { useParams } from 'react-router';
import SlugFormItem from 'components/SlugFormItem';

const { Panel } = Collapse;

const ArticleInfoForm = () => {
  const { isEditingArticle, form } = useContext(ArticleDetailContext);
  const { id } = useParams();

  const { fetchModules } = useModule(
    useCallback(
      ({ fetchModules }) => ({
        fetchModules
      }),
      []
    ),
    shallow
  );

  const { fetchSections } = useSection(
    useCallback(
      ({ fetchSections }) => ({
        fetchSections
      }),
      []
    ),
    shallow
  );

  const {
    setSectionOptions,
    setModuleOptions,
    sectionOptions,
    moduleOptions,
    moduleCondition,
    setModuleCondition,
    updateArticle
  } = useArticleDetail(
    useCallback(
      ({
        setSectionOptions,
        setModuleOptions,
        sectionOptions,
        moduleOptions,
        moduleCondition,
        setModuleCondition,
        updateArticle
      }) => ({
        setSectionOptions,
        setModuleOptions,
        sectionOptions,
        moduleOptions,
        moduleCondition,
        setModuleCondition,
        updateArticle
      }),
      []
    ),
    shallow
  );

  const onSelectSyllabus = async value => {
    const params = {
      SyllabusId: value,
      Page: 0,
      PageSize: 1000
    };
    const { data: sections } = await fetchSections(params);
    const { data: modules } = await fetchModules(params);

    setModuleOptions(modules);
    setSectionOptions(sections);
    setModuleCondition(null);

    form.resetFields(['sectionId', 'moduleId']);
  };

  const onSelectSection = value => {
    setModuleCondition(value);
    form.resetFields(['moduleId']);
  };

  const onSave = async () => {
    try {
      const params = await form.validateFields([
        'title',
        'slug',
        'description',
        'syllabusId',
        'moduleId'
      ]);
      await updateArticle(params, id);
    } catch (e) {
      form.setFields(
        Object.entries(e.data).map(([key, value]) => ({
          name: [key[0].toLocaleLowerCase() + key.slice(1)],
          errors: value
        }))
      );
    }
  };

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel
        header={<Typography.Text strong>Thông tin bài học</Typography.Text>}
        key="1"
      >
        <>
          <Form.Item
            label="Tiêu đề"
            name="title"
            rules={[{ required: true, message: 'Nhập tiêu đề!' }]}
          >
            <Input placeholder="Nhập tiêu đề" />
          </Form.Item>

          <SlugFormItem form={form} target="title" />

          <Form.Item name="description" label="Mô tả">
            <Input.TextArea placeholder="Nhập mô tả" />
          </Form.Item>

          <Form.Item
            label="Giáo trình"
            name="syllabusId"
            rules={[{ required: true, message: 'Chọn giáo trình!' }]}
          >
            <ArticleSyllabusAutoComplete onSelect={onSelectSyllabus} />
          </Form.Item>

          <Row gutter={32}>
            <Col span={12}>
              <Form.Item label="Phần" name="sectionId">
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder="Chọn phần"
                  options={sectionOptions}
                  onSelect={onSelectSection}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="moduleId"
                label="Chương"
                rules={[{ required: true, message: 'Chọn chương!' }]}
              >
                <Select
                  placeholder="Chọn chương"
                  showSearch
                  optionFilterProp="label"
                  options={
                    moduleCondition
                      ? moduleOptions.filter(
                          item => item?.sectionId === moduleCondition
                        )
                      : moduleOptions
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          {/* 
      <Form.Item label="File tài liệu">
        <Upload>
          <Button type="primary" icon={<UploadOutlined />} />
        </Upload>
      </Form.Item> */}

          {isEditingArticle && (
            <Row justify="end">
              <Button onClick={onSave} type="primary">
                Lưu
              </Button>
            </Row>
          )}
        </>
      </Panel>
    </Collapse>
  );
};

export default ArticleInfoForm;
