import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useClassSubject } from 'stores/useClassSubject';
import shallow from 'zustand/shallow';

import { setError } from 'utils/other';
import ClassSubjectFormContent from './ClassSubjectFormContent';
import UpdateCreateModal from 'containers/UpdateCreateModal';

const ClassSubjectForm = () => {
  const {
    classSubjectToEdit,
    setClassSubjectToEdit,
    updateClassSubject,
    setDrawerVisible,
    drawerVisible
  } = useClassSubject(
    useCallback(
      ({
        classSubjectToEdit,
        setClassSubjectToEdit,
        updateClassSubject,
        setDrawerVisible,
        drawerVisible
      }) => ({
        classSubjectToEdit,
        setClassSubjectToEdit,
        updateClassSubject,
        setDrawerVisible,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updateClassSubject(params, classSubjectToEdit.id);
      setClassSubjectToEdit(null);
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  return (
    <UpdateCreateModal
      title={'Chỉnh sửa môn lớp'}
      onOk={onSave}
      onClose={() => {
        setDrawerVisible(false);
        setClassSubjectToEdit(null);
      }}
      visible={drawerVisible}
    >
      <ClassSubjectFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default ClassSubjectForm;
