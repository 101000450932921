import axios from 'axios';

import {
  getAccessToken,
  getRefreshToken,
  setRefreshToken,
  setAccessToken
} from 'utils/authority';
import useAuth from 'stores/useAuth';

import { processFilerParams } from 'utils/other';

const logout = useAuth.getState().logout;
const refreshAccessToken = useAuth.getState().refreshAccessToken;

const mainAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API}`
});

function baseConfig(config) {
  const params = { ...config.params };

  const token = getAccessToken();

  config.headers.Authorization = `Bearer ${token}`;
  config.params = processFilerParams(params);

  return config;
}

const errorHandler = async error => {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest.__retry) {
    originalRequest.__retry = true;

    const { token, refreshToken } = await refreshAccessToken(
      getAccessToken(),
      getRefreshToken(),
      logout
    );

    setAccessToken(token);
    setRefreshToken(refreshToken);

    axios.defaults.headers['Authorization'] = 'Bearer ' + token;

    return mainAxios(originalRequest);
  }

  return Promise.reject(error.response);

  // const response = error?.response;

  // // Signout logic here
  // if (response && response?.status === 401) {
  //   console.log({ error });
  //   console.log(getRefreshToken());
  //   // window.location.href = window.location.origin + '/dang-nhap';
  //   // logout();
  //   // return Promise.reject(new Error('Unauthorized'));
  // }

  // if (response && response?.status) {
  //   return Promise.reject(response);
  // }

  // return Promise.reject(error);
};

mainAxios.interceptors.request.use(
  defaultConfig => {
    return baseConfig(defaultConfig);
  },
  error => Promise.reject(error)
);

mainAxios.interceptors.response.use(
  response => response,
  error => errorHandler(error)
);

export default mainAxios;
