import { Typography } from 'antd';
import React, { useMemo } from 'react';

import LessionList from './components/ArticleList';

import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import { useLocation } from 'react-router';
import ArticleFilter from './ArticleFilter';
import { generateFilterParamsCount } from 'utils/other';

const Article = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div>
      <Typography.Title>Bài học</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={LessionList}
        secondary={ArticleFilter}
        filterDrawerTitle="Lọc bài học"
      />
    </div>
  );
};

export default Article;
