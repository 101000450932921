import { Button, Collapse, Row, Tag, Typography } from 'antd';
import { AUTO_SAVE_INTERVAL, STATUS_VALUE } from 'appConstants';

import EduEditor from 'containers/EduEditor';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useExerciseDetail } from 'stores/useExerciseDetail';
import shallow from 'zustand/shallow';
const { Panel } = Collapse;

const ExerciseMainContent = ({ isEditingExercise, id }) => {
  const questionRef = useRef(null);
  const answerRef = useRef(null);
  const saveInterval = useRef(null);

  const { createExercise, getExercise, updateExercise, reset, exercise } =
    useExerciseDetail(
      useCallback(
        ({ createExercise, getExercise, updateExercise, reset, exercise }) => ({
          createExercise,
          getExercise,
          updateExercise,
          reset,
          exercise
        }),
        []
      ),
      shallow
    );

  const onClick = useCallback(
    async auto => {
      try {
        const question = questionRef.current.getContent();
        const answer = answerRef.current.getContent();

        if (!question || !answer) return;

        const params = {
          question,
          answer
        };

        await updateExercise(params, id, auto);
      } catch (e) {}
    },
    [id, updateExercise]
  );

  useEffect(() => {
    if (exercise?.status === STATUS_VALUE.PUBLISHED) return;

    saveInterval.current = setInterval(() => onClick(true), AUTO_SAVE_INTERVAL);

    return () => {
      if (saveInterval.current) {
        clearInterval(saveInterval.current);
      }
    };
  }, [exercise, onClick]);

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel
        header={
          <Row justify="space-between" style={{ width: '100%' }}>
            <Typography.Text strong>Nội dung</Typography.Text>

            <Tag color="blue">
              Tự động lưu mỗi {AUTO_SAVE_INTERVAL / 1000}s.
            </Tag>
          </Row>
        }
        key={'1'}
      >
        <Collapse
          className="exercise-detail-card"
          defaultActiveKey={['1', '2']}
          ghost
        >
          <Panel
            header={<span className="panel-label-required">Câu hỏi</span>}
            key="1"
          >
            <EduEditor ref={questionRef} initialValue={exercise?.question} />
          </Panel>

          <Panel
            header={<span className="panel-label-required">Đáp án</span>}
            key="2"
          >
            <EduEditor ref={answerRef} initialValue={exercise?.answer} />
          </Panel>
        </Collapse>

        <Button
          block
          type="primary"
          onClick={onClick}
          style={{ marginTop: 12 }}
        >
          {isEditingExercise ? 'Lưu' : 'Tạo'}
        </Button>
      </Panel>
    </Collapse>
  );
};

export default ExerciseMainContent;
