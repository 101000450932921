import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

export const useExerciseDetail = create((set, get) => ({
  isExerciseDetailLoading: false,
  setIsExerciseDetailLoading: isExerciseDetailLoading =>
    set({ isExerciseDetailLoading }),

  isCreatingExercise: false,
  setIsCreatingExercise: isCreatingExercise => set({ isCreatingExercise }),

  //* ***************** */
  //* NOTE: get exercise */
  //* ***************** */

  exercise: null,

  reset: () => {
    set({ exercise: null });
  },

  getExercise: async id => {
    get().setIsExerciseDetailLoading(true);

    try {
      const { data } = await API.get(`/exercise/${id}`);

      get().setIsExerciseDetailLoading(false);

      if (data) {
        set({ exercise: data });

        return data;
      }

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch (data) {
      get().setIsExerciseDetailLoading(false);

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************** */
  //* NOTE: create exercise */
  //* ******************** */

  createExercise: async params => {
    get().setIsCreatingExercise(true);

    try {
      const { data } = await API.post(
        params?.articleId
          ? `/article/${params.articleId}/exercises`
          : '/exercise',
        {
          ...params
        }
      );

      get().setIsCreatingExercise(false);

      if (data) {
        notification.success({
          message: 'Tạo bài tập thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch (e) {
      get().setIsCreatingExercise(false);

      notification.error({
        message: e?.data?.title || DEFAULT_ERROR_MESSAGE
      });

      throw e;
    }
  },

  //* ******************** */
  //* NOTE: update exercise */
  //* ******************** */

  updateExercise: async (params, id, auto) => {
    get().setIsExerciseDetailLoading(true);

    try {
      const { data } = await API.put(`/exercise/${id}`, params);

      get().setIsExerciseDetailLoading(false);

      if (data) {
        notification.success({
          message: auto
            ? 'Tự động cập nhật nội dung thành công!'
            : 'Cập nhật bài tập thành công!'
        });

        // set({ exercise: data });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || 'DEFAULT_ERROR_MESSAGE'
      });

      return null;
    } catch (data) {
      get().setIsExerciseDetailLoading(false);

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  attachExerciseToArticles: async (articleIds, id) => {
    get().setIsExerciseDetailLoading(true);

    try {
      const { data } = await API.patch(`/exercise/${id}/articles`, {
        articleIds
      });

      get().setIsExerciseDetailLoading(false);

      if (data) {
        notification.success({
          message: 'Cập nhật bài tập thành công!'
        });

        //do something

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || 'DEFAULT_ERROR_MESSAGE'
      });

      return null;
    } catch (data) {
      get().setIsExerciseDetailLoading(false);

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getArticlesByExercise: async id => {
    try {
      const { data } = await API.get(`/exercise/${id}/articles`);

      if (data) {
        return data.data;
      }

      notification.error({
        message: data?.errorMessages?.message || 'DEFAULT_ERROR_MESSAGE'
      });

      return null;
    } catch (data) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
