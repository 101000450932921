import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';

import { useSyllabus } from 'stores/useSyllabus';

const SyllabusAutoComplete = ({ syllabus, ...props }) => {
  const { searchForSyllabuses } = useSyllabus(
    useCallback(
      ({ searchForSyllabuses }) => ({
        searchForSyllabuses
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      syllabus
        ? Array.isArray(syllabus)
          ? syllabus.map(({ id, name }) => ({ value: id, label: name }))
          : { value: syllabus.id, label: syllabus.name }
        : null,
    [syllabus]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      handleSearch={searchForSyllabuses}
      labelKey="name"
      valueKey="id"
      placeholder="Tìm giáo trình"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default SyllabusAutoComplete;
