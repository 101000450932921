import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useSyllabus } from 'stores/useSyllabus';
import shallow from 'zustand/shallow';

import { setError } from 'utils/other';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import SyllabusFormContent from './SyllabusFormContent';

const SyllabusForm = () => {
  const {
    syllabusToEdit,
    setSyllabusToEdit,
    updateSyllabus,
    createSyllabus,
    setDrawerVisible,
    drawerVisible
  } = useSyllabus(
    useCallback(
      ({
        syllabusToEdit,
        setSyllabusToEdit,
        updateSyllabus,
        createSyllabus,
        setDrawerVisible,
        drawerVisible
      }) => ({
        syllabusToEdit,
        setSyllabusToEdit,
        updateSyllabus,
        createSyllabus,
        setDrawerVisible,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updateSyllabus(params, syllabusToEdit.id);
      setSyllabusToEdit(null);
      setDrawerVisible(false);
    } catch (errors) {
      setError(form, errors);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createSyllabus(params);
      setDrawerVisible(false);
      form.resetFields();
    } catch (errors) {
      setError(form, errors);
    }
  };

  return (
    <UpdateCreateModal
      title={syllabusToEdit ? 'Chỉnh sửa giáo trình' : 'Tạo giáo trình'}
      onOk={syllabusToEdit ? onSave : onCreate}
      onClose={() => {
        setSyllabusToEdit(null);
        setDrawerVisible(false);
      }}
      visible={drawerVisible}
      destroyOnClose
    >
      <SyllabusFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default SyllabusForm;
