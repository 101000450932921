import { Badge, Button } from 'antd';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { STATUS_VALUE } from 'appConstants';

const DeleteListPopup = ({
  selectedRows,
  deleteService,
  setSelectedRows,
  additionalContidion = true
}) => {
  return selectedRows.length &&
    selectedRows.every(item => item?.status === STATUS_VALUE.DRAFT) > 0 &&
    additionalContidion ? (
    <DeleteConfirmPopup
      onDelete={() => {
        deleteService(selectedRows);
        setSelectedRows([]);
      }}
      title={`Xóa ${selectedRows.length} item(s). Nghĩ kỹ chưa dạ 👀👀?`}
    >
      <Badge size="small" count={selectedRows.length}>
        <Button type="primary" danger icon={<DeleteOutlined />} />
      </Badge>
    </DeleteConfirmPopup>
  ) : (
    <></>
  );
};

export default DeleteListPopup;
