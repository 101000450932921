import { Typography } from 'antd';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { generateFilterParamsCount } from 'utils/other';
import FeedbackFilter from './FeedbackFilter';
import FeedBackList from './FeedBackList';
import FeedbackViewingDrawer from './components/FeedbackViewingDrawer';

const FeedBack = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div>
      <Typography.Title>Phản hồi</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={FeedBackList}
        secondary={FeedbackFilter}
        filterDrawerTitle="Lọc phản hồi"
      />

      <FeedbackViewingDrawer />
    </div>
  );
};

export default FeedBack;
