import React, { useCallback, useState } from 'react';
import { Col, Row, Typography } from 'antd';

import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';

import AvatarUpload from 'components/AvatarUpload';

const CurrentUserAvatar = () => {
  const [loading, setLoading] = useState(false);

  const { currentUser, updateCurrentUserInfo } = useCurrentUser(
    useCallback(
      ({ currentUser, updateCurrentUserInfo }) => ({
        currentUser,
        updateCurrentUserInfo
      }),
      []
    ),
    shallow
  );

  const onDone = useCallback(
    async image => {
      setLoading(true);

      await updateCurrentUserInfo({
        image
      });

      setLoading(false);
    },
    [updateCurrentUserInfo]
  );

  return (
    <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
      <Col>
        <AvatarUpload
          uploadType="user"
          size={128}
          fontSize={64}
          loading={loading}
          fullName={currentUser?.name}
          avatar={currentUser?.image}
          onDone={onDone}
        />
      </Col>

      <Col>
        <Typography.Title>{currentUser?.name}</Typography.Title>
      </Col>
    </Row>
  );
};

export default CurrentUserAvatar;
