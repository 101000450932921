import React, { useMemo } from 'react';
import { Typography } from 'antd';

import UserList from './components/UserList';
import UserFilter from './UserFilter';

import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import { useLocation } from 'react-router';
import UserForm from './UserForm';

import { generateFilterParamsCount } from 'utils/other';
import UserLoginHistory from './UserLoginHistory';

const User = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div className="spacing-container">
      <Typography.Title>Biên tập viên</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={UserList}
        secondary={UserFilter}
        filterDrawerTitle="Lọc biên tập viên"
      />

      <UserForm />

      <UserLoginHistory />
    </div>
  );
};

export default User;
