import React, { useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Space, Typography, Button } from 'antd';

import { useSection } from 'stores/useSection';
import shallow from 'zustand/shallow';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import { PERMISSION_ACTION } from 'appConstants';
import PermissionButton from 'containers/PermissionButton';
import TableRecordName from 'components/TableRecordName';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import StatusButton from 'components/StatusButton';

const EditSectionBtn = record => {
  const { deleteSection } = useSection(
    useCallback(
      ({ deleteSection }) => ({
        deleteSection
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <DeleteConfirmPopup
        onDelete={() => deleteSection(record.id)}
        title={'Xoá ' + record?.name + ' ?'}
      >
        <Button type="default" size="small" icon={<DeleteOutlined />} />
      </DeleteConfirmPopup>
    </Space>
  );
};

const Status = ({ record }) => {
  const { updateSection } = useSection(
    useCallback(
      ({ updateSection }) => ({
        updateSection
      }),
      []
    ),
    shallow
  );

  const onClick = key => {
    return updateSection(
      {
        ...record,
        status: key
      },
      record.id
    );
  };

  return <StatusButton status={record?.status} onClick={onClick} />;
};

const Name = (name, record) => {
  const { setSectionToEdit, setDrawerVisible } = useSection(
    useCallback(
      ({ setSectionToEdit, setDrawerVisible }) => ({
        setSectionToEdit,

        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setSectionToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Tên phần',
    dataIndex: 'name',
    render: Name,
    align: 'left',
    width: '300px',
    fixed: 'left'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => <Status record={record} />,
    width: '220px'
  },
  {
    title: 'Phần số',
    dataIndex: 'sectionNo',
    key: 'sectionNo',
    render: sectionNo => <Typography.Text>{sectionNo}</Typography.Text>,
    width: '90px'
  },
  {
    title: 'Số lượng chương',
    dataIndex: 'moduleStatus',
    render: moduleStatus => (
      <Typography.Text>{moduleStatus?.total}</Typography.Text>
    ),
    width: '120px'
  },
  {
    title: 'Số lượng bài',
    dataIndex: 'articleStatus',
    render: articleStatus => (
      <Typography.Text>{articleStatus?.total}</Typography.Text>
    ),
    width: '90px'
  },
  // {
  //   title: 'Mô tả',
  //   dataIndex: 'description',
  //   key: 'description',
  //   render: description => <Typography.Text>{description}</Typography.Text>,
  //   width: '30%'
  // },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '10%',
    render: EditSectionBtn,
    toggleEdit: true,
    align: 'center'
  }
];
