import React, { useCallback } from 'react';
import { Form } from 'antd';
import { usePublisher } from 'stores/usePublisher';
import shallow from 'zustand/shallow';

import { setError } from 'utils/other';
import PublisherFormContent from './PublisherFormContent';
import UpdateCreateModal from 'containers/UpdateCreateModal';

const PublisherForm = () => {
  const {
    publisherToEdit,
    setPublisherToEdit,
    createPublisher,
    updatePublisher,
    setDrawerVisible,
    drawerVisible
  } = usePublisher(
    useCallback(
      ({
        publisherToEdit,
        setPublisherToEdit,
        createPublisher,
        updatePublisher,
        setDrawerVisible,
        drawerVisible
      }) => ({
        publisherToEdit,
        setPublisherToEdit,
        createPublisher,
        updatePublisher,
        setDrawerVisible,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updatePublisher(params, publisherToEdit.id);
      setPublisherToEdit(null);
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createPublisher(params);
      form.resetFields();
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  return (
    <UpdateCreateModal
      title={publisherToEdit ? 'Chỉnh sửa nhà xuất bản' : 'Tạo nhà xuất bản'}
      onOk={publisherToEdit ? onSave : onCreate}
      onClose={() => {
        setDrawerVisible(false);
        setPublisherToEdit(null);
      }}
      visible={drawerVisible}
    >
      <PublisherFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default PublisherForm;
