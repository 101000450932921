import React, { useCallback, useContext } from 'react';
import { Card, Row, Form, Radio, Button, InputNumber, Input } from 'antd';
import { useParams } from 'react-router';
import { BookOutlined } from '@ant-design/icons';
import shallow from 'zustand/shallow';

import { ArticleDetailContext } from 'pages/ArticleDetail';
import AvatarUpload from 'components/AvatarUpload';
import { useArticleDetail } from 'stores/useArticleDetail';
import DatePicker from 'components/DatePicker';
import ArticleTemplateInput from './ArticleTemplateInput';
import ArticleTypeFormItems from 'pages/ArticleDetail/ArticleTypeFormItems';
import { DATE_FORMAT } from 'appConstants';
import moment from 'moment';

const ArticleDetailRight = () => {
  const { isEditingArticle, form } = useContext(ArticleDetailContext);
  const { id } = useParams();

  const { updateArticle } = useArticleDetail(
    useCallback(
      ({ updateArticle }) => ({
        updateArticle
      }),
      []
    ),
    shallow
  );

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.file;
  };

  const onSave = async () => {
    try {
      const params = await form.validateFields([
        'code',
        'isPrivate',
        'bookTypeId',
        'publishedDate',
        'articleNo',
        'pageNo',
        'image',
        'source'
      ]);

      updateArticle(
        {
          ...params,
          image: params?.image?.response?.preview || params.image,
          publishedDate: params.publishedDate || moment()
        },
        id
      );
    } catch (e) {}
  };

  return (
    <div className="spacing-container">
      <Card title="Thông tin khác">
        <ArticleTemplateInput />

        <Form.Item
          label="Trạng thái"
          name="isPrivate"
          initialValue={true}
          rules={[{ required: true, message: 'Chọn trạng thái!' }]}
        >
          <Radio.Group>
            <Radio value={false}>Công khai</Radio>
            <Radio value={true}>Riêng tư</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Thời gian đăng" name="publishedDate">
          <DatePicker
            style={{ width: '100%' }}
            showTime
            format={DATE_FORMAT + ' - hh:mm:ss'}
          />
        </Form.Item>

        <Row justify="center">
          <Form.Item
            name="image"
            valuePropName="file"
            getValueFromEvent={normFile}
          >
            <AvatarUpload
              uploadType="article"
              size={102}
              fontSize={64}
              defaultAvatar={<BookOutlined />}
            />
          </Form.Item>
        </Row>

        <ArticleTypeFormItems form={form} />

        <Form.Item
          label="Bài số"
          name="articleNo"
          rules={[{ required: true, message: 'Nhập số bài!' }]}
        >
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder="Nhập bài số"
          />
        </Form.Item>

        <Form.Item name="source" label="Nguồn">
          <Input placeholder="Nhập" />
        </Form.Item>

        {isEditingArticle && (
          <Row justify="end" style={{ marginTop: 24 }}>
            <Button onClick={onSave} type="primary">
              Lưu
            </Button>
          </Row>
        )}
      </Card>
    </div>
  );
};

export default ArticleDetailRight;
