import React, { useCallback, useMemo } from 'react';
import Header from 'containers/Header';
import Sidebar from 'components/Sidebar';
import { Switch, Redirect, BrowserRouter, Route } from 'react-router-dom';
import { privateRoutes, publicRoutes } from 'routes';
import { Layout } from 'antd';

import useAuth from 'stores/useAuth';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import QNAWidget from 'containers/FeedbackWidget';

const { Content } = Layout;

const MainLayout = () => {
  const isLoggedIn = useAuth(useCallback(({ isLoggedIn }) => isLoggedIn, []));
  const params = useMemo(
    () =>
      '?redirect=' +
      encodeURIComponent(
        window.location.href.replace(window.location.origin, '')
      ),
    []
  );

  return (
    <BrowserRouter>
      <Layout style={{ minHeight: '100vh' }}>
        <Switch>
          {publicRoutes.map(route => (
            <PublicRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
              isLoggedIn={isLoggedIn}
            >
              <route.component />
            </PublicRoute>
          ))}
          {isLoggedIn ? (
            <Route>
              <Sidebar />
              <Layout className="site-layout">
                <Header />
                <Content className="navi-content-wrapper">
                  <Switch>
                    {privateRoutes.map(route => (
                      <PrivateRoute
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        keyCode={route?.keyCode}
                        isLoggedIn={isLoggedIn}
                      >
                        <route.component />
                      </PrivateRoute>
                    ))}
                    <Route path="*">
                      <Redirect to="/404" />
                    </Route>
                  </Switch>
                </Content>
              </Layout>
            </Route>
          ) : (
            <Redirect to={'/dang-nhap' + params} />
          )}
        </Switch>

        {isLoggedIn && <QNAWidget />}
      </Layout>
    </BrowserRouter>
  );
};

export default MainLayout;
