import { Space, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import UserCard from './components/UserCard';

const UsersContainer = () => {
  const { users } = useDashboard(
    useCallback(
      ({ users }) => ({
        users
      }),
      []
    ),
    shallow
  );

  return (
    <div
      style={{
        background: 'white',
        padding: '10px',
        marginTop: '16px',
        borderRadius: '8px'
      }}
    >
      <Typography.Title level={5} strong>
        Biên tập viên
      </Typography.Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        {users?.map(user => (
          <UserCard key={user?.id} user={user} />
        ))}
      </Space>
    </div>
  );
};

export default UsersContainer;
