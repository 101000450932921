import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';

import { useServices } from 'hooks/useServices';

const SectionAutoComplete = ({ section, ...props }) => {
  const { searchForSections } = useServices(
    useCallback(
      ({ searchForSections }) => ({
        searchForSections
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () => (section ? { value: section.id, label: section.name } : null),
    [section]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeholder="Tìm phần"
      handleSearch={searchForSections}
      labelKey="name"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default SectionAutoComplete;
