import { Typography } from 'antd';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { generateFilterParamsCount } from 'utils/other';
import SectionList from './components/SectionList';
import SectionForm from './SectionForm';
import SectionFilter from './SectionFilter';

const Section = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div>
      <Typography.Title>Phần</Typography.Title>

      <SectionForm />

      <DynamicFlexLayout
        badgeCount={count}
        primary={SectionList}
        secondary={SectionFilter}
        filterDrawerTitle="Lọc phần"
      />
    </div>
  );
};

export default Section;
