import React, { useCallback, useEffect, useMemo } from 'react';
import { Space, Button, Table } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import { columns } from '../columns';
import { useSubject } from 'stores/useSubject';
import {
  ORDER_BY_SELECT_VALUE,
  PAGE_SIZE,
  PERMISSION_ACTION,
  TABLE_PROPS
} from 'appConstants';

import SortSelect from 'components/SortSelect';
import StatusSelectForList from 'components/StatusSelectForList';
import PermissionButton from 'containers/PermissionButton';
import SubjectOrder from '../SubjectOrder';
import ListCard from 'components/ListCard';

const SubjectList = () => {
  const {
    getSubjects,
    subjects,
    isSubjectListLoading,
    total,
    selectedRows,
    setSelectedRows,
    updateSubjectStatus,
    setDrawerVisible
  } = useSubject(
    useCallback(
      ({
        getSubjects,
        subjects,
        isSubjectListLoading,
        total,
        selectedRows,
        setSelectedRows,
        updateSubjectStatus,
        setDrawerVisible
      }) => ({
        getSubjects,
        subjects,
        isSubjectListLoading,
        total,
        selectedRows,
        setSelectedRows,
        updateSubjectStatus,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRows.map(obj => obj.id),
      onChange: (_, selectedRows) => {
        return setSelectedRows(selectedRows);
      }
    }),
    [setSelectedRows, selectedRows]
  );

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-mon-hoc',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-mon-hoc',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getSubjects();
  }, [getSubjects, search]);

  return (
    <ListCard
      left="Danh sách môn học"
      right={
        <Space>
          <StatusSelectForList
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            updateService={updateSubjectStatus}
          />

          <SubjectOrder />

          <PermissionButton keyAction={PERMISSION_ACTION.CREATE}>
            <Button
              onClick={() => setDrawerVisible(true)}
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </PermissionButton>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={subjects.map(obj => ({ ...obj, key: obj.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: subjects?.length
        }}
        rowSelection={rowSelection}
        loading={isSubjectListLoading}
      />
    </ListCard>
  );
};

export default SubjectList;
