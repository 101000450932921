import { Button, Card, Typography } from 'antd';
import React from 'react';

const ExerciseItem = ({ item, closeModal, pushContent }) => {
  const handlePushContent = item => {
    const contentToBePushed =
      item.question +
      '\n' +
      '<details open=""><summary>Câu trả lời</summary>' +
      item.answer +
      '</details><br/>';

    pushContent(contentToBePushed);
  };
  return (
    <Card
      size="small"
      title={item.title}
      extra={
        <Button
          type="link"
          onClick={() => {
            handlePushContent(item);
            closeModal();
          }}
        >
          Thêm
        </Button>
      }
      style={{ marginBottom: 16 }}
    >
      <Typography.Title level={5}>Câu hỏi:</Typography.Title>

      <div
        dangerouslySetInnerHTML={{ __html: item.question }}
        style={{ marginBottom: 8 }}
      />

      <Typography.Title level={5}>Câu trả lời:</Typography.Title>

      <div
        dangerouslySetInnerHTML={{ __html: item.answer }}
        style={{ marginBottom: 8 }}
      />
    </Card>
  );
};

export default ExerciseItem;
