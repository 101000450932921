import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import queryString from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, Input, Button, Typography } from 'antd';

import useAuth from 'stores/useAuth';
import Sakura from 'other/Sakura';

const Login = () => {
  const firstFocusRef = useRef(null);

  let history = useHistory();
  const login = useAuth(useCallback(({ login }) => login, []));
  const isLoggingIn = useAuth(
    useCallback(({ isLoggingIn }) => isLoggingIn, [])
  );
  const { search } = useLocation();

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const onFinish = values => {
    const callback = () => history.push(searchParams?.redirect || '/');

    return login(values, callback);
  };

  useEffect(() => {
    if (firstFocusRef.current) firstFocusRef.current.focus();
  }, []);

  useEffect(() => {
    var sakura = new Sakura('body', {
      maxSize: 14,
      minSize: 10
    });

    return () => {
      sakura.stop();
    };
  }, []);

  return (
    <div className="unauthorized-container-background">
      <div className="unauthorized-container">
        <Typography.Title style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          Đăng nhập
        </Typography.Title>

        <div className="unauthorized-container__form-wrapper">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Nhập email!' }]}
            >
              <Input ref={firstFocusRef} />
            </Form.Item>

            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
            >
              <Input.Password />
            </Form.Item>

            <div style={{ textAlign: 'end', marginBottom: '0.5em' }}>
              <Link to="/quen-mat-khau">Quên mật khẩu?</Link>
            </div>

            <Form.Item>
              <Button
                loading={isLoggingIn}
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
