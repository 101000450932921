import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';

const PrivateRoute = ({ children, isLoggedIn, path, keyCode, ...props }) => {
  const { currentPermissions } = useCurrentUser(
    useCallback(
      ({ currentPermissions }) => ({
        currentPermissions
      }),
      []
    ),
    shallow
  );

  const render = () => {
    if (currentPermissions)
      switch (true) {
        case currentPermissions
          .filter(permission => permission.isRead)
          .some(({ code }) => code === keyCode):
        case path === '/404':
        case path === '/unauthorized':
        case path === '/':
        case path === '/ca-nhan/:id':
        case path === '/ca-nhan':
        case path === '/danh-sach-html':
          return (
            <Route path={path} {...props}>
              {isLoggedIn ? children : <Redirect exact to="/dang-nhap" />}
            </Route>
          );

        default:
          return <Redirect to="/unauthorized" />;
      }
    else {
      return <></>;
    }
  };

  return render();
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
  isLoggedIn: PropTypes.bool
};

export default PrivateRoute;
