import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row, Tag, Button, Input, Spin } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';
import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';
import MetaCard from 'components/MetaCard';

const PersonalInfo = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { currentUser, updateCurrentUserInfo } = useCurrentUser(
    useCallback(
      ({ currentUser, updateCurrentUserInfo }) => ({
        currentUser,
        updateCurrentUserInfo
      }),
      []
    ),
    shallow
  );

  const onSave = async () => {
    try {
      const formParams = await form.validateFields();
      const params = {
        name: formParams.name,
        phoneNumber: formParams.phoneNumber,
        email: formParams.email,
        pseudonym: formParams.pseudonym
      };

      setLoading(true);
      await updateCurrentUserInfo(params);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser)
      form.setFieldsValue({
        name: currentUser.name,
        phoneNumber: currentUser.phoneNumber,
        email: currentUser.email,
        pseudonym: currentUser.pseudonym,
        role: currentUser?.role?.roleName
      });
  }, [form, currentUser]);

  return (
    <MetaCard title="Thông tin cá nhân">
      <Spin spinning={loading}>
        <Form layout="vertical" form={form}>
          <Row gutter={32}>
            <Col span={6}>
              <Form.Item label="Email:" name="email">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label="Vai trò:" name="role">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Họ tên:"
                name="name"
                rules={[{ required: true, message: 'Nhập họ tên!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Bút danh:"
                name="pseudonym"
                rules={[{ required: true, message: 'Nhập bút danh!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Số điện thoại:"
                name="phoneNumber"
                rules={[{ required: true, message: 'Nhập số điện thoại!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end" align="middle">
            <Tag color="processing" icon={<InfoCircleOutlined />}>
              Chỉ có thể thay đổi những thông tin được thao tác.
            </Tag>

            <Button onClick={onSave} type="primary" loading={loading}>
              Lưu
            </Button>
          </Row>
        </Form>
      </Spin>
    </MetaCard>
  );
};

export default PersonalInfo;
