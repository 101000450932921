import React, { useCallback, useEffect } from 'react';
import { Form, Input } from 'antd';
import { usePublisher } from 'stores/usePublisher';
import shallow from 'zustand/shallow';
import SlugFormItem from 'components/SlugFormItem';

const PublisherFormContent = ({ form }) => {
  const { publisherToEdit } = usePublisher(
    useCallback(
      ({ publisherToEdit }) => ({
        publisherToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (publisherToEdit) {
      form.setFieldsValue({
        name: publisherToEdit.name,
        slug: publisherToEdit.slug,
        description: publisherToEdit.description,
        seoTitle: publisherToEdit.seoTitle,
        seoDescription: publisherToEdit.seoDescription,
        seoKeyword: publisherToEdit.seoKeyword
      });
    } else {
      form.resetFields();
    }
  }, [publisherToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Tên nhà xuất bản"
        rules={[{ required: true, message: 'Nhập tên nhà xuất bản!' }]}
      >
        <Input placeholder="Nhập tên nhà xuất bản" />
      </Form.Item>

      <SlugFormItem form={form} target="name" />

      <Form.Item name="description" label="Mô tả">
        <Input placeholder="Nhập mô tả" />
      </Form.Item>

      <Form.Item name="seoTitle" label="Tiêu đề SEO">
        <Input placeholder="Nhập tiêu đề SEO" />
      </Form.Item>

      <Form.Item name="seoDescription" label="Mô tả SEO">
        <Input placeholder="Nhập mô tả SEO" />
      </Form.Item>

      <Form.Item name="seoKeyword" label="Từ khoá SEO">
        <Input placeholder="Nhập từ khoá SEO" />
      </Form.Item>
    </Form>
  );
};

export default PublisherFormContent;
