import React, { useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Space, Typography, Button } from 'antd';

import { useModule } from 'stores/useModule';
import shallow from 'zustand/shallow';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import StatusButton from 'components/StatusButton';
import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';

const EditModuleBtn = record => {
  const { deleteModule } = useModule(
    useCallback(
      ({ deleteModule }) => ({
        deleteModule
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deleteModule(record.id)}
          title={'Xoá ' + record?.name + ' ?'}
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>
    </Space>
  );
};

const Status = ({ record }) => {
  const { updateModuleStatus } = useModule(
    useCallback(
      ({ updateModuleStatus }) => ({
        updateModuleStatus
      }),
      []
    ),
    shallow
  );

  const onClick = key => {
    return updateModuleStatus([
      {
        ...record,
        status: key
      }
    ]);
  };

  return <StatusButton status={record?.status} onClick={onClick} />;
};

const Name = (name, record) => {
  const { setModuleToEdit, setDrawerVisible } = useModule(
    useCallback(
      ({ setModuleToEdit, setDrawerVisible }) => ({
        setModuleToEdit,

        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setModuleToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Tên chương',
    dataIndex: 'name',
    render: Name,
    align: 'left',
    width: '300px',
    fixed: 'left'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => <Status record={record} />,
    width: '220px'
  },
  {
    title: 'Chương',
    dataIndex: 'moduleNo',
    key: 'moduleNo',
    render: moduleNo => <Typography.Text>{moduleNo}</Typography.Text>,
    width: '90px'
  },
  {
    title: 'Số lượng bài',
    dataIndex: 'articleStatus',
    render: articleStatus => (
      <Typography.Text>{articleStatus?.total}</Typography.Text>
    ),
    width: '120px'
  },
  {
    title: 'Giáo trình',
    dataIndex: 'syllabus',
    key: 'syllabus',
    render: syllabus => <Typography.Text>{syllabus?.name}</Typography.Text>,
    width: '300px'
  },
  {
    title: 'Phần',
    dataIndex: 'section',
    key: 'section',
    render: section => <Typography.Text>{section?.name}</Typography.Text>,
    width: '200px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: EditModuleBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
