import { FileTextOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const Articles = ({ articles }) => {
  return (
    <div
      style={{
        background: 'white',
        padding: '10px',
        marginTop: '16px',
        borderRadius: '8px'
      }}
    >
      <Typography.Title level={5} strong>
        Bài viết đợi phát hành
      </Typography.Title>
      {articles.map(({ title, id, createdDate }) => (
        <div
          key={id}
          style={{
            background: '#c9c9c9',
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '8px',
            justifyContent: 'space-between',
            marginBottom: '8px'
          }}
        >
          <Row
            align="middle"
            gutter={8}
            justify="space-between"
            style={{ width: '100%' }}
          >
            <Col>
              <Link to={`/chi-tiet-bai-hoc/${id}`}>
                <Space>
                  <div
                    style={{
                      borderRadius: '4px',
                      background: 'white',
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <FileTextOutlined style={{ color: 'rgb(73, 106, 129)' }} />
                  </div>
                  <Typography.Text strong>{title}</Typography.Text>
                </Space>
              </Link>
            </Col>
            <Col>
              <Typography.Text>
                {moment(createdDate).format('DD/MM/YYYY hh:mm')}
              </Typography.Text>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default Articles;
