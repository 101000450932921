import { Button, notification } from 'antd';
import Upload from 'components/Upload';
import UserAvatar from 'components/UserAvatar';
import React, { useEffect, useState } from 'react';
import { CameraOutlined } from '@ant-design/icons';
import { IMG_UPLOAD_SIZE_LIMIT } from 'appConstants';

const AvatarUpload = ({
  defaultAvatar,
  fullName,
  avatar,
  size,
  fontSize,
  loading,
  onDone = f => f,
  uploadType,
  avatarShape,
  ...props
}) => {
  const [value, setValue] = useState(null);

  const onChange = args => {
    if (props.onChange) {
      props.onChange(args);
    }

    if (args.file.status === 'done') {
      setValue(args.file.response.preview);
      onDone(args.file.response.preview);
    }
  };

  useEffect(() => {
    if (props.file) {
      if (typeof props.file === 'string') {
        //NOTE: form item default value
        return setValue(props.file);
      } else {
        //NOTE: form item after upload
        if (props.file.status === 'done') {
          return setValue(props.file.response.preview);
        }
      }
    } else {
      //NOTE: normal default value
      return setValue(avatar);
    }
  }, [props.file, avatar]);

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < IMG_UPLOAD_SIZE_LIMIT;
    if (!isLt2M) {
      notification.error({
        message: 'Up hình dưới ' + IMG_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
      });
    }
    return isLt2M;
  };

  return (
    <div className="avatar-wrapper" style={{ width: size, height: size }}>
      <UserAvatar
        fullName={fullName}
        defaultAvatar={defaultAvatar}
        avatar={value}
        size={size}
        style={{ fontSize }}
        loading={loading}
        shape={avatarShape}
      />

      <Upload
        type={uploadType}
        // accept="image/*"
        maxCount={1}
        showUploadList={false}
        onChange={onChange}
        beforeUpload={beforeUpload}
      >
        <span className="avatar-btn">
          <Button type="default" shape="circle" icon={<CameraOutlined />} />
        </span>
      </Upload>
    </div>
  );
};

export default AvatarUpload;
