import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Space, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import { columns } from './columns';
import { useHTMLTemplate } from 'stores/useHTMLTemplate';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE, TABLE_PROPS } from 'appConstants';

import SortSelect from 'components/SortSelect';
import ListCard from 'components/ListCard';

const HTMLTemplateList = () => {
  const {
    getHTMLTemplates,
    HTMLTemplates,
    isHTMLTemplateListLoading,
    total,
    selectedRows,
    setSelectedRows,

    setDrawerVisible
  } = useHTMLTemplate(
    useCallback(
      ({
        getHTMLTemplates,
        HTMLTemplates,
        isHTMLTemplateListLoading,
        total,
        selectedRows,
        setSelectedRows,
        updateHTMLTemplateStatus,
        setDrawerVisible
      }) => ({
        getHTMLTemplates,
        HTMLTemplates,
        isHTMLTemplateListLoading,
        total,
        selectedRows,
        setSelectedRows,
        updateHTMLTemplateStatus,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRows.map(obj => obj.id),
      onChange: (_, selectedRows) => {
        return setSelectedRows(selectedRows);
      }
    }),
    [setSelectedRows, selectedRows]
  );

  const onPaginate = page => {
    history.push({
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getHTMLTemplates();
  }, [getHTMLTemplates, search]);

  return (
    <ListCard
      left="Danh sách HTML template"
      right={
        <Space>
          <Button
            onClick={() => setDrawerVisible(true)}
            icon={<PlusOutlined />}
          >
            Thêm mới
          </Button>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={HTMLTemplates.map(obj => ({ ...obj, key: obj.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: HTMLTemplates?.length
        }}
        rowSelection={rowSelection}
        loading={isHTMLTemplateListLoading}
      />
    </ListCard>
  );
};

export default HTMLTemplateList;
