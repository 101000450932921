import React, { useMemo } from 'react';

import { Typography } from 'antd';
import SubjectForm from './SubjectForm';
import SubjectList from './components/SubjectList';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import SubjectFilter from './SubjectFilter';
import { useLocation } from 'react-router';
import { generateFilterParamsCount } from 'utils/other';

const Subject = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div>
      <Typography.Title>Môn học</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={SubjectList}
        secondary={SubjectFilter}
        filterDrawerTitle="Lọc môn học"
      />

      <SubjectForm />
    </div>
  );
};

export default Subject;
