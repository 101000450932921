import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useExercise = create((set, get) => ({
  isExerciseLoading: false,
  setIsExerciseLoading: isExerciseLoading => set({ isExerciseLoading }),
  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),
  //* ******************** */
  //* NOTE: get exercises  */
  //* ******************** */

  exercises: [],
  totalPage: 1,
  total: 1,
  fetchExercises: async (params = {}) => {
    get().setIsExerciseLoading(true);

    try {
      const {
        data: { data, total }
      } = await API.get('/exercise', {
        params
      });

      get().setIsExerciseLoading(false);

      if (data) {
        return { data, total };
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsExerciseLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getExercises: async (params = {}) => {
    get().setIsExerciseLoading(true);

    try {
      const { data, total } = await get().fetchExercises({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsExerciseLoading(false);

      if (data) {
        set({
          exercises: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsExerciseLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************** */
  //* NOTE: delete exercise  */
  //* ********************** */

  deleteExercise: async id => {
    get().setIsExerciseLoading(true);

    try {
      const { data } = await API.delete('/exercise/' + id);

      get().setIsExerciseLoading(false);

      if (data) {
        if (id === get().exerciseToEdit?.id) {
          get().setExerciseToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getExercises();

        return notification.success({
          message: 'Xoá bài tập thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsExerciseLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */

  updateExerciseStatus: async params => {
    get().setIsExerciseLoading(true);

    try {
      const { data } = await API.patch('/exercise', params);

      get().setIsExerciseLoading(false);

      if (data) {
        get().getExercises();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa bài tập thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsExerciseLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
