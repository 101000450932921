import React, { useCallback } from 'react';
import { Button, Form, Input, Row, Space, Spin, Tooltip } from 'antd';
import { PaperClipOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Upload from 'components/Upload';
import MasterDataSelect from 'containers/MasterDataSelect';
import { useFeedback } from 'stores/useFeedback';
import shallow from 'zustand/shallow';
import { get } from 'utils/lodash';

const FeedbackWidgetForm = ({ setVisible }) => {
  const [form] = Form.useForm();

  const { createFeedback, isFeedbackFormLoading } = useFeedback(
    useCallback(
      ({ createFeedback, isFeedbackFormLoading }) => ({
        createFeedback,
        isFeedbackFormLoading
      }),
      []
    ),
    shallow
  );

  const onSend = async () => {
    const params = form.getFieldsValue();
    const image = get(params, 'image.fileList', []);

    const newParams = {
      ...params,
      image: image.map(item => item?.response?.preview || '')
    };
    await createFeedback(newParams);
    setVisible(false);
    form.resetFields();
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <Spin spinning={isFeedbackFormLoading}>
      <Form form={form} layout="vertical" style={{ width: 480 }}>
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[{ required: true, message: 'Nhập tiêu đề!' }]}
        >
          <Input placeholder="Nhập tiêu đề" />
        </Form.Item>

        <Form.Item
          label="Nội dung"
          name="comment"
          rules={[{ required: true, message: 'Nhập nội dung!' }]}
        >
          <Input.TextArea rows={5} placeholder="Nhập nội dung" />
        </Form.Item>

        <Form.Item
          name="target"
          label="Đối tượng"
          rules={[{ required: true, message: 'Chọn đối tượng!' }]}
        >
          <MasterDataSelect groupCode="resource" placeholder="Chọn đối tượng" />
        </Form.Item>

        <Form.Item name="image">
          <Upload maxCount={3} type="user" accept="image/*" listType="picture">
            <Space>
              <Button icon={<PaperClipOutlined />}>Đính kèm hình ảnh</Button>

              <Tooltip placement="right" title="Giới hạn 3 tấm hình">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          </Upload>
        </Form.Item>

        <Row justify="end">
          <Space>
            <Button onClick={onCancel}>Hủy</Button>

            <Button type="primary" onClick={onSend}>
              Gửi
            </Button>
          </Space>
        </Row>
      </Form>
    </Spin>
  );
};

export default FeedbackWidgetForm;
