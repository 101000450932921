import React, { useCallback } from 'react';

import 'moment/locale/vi';

import { DeleteOutlined } from '@ant-design/icons';
import { Space, Button } from 'antd';

import { useTag } from 'stores/useTag';
import shallow from 'zustand/shallow';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';

const EditTagBtn = record => {
  const { deleteTag } = useTag(
    useCallback(
      ({ deleteTag }) => ({
        deleteTag
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deleteTag(record.id)}
          title={'Xoá ' + record?.name + ' ?'}
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>
    </Space>
  );
};

const Name = (name, record) => {
  const { setTagToEdit, setDrawerVisible } = useTag(
    useCallback(
      ({ setTagToEdit, setDrawerVisible }) => ({
        setTagToEdit,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setTagToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Tag',
    dataIndex: 'name',
    render: Name,
    align: 'left',
    width: '180px',
    fixed: 'left'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: EditTagBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
