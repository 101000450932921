import { Input, Form } from 'antd';

import React, { useCallback, useContext } from 'react';
import { useArticleDetail } from 'stores/useArticleDetail';
import { useModule } from 'stores/useModule';
import { useSection } from 'stores/useSection';

import shallow from 'zustand/shallow';
import { ArticleDetailContext } from '..';

// SGK1201010101001
// SGK1201010101002
// SGK1201010101003
// SGK1201010101004
// SGK1201010101005
// SGK1201010101006
// SGK1201010101007

const ArticleTemplateInput = props => {
  const { form } = useContext(ArticleDetailContext);

  const { fetchModules } = useModule(
    useCallback(
      ({ fetchModules }) => ({
        fetchModules
      }),
      []
    ),
    shallow
  );

  const { fetchSections } = useSection(
    useCallback(
      ({ fetchSections }) => ({
        fetchSections
      }),
      []
    ),
    shallow
  );

  const {
    retrieveArticleTemplate,
    setSyllabusOptions,
    setModuleOptions,
    setSectionOptions,
    setModuleCondition
  } = useArticleDetail(
    useCallback(
      ({
        retrieveArticleTemplate,
        setSyllabusOptions,
        setModuleOptions,
        setSectionOptions,
        setModuleCondition
      }) => ({
        retrieveArticleTemplate,
        setSyllabusOptions,
        setModuleOptions,
        setSectionOptions,
        setModuleCondition
      }),
      []
    ),
    shallow
  );

  const onSearch = async value => {
    try {
      const res = await retrieveArticleTemplate(value);
      setSyllabusOptions([res?.syllabus]);

      const params = {
        SyllabusId: res?.syllabus?.id,
        Page: 0,
        PageSize: 1000
      };
      const { data: modules } = await fetchModules(params);
      const { data: sections } = await fetchSections(params);

      setModuleOptions(modules);
      setSectionOptions(sections);
      setModuleCondition(res?.module?.sectionId);

      form.setFieldsValue({
        title: res?.title,
        syllabusId: res?.syllabus?.id,
        moduleId: res?.module?.id,
        sectionId: res?.module?.sectionId,
        articleNo: res?.articleNo,
        code: value
      });
    } catch (e) {}
  };
  return (
    <>
      <Form.Item name="code" style={{ display: 'none' }}>
        <Input readOnly />
      </Form.Item>

      <Input.Search onSearch={onSearch} style={{ marginBottom: 24 }} />
    </>
  );
};

export default ArticleTemplateInput;
