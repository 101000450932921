import React, { useMemo } from 'react';
import { Form, Input } from 'antd';
import queryString from 'query-string';

import SyllabusAutoComplete from 'containers/SyllabusAutoComplete';

import { useLocation } from 'react-router';
import { FILTER_PARAMS_PREFIX } from 'appConstants';

const SyllabusAutoCompleteFilter = ({
  onAutoCompleteSelect = f => f,
  onAutoCompleteClear = f => f,
  form
}) => {
  const { search } = useLocation();

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const syllabus = useMemo(() => {
    if (
      !searchParams[FILTER_PARAMS_PREFIX + 'syllabusId'] ||
      !searchParams.syllabusName
    )
      return null;

    return {
      id: searchParams[FILTER_PARAMS_PREFIX + 'syllabusId'],
      name: searchParams.syllabusName
    };
  }, [searchParams]);

  const onSelect = (value, record) => {
    form.setFieldsValue({ syllabusName: record.label || record.children });
    onAutoCompleteSelect(value, record);
  };

  const onClear = () => {
    form.resetFields(['syllabusName']);
    onAutoCompleteClear();
  };

  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'syllabusId'} label="Giáo trình">
        <SyllabusAutoComplete
          allowClear
          syllabus={syllabus}
          onSelect={onSelect}
          onClear={onClear}
        />
      </Form.Item>

      <Form.Item name="syllabusName" style={{ display: 'none' }}>
        <Input readOnly />
      </Form.Item>
    </>
  );
};

export default SyllabusAutoCompleteFilter;
