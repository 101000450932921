import { Button, Collapse, Row, Typography, Tag } from 'antd';

import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useArticleDetail } from 'stores/useArticleDetail';
import shallow from 'zustand/shallow';

import { AUTO_SAVE_INTERVAL, STATUS_VALUE } from 'appConstants';
import EduEditor from 'containers/EduEditor/EduEditor';

const { Panel } = Collapse;

const ArticleEditorForm = () => {
  const { id } = useParams();
  const saveInterval = useRef(null);

  const { updateArticleContent, article, fetchArticleContent } =
    useArticleDetail(
      useCallback(
        ({ updateArticleContent, article, fetchArticleContent }) => ({
          updateArticleContent,
          article,
          fetchArticleContent
        }),
        []
      ),
      shallow
    );

  const editorRef = useRef(null);

  const onSave = useCallback(
    async auto => {
      const iframe = editorRef.current.iframeElement;
      const div = document.createElement('div');

      if (iframe) {
        const _document = iframe.contentDocument;
        const _body = _document.querySelector('body');
        div.innerHTML = _body.innerHTML;
        const imgs = div.querySelectorAll('img');

        imgs.forEach(img => {
          if (img.hasAttribute('src')) {
            img.setAttribute('data-src', img.src);

            img.removeAttribute('src');
          }
        });
      }

      const content = div?.innerHTML || editorRef.current.getContent();

      await updateArticleContent(content, id, auto);

      div.remove();
    },
    [updateArticleContent, id]
  );

  useEffect(() => {
    if (article?.status === STATUS_VALUE.PUBLISHED) return;

    saveInterval.current = setInterval(() => onSave(true), AUTO_SAVE_INTERVAL);

    return () => {
      if (saveInterval.current) {
        clearInterval(saveInterval.current);
      }
    };
  }, [article?.status, onSave]);

  const handleFetchArticleContent = async (id, evt) => {
    const res = await fetchArticleContent(id);
    if (res?.content) {
      editorRef.current.setContent(res.content);
    }

    const iframe = evt.target.iframeElement;
    if (!iframe) return;

    const head = iframe.contentDocument.querySelector('head');

    if (!head) return;

    const cript = document.createElement('script');
    cript.type = 'text/javascript';
    cript.id = 'MathJax-script';
    cript.async = true;
    cript.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-svg.js';
    head.appendChild(cript);
  };

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel
        header={
          <Row justify="space-between" style={{ width: '100%' }}>
            <Typography.Text strong>Bài viết</Typography.Text>

            <Tag color="blue">
              Tự động lưu mỗi {AUTO_SAVE_INTERVAL / 1000}s.
            </Tag>
          </Row>
        }
        key="1"
      >
        <>
          <EduEditor
            ref={editorRef}
            onInit={evt => handleFetchArticleContent(id, evt)}
            postSetup={editor => {
              editor.ui.registry.addButton('previewBtn', {
                text: 'Preview',
                tooltip: 'Preview',
                onAction: async () => {
                  await onSave();
                  window.open(`https://voh.com.vn/hoc-tap/preview/${id}`);
                }
              });
            }}
          />

          <Row justify="end" style={{ marginTop: 24 }}>
            <Button onClick={onSave} type="primary">
              Lưu
            </Button>
          </Row>
        </>
      </Panel>
    </Collapse>
  );
};

export default ArticleEditorForm;
