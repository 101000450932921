import { Button, Drawer, Space } from 'antd';

import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';

import { useSyllabus } from 'stores/useSyllabus';
import SyllabusTreeContent from './SyllabusTreeContent';

const SyllabusOrder = () => {
  const { syllabusTreeDrawerVisible, setSyllabusTreeDrawerVisible } =
    useSyllabus(
      useCallback(
        ({ syllabusTreeDrawerVisible, setSyllabusTreeDrawerVisible }) => ({
          syllabusTreeDrawerVisible,
          setSyllabusTreeDrawerVisible
        }),
        []
      ),
      shallow
    );

  const closeDrawer = () => setSyllabusTreeDrawerVisible(null);

  return (
    <>
      <Drawer
        title="Preview giáo trình"
        visible={!!syllabusTreeDrawerVisible}
        placement="right"
        width={450}
        onClose={closeDrawer}
        closable={false}
        // destroyOnClose
        extra={
          <Space>
            <Button onClick={closeDrawer}>Đóng</Button>
          </Space>
        }
      >
        <SyllabusTreeContent syllabusId={syllabusTreeDrawerVisible} />
      </Drawer>
    </>
  );
};

export default SyllabusOrder;
