import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';

import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import StatusButton from 'components/StatusButton';

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useArticle } from 'stores/useArticle';
import shallow from 'zustand/shallow';
import RelatedUser from 'components/RelatedUser';
import TimeLine from 'components/TimeLine';
import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';

const EditSyllabusBtn = record => {
  const { deleteArticle } = useArticle(
    useCallback(
      ({ deleteArticle }) => ({
        deleteArticle
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deleteArticle(record.id)}
          title={'Xoá ' + record?.title + ' ?'}
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>

      {/* <StatusDropdown record={record} onClick={onClick} /> */}
    </Space>
  );
};

const Status = ({ record }) => {
  const { updateArticleStatus } = useArticle(
    useCallback(
      ({ updateArticleStatus }) => ({
        updateArticleStatus
      }),
      []
    ),
    shallow
  );

  const onClick = key => {
    return updateArticleStatus([
      {
        ...record,
        status: key
      }
    ]);
  };

  return <StatusButton status={record?.status} onClick={onClick} />;
};

export const columns = [
  {
    title: 'Tên bài',
    dataIndex: 'title',
    render: (title, { id, articleNo, bookType, views }) => (
      <Space direction="vertical">
        <Link to={'/chi-tiet-bai-hoc/' + id}>
          <Typography.Text strong>
            #{title} <EditOutlined />
          </Typography.Text>
        </Link>
        <Typography.Text>
          <Typography.Text strong>Loại bài:</Typography.Text> {bookType?.name}
        </Typography.Text>
        <Typography.Text>
          <Typography.Text strong>Bài số:</Typography.Text> {articleNo}
        </Typography.Text>
        <Typography.Text>
          <Typography.Text strong>Lượt xem:</Typography.Text> {views}
        </Typography.Text>
      </Space>
    ),
    align: 'left',
    width: '300px',
    fixed: 'left'
  },

  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => <Status record={record} />,
    width: '220px'
  },
  {
    title: 'Giáo trình',
    dataIndex: 'syllabus',
    key: 'syllabus',
    render: ({ name }) => <Typography.Text>{name}</Typography.Text>,
    width: '300px'
  },
  {
    title: 'Phần',
    dataIndex: 'section',
    key: 'section',
    render: section =>
      section && (
        <Typography.Text>
          Phần {section?.sectionNo} - {section?.name}
        </Typography.Text>
      ),
    width: '300px'
  },
  {
    title: 'Chương',
    dataIndex: 'module',
    key: 'module',
    render: module => (
      <Typography.Text>
        Chương {module?.moduleNo} - {module?.name}
      </Typography.Text>
    ),
    width: '200px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    align: 'center',
    render: EditSyllabusBtn
  }
];
