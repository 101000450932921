import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import UserAvatar from 'components/UserAvatar';

import { useCurrentUser } from 'stores/useCurrentUser';
import { get } from 'utils/lodash';

const UserInfoDisplay = () => {
  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );
  const fullName = get(currentUser, 'name', '');
  const avatar = get(currentUser, 'image', '');

  return (
    <Link to="/ca-nhan">
      <div className="user-info-display">
        <UserAvatar fullName={fullName} avatar={avatar} size={32} />

        <Typography.Text strong className="user-info-display__name">
          {/* {fullName.split(' ').pop()} */}
          {fullName}
        </Typography.Text>
      </div>
    </Link>
  );
};

export default UserInfoDisplay;
