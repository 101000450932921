import React, { useCallback, useMemo } from 'react';
import StatusSelect from 'components/StatusSelect';
import { STATUS_VALUE } from 'appConstants';

const StatusSelectForList = ({
  selectedRows,
  setSelectedRows,
  updateService
}) => {
  const getSelectedValue = useCallback(arr => {
    if (arr.length === 0) return null;

    return arr.reduce((previousValue, currentValue) => {
      if (previousValue?.status === currentValue?.status) {
        return currentValue;
      } else {
        return null;
      }
    });
  }, []);

  const selectedStatus = useMemo(
    () => getSelectedValue(selectedRows)?.status,
    [selectedRows, getSelectedValue]
  );

  const onSelect = async status => {
    await updateService(
      selectedRows.map(({ id }) => ({
        id,
        status
      }))
    );

    setSelectedRows([]);
  };

  const filter = useCallback(
    key => {
      if (selectedStatus === STATUS_VALUE.DRAFT) {
        return key === STATUS_VALUE.APPROVED || key === STATUS_VALUE.DRAFT;
      }

      if (selectedStatus === STATUS_VALUE.APPROVED) {
        return (
          key === STATUS_VALUE.DRAFT ||
          key === STATUS_VALUE.APPROVED ||
          key === STATUS_VALUE.PUBLISHED
        );
      }

      if (selectedStatus === STATUS_VALUE.PUBLISHED) {
        return key === STATUS_VALUE.APPROVED || key === STATUS_VALUE.PUBLISHED;
      }

      return true;
    },
    [selectedStatus]
  );

  return !!selectedStatus ? (
    <StatusSelect
      onSelect={onSelect}
      value={selectedStatus}
      disableSelected
      filter={filter}
    />
  ) : (
    <></>
  );
};

export default StatusSelectForList;
