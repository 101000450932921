import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useModule = create((set, get) => ({
  isModuleFormLoading: false,
  setIsModuleFormLoading: isModuleFormLoading => set({ isModuleFormLoading }),
  isModuleListLoading: false,
  setIsModuleListLoading: isModuleListLoading => set({ isModuleListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //* **************** */
  //* NOTE: get module */
  //* **************** */

  moduleToEdit: null,
  setModuleToEdit: moduleToEdit => set({ moduleToEdit }),

  updateModule: async (params, id) => {
    get().setIsModuleFormLoading(true);

    try {
      const { data } = await API.put(`/module/${id}`, params);

      get().setIsModuleFormLoading(false);

      if (data) {
        get().getModules();

        notification.success({
          message: 'Cập nhật chương thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsModuleFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ******************* */
  //* NOTE: create module */
  //* ******************* */

  createModule: async params => {
    get().setIsModuleFormLoading(true);

    try {
      const { data } = await API.post('/module', params);

      get().setIsModuleFormLoading(false);

      if (data) {
        notification.success({
          message: 'Tạo chương thành công!'
        });

        get().getModules();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsModuleFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ****************** */
  //* NOTE: get modules  */
  //* ****************** */

  modules: [],
  totalPage: 1,
  fetchModules: async (params = {}) => {
    try {
      const { data } = await API.get('/module', {
        params
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsModuleListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getModules: async (params = {}) => {
    get().setIsModuleListLoading(true);

    try {
      const res = await get().fetchModules({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsModuleListLoading(false);

      if (res) {
        const { data, total } = res;
        set({ total, modules: data, totalPage: Math.ceil(total / PAGE_SIZE) });
        return data.result;
      }

      notification.error({
        message: res?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsModuleListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************** */
  //* NOTE: delete module  */
  //* ******************** */

  deleteModule: async id => {
    get().setIsModuleListLoading(true);

    try {
      const { data } = await API.delete('/module/' + id);

      get().setIsModuleListLoading(false);

      if (data) {
        if (id === get().moduleToEdit?.id) {
          get().setModuleToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getModules();

        return notification.success({
          message: 'Xoá chương thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsModuleListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  deleteModules: async params => {
    get().setIsModuleListLoading(true);

    try {
      const { data } = await API.delete('/module', {
        data: params
      });

      get().setIsModuleListLoading(false);

      if (data) {
        get().getModules();

        return notification.success({
          message: 'Xoá bài viết thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsModuleListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */

  updateModuleStatus: async params => {
    get().setIsModuleListLoading(true);

    try {
      const { data } = await API.patch('/module', params);

      get().setIsModuleListLoading(false);

      if (data) {
        get().getModules();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa chương thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsModuleListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
