import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import DatePicker from 'components/DatePicker';
import queryString from 'query-string';
import shallow from 'zustand/shallow';
import { useLocation } from 'react-router';

import SyllabusAutoCompleteFilter from 'containers/SyllabusAutoCompleteFilter';

import { useModule } from 'stores/useModule';
import StatusSelect from 'components/StatusSelect';
import {
  BE_FILTER_DATE_FORMAT,
  FILTER_PARAMS_PREFIX,
  FILTER_PARAMS_PREFIX_DATE
} from 'appConstants';
import moment from 'moment';
import UserAutoCompleteFilter from 'containers/UserAutoCompleteFilter/UserAutoCompleteFilter';
import ArticleTypeSelect from 'containers/ArticleTypeSelect';

const ModuleFormItems = ({ moduleOptions, form }) => {
  const onModuleSelect = (_, record) => {
    form.setFieldsValue({ moduleName: record.label || record.children });
  };

  const onModuleClear = () => {
    form.resetFields(['moduleName']);
  };

  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'moduleId'} label="Chương">
        <Select
          options={moduleOptions}
          onSelect={onModuleSelect}
          onClear={onModuleClear}
          placeholder="Chọn chương"
          allowClear
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>

      <Form.Item name="moduleName" style={{ display: 'none' }}>
        <Input readOnly />
      </Form.Item>
    </>
  );
};

const SyllabusFormItems = ({
  formatThenSetModuleOptions,
  fetchModules,
  form
}) => {
  const onSyllabusSelect = async value => {
    try {
      formatThenSetModuleOptions([]);

      const params = {
        SyllabusId: value,
        Page: 0,
        PageSize: 1000
      };

      const { data } = await fetchModules(params);

      formatThenSetModuleOptions(data);
    } catch (e) {}
  };

  const onSyllabusClear = () => {
    formatThenSetModuleOptions([]);
    form.resetFields(['moduleName', FILTER_PARAMS_PREFIX + 'moduleId']);
  };

  return (
    <SyllabusAutoCompleteFilter
      onAutoCompleteSelect={onSyllabusSelect}
      onAutoCompleteClear={onSyllabusClear}
      form={form}
    />
  );
};

const RangePickerFormItem = ({ form }) => {
  const filterCreatedFromLabel = FILTER_PARAMS_PREFIX_DATE + 'createdFrom';
  const filterCreatedToLabel = FILTER_PARAMS_PREFIX_DATE + 'createdTo';

  const onCreatedFromChange = value => {
    const { [filterCreatedToLabel]: createdTo } = form.getFieldsValue([
      filterCreatedToLabel
    ]);

    if (!createdTo) return;

    const formatedFrom = moment(value).format(BE_FILTER_DATE_FORMAT);
    const formatedTo = moment(createdTo).format(BE_FILTER_DATE_FORMAT);

    if (moment(formatedFrom).isSame(formatedTo)) {
      return form.resetFields([filterCreatedToLabel]);
    }

    if (moment(formatedFrom).isAfter(formatedTo)) {
      return form.setFieldsValue({
        [filterCreatedFromLabel]: createdTo,
        [filterCreatedToLabel]: value
      });
    }
  };

  const onCreatedToChange = value => {
    const { [filterCreatedFromLabel]: createdFrom } = form.getFieldsValue([
      filterCreatedFromLabel
    ]);

    if (!createdFrom) return;

    const formatedFrom = moment(createdFrom).format(BE_FILTER_DATE_FORMAT);
    const formatedTo = moment(value).format(BE_FILTER_DATE_FORMAT);

    if (moment(formatedFrom).isSame(formatedTo)) {
      return form.resetFields([filterCreatedToLabel]);
    }

    if (moment(formatedFrom).isAfter(formatedTo)) {
      return form.setFieldsValue({
        [filterCreatedFromLabel]: value,
        [filterCreatedToLabel]: createdFrom
      });
    }
  };

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item name={filterCreatedFromLabel} label="Thời gian tạo từ">
          <DatePicker
            style={{ width: '100%' }}
            onChange={onCreatedFromChange}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name={filterCreatedToLabel} label="Thời gian tạo đến">
          <DatePicker style={{ width: '100%' }} onChange={onCreatedToChange} />
        </Form.Item>
      </Col>
    </Row>
  );
};

const ArticleFilter = ({ form }) => {
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const { fetchModules } = useModule(
    useCallback(
      ({ fetchModules }) => ({
        fetchModules
      }),
      []
    ),
    shallow
  );

  const [moduleOptions, setModuleOptions] = useState([]);

  const formatThenSetModuleOptions = options =>
    setModuleOptions(
      options.map(({ id, name }) => ({ value: id + '', label: name }))
    );

  const fetchOptions = useCallback(async () => {
    if (searchParams[FILTER_PARAMS_PREFIX + 'syllabusId']) {
      const params = {
        SyllabusId: searchParams[FILTER_PARAMS_PREFIX + 'syllabusId'],
        Page: 0,
        PageSize: 1000
      };

      const { data } = await fetchModules(params);

      formatThenSetModuleOptions(data);
    }
  }, [fetchModules, searchParams]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'q'} label="Từ khóa">
        <Input placeholder="Nhập từ khoá" />
      </Form.Item>

      <SyllabusFormItems
        formatThenSetModuleOptions={formatThenSetModuleOptions}
        fetchModules={fetchModules}
        form={form}
      />

      <ModuleFormItems moduleOptions={moduleOptions} form={form} />

      <Form.Item name={FILTER_PARAMS_PREFIX + 'status'} label="Trạng thái">
        <StatusSelect style={{ width: '100%', fontWeight: 'normal' }} />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'bookTypeId'} label="Loại bài">
        <ArticleTypeSelect
          allowClear
          style={{ width: '100%', fontWeight: 'normal' }}
        />
      </Form.Item>

      <UserAutoCompleteFilter form={form} />

      <RangePickerFormItem form={form} />
    </>
  );
};

export default ArticleFilter;
