import React, { useEffect, useMemo } from 'react';
import { Button, Drawer, Form, Space } from 'antd';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { isObjEmpty } from 'utils/lodash';
import moment from 'moment';
import { BE_FILTER_DATE_FORMAT, FILTER_PARAMS_PREFIX_DATE } from 'appConstants';

const FilterForm = ({
  children,
  expand,
  setExpand,
  pathname,
  drawerTitle = 'Lọc',
  ...props
}) => {
  const [form] = Form.useForm();

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const closeDrawer = () => setExpand(false);

  const onNavigate = () => {
    const params = form.getFieldsValue();

    const filteredParams = Object.fromEntries(
      Object.entries(params)
        .filter(([key, value]) => {
          return value !== '' && value !== null;
        })
        .map(([key, value]) => {
          if (value?.isValid && value.isValid()) {
            return [key, moment(value).format(BE_FILTER_DATE_FORMAT)];
          }

          return [key, value];
        })
    );

    history.push({
      pathname,
      search: queryString.stringify(filteredParams)
    });

    closeDrawer();
  };

  const onFilter = () => {
    onNavigate();
  };

  const onClearFilter = () => {
    form.resetFields();
    onNavigate();
  };

  useEffect(() => {
    if (searchParams) {
      const newSearchParams = Object.fromEntries(
        Object.entries(searchParams).map(([key, value]) => {
          if (key.startsWith(FILTER_PARAMS_PREFIX_DATE)) {
            return [key, moment(value)];
          }

          return [key, value];
        })
      );

      form.setFieldsValue(newSearchParams);
    }
  }, [searchParams, form]);

  const renderDeleteBtn = () => {
    if (!isObjEmpty(searchParams)) {
      if (Object.keys(searchParams).length === 1 && searchParams?.page)
        return <></>;

      return (
        <Button onClick={onClearFilter} style={{ marginRight: 8 }}>
          Xoá bộ lọc
        </Button>
      );
    }
    return <></>;
  };

  return (
    <Drawer
      visible={expand}
      placement="right"
      width={600}
      onClose={closeDrawer}
      closable={false}
      title={drawerTitle}
      extra={
        <Space>
          <Button onClick={closeDrawer}>Đóng</Button>

          {renderDeleteBtn()}

          <Button type="primary" onClick={onFilter}>
            Lọc
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" {...props} onFinish={onFilter}>
        {React.cloneElement(children, { form })}

        <Form.Item style={{ display: 'none' }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default FilterForm;
