import { Input, Form } from 'antd';
import { FILTER_PARAMS_PREFIX } from 'appConstants';

import React from 'react';

const SubjectFilter = () => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'name'} label="Tên môn học">
        <Input placeholder="Nhập tên môn học" />
      </Form.Item>
    </>
  );
};

export default SubjectFilter;
