import { Form, Input } from 'antd';
import { FILTER_PARAMS_PREFIX } from 'appConstants';
import StatusSelect from 'components/StatusSelect';

import ClassSelect from 'containers/ClassSelect';
import PublisherSelect from 'containers/PublisherSelect';
import SubjectSelect from 'containers/SubjectSelect/SubjectSelect';
import React from 'react';

const SyllabusFilter = ({ form }) => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'name'} label="Tên giáo trình">
        <Input placeholder="Nhập tên giáo trình" />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'status'} label="Trạng thái">
        <StatusSelect style={{ width: '100%', fontWeight: 'normal' }} />
      </Form.Item>

      <Form.Item
        name={FILTER_PARAMS_PREFIX + 'publisherId'}
        label="Nhà xuất bản"
      >
        <PublisherSelect form={form} />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'classId'} label="Lớp">
        <ClassSelect />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'subjectId'} label="Môn học">
        <SubjectSelect />
      </Form.Item>
    </>
  );
};

export default SyllabusFilter;
