import { Button, Collapse, Form, Input, Row, Typography } from 'antd';
import { ArticleDetailContext } from 'pages/ArticleDetail';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router';
import { useArticleDetail } from 'stores/useArticleDetail';
import shallow from 'zustand/shallow';

const { Panel } = Collapse;

const ArticleSEOForm = () => {
  const { isEditingArticle, form } = useContext(ArticleDetailContext);
  const { id } = useParams();

  const { updateArticle } = useArticleDetail(
    useCallback(
      ({ updateArticle }) => ({
        updateArticle
      }),
      []
    ),
    shallow
  );

  const onSave = async () => {
    try {
      const params = await form.validateFields([
        'seoTitle',
        'seoDescription',
        'seoKeyword',
        'seo301',
        'seoCanonical'
      ]);
      updateArticle(params, id);
    } catch (e) {}
  };

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header={<Typography.Text strong>SEO</Typography.Text>} key="1">
        <>
          <Form.Item
            label="Tiêu đề SEO"
            name="seoTitle"
            rules={[{ required: true, message: 'Nhập tiêu đề SEO!' }]}
          >
            <Input placeholder="Nhập tiêu đề SEO" />
          </Form.Item>

          <Form.Item
            label="Mô tả SEO"
            name="seoDescription"
            rules={[{ required: true, message: 'Nhập mô tả SEO!' }]}
          >
            <Input.TextArea placeholder="Nhập mô tả SEO" />
          </Form.Item>

          <Form.Item label="Từ khoá SEO" name="seoKeyword">
            <Input placeholder="Nhập từ khoá SEO" />
          </Form.Item>

          <Form.Item label="SEO 301" name="seo301">
            <Input placeholder="Nhập mô tả SEO" disabled />
          </Form.Item>

          <Form.Item label="SEO Canonical" name="seoCanonical">
            <Input placeholder="Nhập mô tả SEO" />
          </Form.Item>

          {isEditingArticle && (
            <Row justify="end">
              <Button onClick={onSave} type="primary">
                Lưu
              </Button>
            </Row>
          )}
        </>
      </Panel>
    </Collapse>
  );
};

export default ArticleSEOForm;
