import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { useSyllabus } from 'stores/useSyllabus';
import shallow from 'zustand/shallow';

import SubjectSelect from 'containers/SubjectSelect/SubjectSelect';

import ClassSelect from 'containers/ClassSelect';
import SlugFormItem from 'components/SlugFormItem';
import PublisherSelect from 'containers/PublisherSelect';

const SyllabusFormContent = ({ form }) => {
  const [classCondition, setClassCondition] = useState(null);
  const [subjectCondition, setSubjectCondition] = useState(null);

  const { syllabusToEdit } = useSyllabus(
    useCallback(
      ({ syllabusToEdit }) => ({
        syllabusToEdit
      }),
      []
    ),
    shallow
  );

  const onClassSelect = value => {
    setSubjectCondition(value);
  };

  const onSubjectSelect = value => {
    setClassCondition(value);
  };

  useEffect(() => {
    if (syllabusToEdit) {
      form.setFieldsValue({
        name: syllabusToEdit.name,
        slug: syllabusToEdit.slug,
        description: syllabusToEdit.description,
        publisherId: syllabusToEdit.publisherId + '',
        classId: syllabusToEdit.classId + '',
        subjectId: syllabusToEdit.subjectId + '',
        part: syllabusToEdit.part,
        seoTitle: syllabusToEdit.seoTitle,
        seoDescription: syllabusToEdit.seoDescription,
        seoKeyword: syllabusToEdit.seoKeyword
      });
      setSubjectCondition(syllabusToEdit.classId);
      setClassCondition(syllabusToEdit.subjectId);
    } else {
      form.resetFields();
      setSubjectCondition(null);
      setClassCondition(null);
    }
  }, [syllabusToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Tên giáo trình"
        rules={[{ required: true, message: 'Nhập tên giáo trình!' }]}
      >
        <Input placeholder="Nhập tên giáo trình" />
      </Form.Item>

      <SlugFormItem form={form} target="name" />

      <Form.Item name="description" label="Mô tả">
        <Input.TextArea placeholder="Nhập tiêu đề SEO" />
      </Form.Item>

      <Form.Item
        name="publisherId"
        label="Nhà xuất bản"
        rules={[{ required: true, message: 'Chọn nhà xuất bản!' }]}
      >
        <PublisherSelect />
      </Form.Item>

      <Form.Item
        name="classId"
        label="Lớp"
        rules={[{ required: true, message: 'Chọn lớp!' }]}
      >
        <ClassSelect
          condition={classCondition}
          onSelect={onClassSelect}
          onClear={() => setSubjectCondition(null)}
        />
      </Form.Item>

      <Form.Item
        name="subjectId"
        label="Môn học"
        rules={[{ required: true, message: 'Chọn môn học!' }]}
      >
        <SubjectSelect
          condition={subjectCondition}
          onSelect={onSubjectSelect}
          onClear={() => setClassCondition(null)}
        />
      </Form.Item>

      <Form.Item
        name="part"
        label="Tập"
        rules={[{ required: true, message: 'Điền tập!' }]}
      >
        <Input placeholder="Nhập tập" />
      </Form.Item>

      <Form.Item name="seoTitle" label="Tiêu đề SEO">
        <Input placeholder="Nhập tiêu đề SEO" />
      </Form.Item>

      <Form.Item name="seoDescription" label="Mô tả SEO">
        <Input placeholder="Nhập mô tả SEO" />
      </Form.Item>

      <Form.Item name="seoKeyword" label="Từ khoá SEO">
        <Input placeholder="Nhập từ khoá SEO" />
      </Form.Item>
    </Form>
  );
};

export default SyllabusFormContent;
