import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Space, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { columns } from '../columns';

import shallow from 'zustand/shallow';
import { useLocation } from 'react-router';

import { useClass } from 'stores/useClass';
import StatusSelectForList from 'components/StatusSelectForList';
import { PERMISSION_ACTION } from 'appConstants';
import PermissionButton from 'containers/PermissionButton';
import ListCard from 'components/ListCard';

const ClassList = () => {
  const {
    getClasses,
    classes,
    isClassListLoading,
    selectedRows,
    setSelectedRows,
    updateClassStatus,
    setDrawerVisible
  } = useClass(
    useCallback(
      ({
        getClasses,
        classes,
        isClassListLoading,
        selectedRows,
        setSelectedRows,
        updateClassStatus,
        setDrawerVisible
      }) => ({
        getClasses,
        classes,
        isClassListLoading,
        selectedRows,
        setSelectedRows,
        updateClassStatus,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRows.map(obj => obj.id),
      onChange: (_, selectedRows) => {
        return setSelectedRows(selectedRows);
      }
    }),
    [setSelectedRows, selectedRows]
  );

  useEffect(() => {
    getClasses();
  }, [getClasses, search]);

  return (
    <ListCard
      left="Danh sách lớp"
      right={
        <Space>
          <StatusSelectForList
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            updateService={updateClassStatus}
          />

          <PermissionButton keyAction={PERMISSION_ACTION.CREATE}>
            <Button
              onClick={() => setDrawerVisible(true)}
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </PermissionButton>
        </Space>
      }
    >
      <Table
        // {...TABLE_PROPS}
        columns={columns}
        dataSource={classes.map(obj => ({ ...obj, key: obj.id }))}
        pagination={false}
        rowSelection={rowSelection}
        loading={isClassListLoading}
      />
    </ListCard>
  );
};

export default ClassList;
