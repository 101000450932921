import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Space, Table } from 'antd';
import queryString from 'query-string';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { columns } from '../columns';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useArticle } from 'stores/useArticle';
import shallow from 'zustand/shallow';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE, TABLE_PROPS } from 'appConstants';

import SortSelect from 'components/SortSelect';

import StatusSelectForList from 'components/StatusSelectForList';
import DeleteListPopup from 'components/DeleteListPopup';
import ListCard from 'components/ListCard';

const LessionList = () => {
  const {
    getArticles,
    articles,
    isArticleLoading,
    total,
    updateArticleStatus,
    selectedRows,
    setSelectedRows,
    deleteArticles,
    exportArticles
  } = useArticle(
    useCallback(
      ({
        getArticles,
        articles,
        isArticleLoading,
        total,
        updateArticleStatus,
        selectedRows,
        setSelectedRows,
        deleteArticles,
        exportArticles
      }) => ({
        getArticles,
        articles,
        isArticleLoading,
        total,
        updateArticleStatus,
        selectedRows,
        setSelectedRows,
        deleteArticles,
        exportArticles
      }),
      []
    ),
    shallow
  );
  const { search } = useLocation();
  const history = useHistory();

  console.log(articles);

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const rowSelection = {
    selectedRowKeys: selectedRows.map(obj => obj.id),
    onChange: (_, selectedRows) => {
      return setSelectedRows(selectedRows);
    }
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-bai-hoc',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-bai-hoc',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getArticles();
  }, [getArticles, search]);
  return (
    <ListCard
      left="Danh sách bài học"
      right={
        <Space>
          <Button
            icon={<DownloadOutlined />}
            onClick={exportArticles}
            disabled={articles.length <= 0}
          >
            Export
          </Button>
          <DeleteListPopup
            selectedRows={selectedRows}
            deleteService={deleteArticles}
            setSelectedRows={setSelectedRows}
          />

          <StatusSelectForList
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            updateService={updateArticleStatus}
          />

          <Link to="/chi-tiet-bai-hoc">
            <Button type="primary" icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Link>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={articles.map(obj => ({ ...obj, key: obj.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: articles?.length
        }}
        rowSelection={rowSelection}
        loading={isArticleLoading}
      />
    </ListCard>
  );
};

export default LessionList;
