import React from 'react';
import { Form, Input } from 'antd';

import { FILTER_PARAMS_PREFIX } from 'appConstants';
import SyllabusAutoCompleteFilter from 'containers/SyllabusAutoCompleteFilter';
import StatusSelect from 'components/StatusSelect';

const SectionFilter = ({ form }) => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'name'} label="Từ khoá">
        <Input placeholder="Nhập tên chương" />
      </Form.Item>

      <SyllabusAutoCompleteFilter form={form} />
      <Form.Item name={FILTER_PARAMS_PREFIX + 'status'} label="Trạng thái">
        <StatusSelect style={{ width: '100%', fontWeight: 'normal' }} />
      </Form.Item>
    </>
  );
};

export default SectionFilter;
