import { Button, Drawer, Space } from 'antd';
import { SortAscendingOutlined } from '@ant-design/icons';
import React, { useCallback, useRef, useState } from 'react';
import shallow from 'zustand/shallow';
import SyllabusOrderContent from './SyllabusOrderContent';
import ClassAutoComplete from 'containers/ClassAutoComplete';
import SubjectAutoComplete from 'containers/SubjectAutoComplete/SubjectAutoComplete';
import { useSyllabus } from 'stores/useSyllabus';

const SyllabusOrder = () => {
  const [classId, setClassId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);

  const childRef = useRef(null);
  const { updateSyllabusOrder } = useSyllabus(
    useCallback(
      ({ updateSyllabusOrder }) => ({
        updateSyllabusOrder
      }),
      []
    ),
    shallow
  );

  const [expand, setExpand] = useState(false);

  const closeDrawer = () => {
    setExpand(false);
    setClassId(null);
    setSubjectId(null);
  };
  const openDrawer = () => setExpand(true);

  const onSave = async () => {
    const list = childRef.current?.syllabuses;

    if (!list) {
      return;
    }

    await updateSyllabusOrder(list.map(({ id }) => ({ id })).reverse());

    closeDrawer();
  };

  return (
    <>
      <Button onClick={openDrawer} icon={<SortAscendingOutlined />}>
        Độ ưu tiên
      </Button>

      <Drawer
        title="Độ ưu tiên giáo trình"
        visible={expand}
        placement="right"
        width={450}
        onClose={closeDrawer}
        closable={false}
        destroyOnClose
        footer={
          <Space direction="vertical" style={{ width: '100%' }}>
            <ClassAutoComplete onSelect={v => setClassId(v)} />

            <SubjectAutoComplete onSelect={v => setSubjectId(v)} />
          </Space>
        }
        extra={
          <Space>
            <Button onClick={closeDrawer}>Đóng</Button>

            <Button type="primary" onClick={onSave}>
              Lưu
            </Button>
          </Space>
        }
      >
        <SyllabusOrderContent
          ref={childRef}
          classId={classId}
          subjectId={subjectId}
        />
      </Drawer>
    </>
  );
};

export default SyllabusOrder;
