import { FILTER_PARAMS_PREFIX, FILTER_PARAMS_PREFIX_DATE } from 'appConstants';

import moment from 'moment';
import queryString from 'query-string';

export const calculatePercent = (a, b) => ((a / b) * 100).toFixed(2) * 1;
export const generateFilterParams = arr => {
  let obj = {};

  arr
    .filter(
      ({ value, operator, operatorFrom, operatorTo }) =>
        (value || value === 0) && (operator || (operatorFrom && operatorTo))
    )
    .forEach(({ name, operator, value, operatorFrom, operatorTo }) => {
      // Check is range picker
      if (operator) {
        obj[`${name}[${operator}]`] = value;
      } else {
        obj[`${name}[${operatorFrom}]`] = moment(value[0]).format('YYYY-MM-DD');
        obj[`${name}[${operatorTo}]`] = moment(value[1]).format('YYYY-MM-DD');
      }
    });

  return obj;
};

export const objToArr = obj => {
  return Object.keys(obj).map(key => ({ key, value: obj[key] }));
};

export const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const extractPartnersFromSong = (songs, forSelect) => {
  if (!songs) return [[], []];

  const a = [],
    b = [];

  songs.forEach(item => {
    const thingToPush = forSelect
      ? {
          value: item?.partner?.id,
          label: item?.partner?.artistName || item?.partner?.fullName
        }
      : item?.partnerId;

    if (item?.type === 'musician') {
      a.push(thingToPush);
    } else if (item?.type === 'singer') {
      b.push(thingToPush);
    }
  });

  return [a, b];
};

export const getQueryParams = () => {
  const search = window.location.search;
  let otherParams = queryString.parse(search);
  const page = otherParams.page || 1;
  return { ...otherParams, page: page - 1 };
};

export const setError = (form, errs) => {
  if (!errs && typeof errs !== 'object') return;

  return form.setFields(
    Object.entries(errs).map(([key, value]) => {
      return {
        name: [key[0].toLowerCase() + key.substring(1)],
        errors: [value]
      };
    })
  );
};

export const processFilerParams = (params, filter) => {
  const processedParams = Object.entries(params);

  if (filter) {
    return Object.fromEntries(
      processedParams
        .filter(
          ([key, value]) =>
            key.startsWith(FILTER_PARAMS_PREFIX) ||
            key.startsWith(FILTER_PARAMS_PREFIX_DATE)
        )
        .map(([key, value]) => {
          return [
            key
              .replace(FILTER_PARAMS_PREFIX, '')
              .replace(FILTER_PARAMS_PREFIX_DATE, ''),
            value
          ];
        })
    );
  }

  return Object.fromEntries(
    processedParams.map(([key, value]) => [
      key
        .replace(FILTER_PARAMS_PREFIX, '')
        .replace(FILTER_PARAMS_PREFIX_DATE, ''),
      value
    ])
  );
};

export const generateFilterParamsCount = search => {
  return Object.keys(processFilerParams(queryString.parse(search), true))
    .length;
};

export const processFileUrl = fileDetails => {
  return (
    process.env.REACT_APP_CLOUD_URL + fileDetails.filterPath + fileDetails.name
  );
};

export const generateFileDownload = link => {
  const linkFile = document.createElement('a');
  linkFile.href = link;
  linkFile.setAttribute('download', `File`);
  document.body.appendChild(linkFile);

  linkFile.click();

  linkFile.parentNode.removeChild(linkFile);
};
