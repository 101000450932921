import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useClass = create((set, get) => ({
  isClassFormLoading: false,
  setIsClassFormLoading: isClassFormLoading => set({ isClassFormLoading }),
  isClassListLoading: false,
  setIsClassListLoading: isClassListLoading => set({ isClassListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //* *************** */
  //* NOTE: get class */
  //* *************** */
  classToEdit: null,
  setClassToEdit: classToEdit => set({ classToEdit }),

  updateClass: async (params, id) => {
    get().setIsClassFormLoading(true);

    try {
      const { data } = await API.put(`/class/${id}`, params);

      get().setIsClassFormLoading(false);

      if (data) {
        get().getClasses();

        notification.success({
          message: 'Cập nhật lớp thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsClassFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************** */
  //* NOTE: create class */
  //* ********************** */

  createClass: async params => {
    get().setIsClassFormLoading(true);

    try {
      const { data } = await API.post('/class', params);

      get().setIsClassFormLoading(false);

      if (data) {
        notification.success({
          message: 'Tạo lớp thành công!'
        });

        get().getClasses();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsClassFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ****************** */
  //* NOTE: get classes  */
  //* ****************** */

  classes: [],
  totalPage: 1,
  fetchClasses: async (params = {}) => {
    try {
      const { data } = await API.get('/class', {
        params: {
          ...params
        }
      });

      if (!data) return null;

      return data;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getClasses: async (params = {}) => {
    get().setIsClassListLoading(true);

    try {
      const res = await get().fetchClasses({
        PageSize: 1000,
        ...params,
        ...getQueryParams()
      });

      get().setIsClassListLoading(false);

      if (res) {
        const { data, total } = res;

        set({ classes: data, totalPage: Math.ceil(total / PAGE_SIZE) });
        return data;
      }

      notification.error({
        message: res?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsClassListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: delete class  */
  //* ******************* */

  deleteClass: async id => {
    get().setIsClassListLoading(true);

    try {
      const { data } = await API.delete('/class/' + id);

      get().setIsClassListLoading(false);

      if (data) {
        if (id === get().classToEdit?.id) {
          get().setClassToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getClasses();

        return notification.success({
          message: 'Xoá lớp thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsClassListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */

  updateClassStatus: async params => {
    get().setIsClassListLoading(true);

    try {
      const { data } = await API.patch('/class', params);

      get().setIsClassListLoading(false);

      if (data) {
        get().getClasses();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa lớp thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsClassListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
