import { Collapse, Typography, Form, Row, Button } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ExerciseAutoComplete from 'containers/ExerciseAutoComplete';
import { useArticleDetail } from 'stores/useArticleDetail';
import shallow from 'zustand/shallow';
import { useParams } from 'react-router';
import { ArticleDetailContext } from 'pages/ArticleDetail';

const ArticleDetailExercises = () => {
  const { id } = useParams();
  const [exercises, setExercises] = useState([]);
  const { attachExercisesToArticle, fetchExercisesByArticle } =
    useArticleDetail(
      useCallback(
        ({ attachExercisesToArticle, fetchExercisesByArticle }) => ({
          attachExercisesToArticle,
          fetchExercisesByArticle
        }),
        []
      ),
      shallow
    );

  const { form } = useContext(ArticleDetailContext);

  const onSave = () => {
    const { exerciseIds } = form.getFieldsValue();
    attachExercisesToArticle(id, exerciseIds);
  };

  const handleFetchExercises = useCallback(async () => {
    try {
      const res = await fetchExercisesByArticle(id);
      setExercises(res);
      form.setFieldsValue({ exerciseIds: res.map(({ id }) => id) });
    } catch (e) {}
  }, [id, fetchExercisesByArticle, form]);

  useEffect(() => {
    handleFetchExercises();
  }, [handleFetchExercises]);

  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel
        header={<Typography.Text strong>Bài tập</Typography.Text>}
        key="1"
      >
        <>
          <Form.Item name="exerciseIds">
            <ExerciseAutoComplete exercise={exercises} mode="multiple" />
          </Form.Item>

          <Row justify="end">
            <Button onClick={onSave} type="primary">
              Lưu
            </Button>
          </Row>
        </>
      </Collapse.Panel>
    </Collapse>
  );
};

export default ArticleDetailExercises;
