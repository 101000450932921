import React, { useCallback, useEffect, useState } from 'react';

import shallow from 'zustand/shallow';
import { Select } from 'antd';

import { useRole } from 'stores/useRole';

const RoleSelect = props => {
  const [options, setOptions] = useState([]);
  const { fetchRoles } = useRole(
    useCallback(
      ({ fetchRoles }) => ({
        fetchRoles
      }),
      []
    ),
    shallow
  );

  const handleFetchRoles = useCallback(async () => {
    const { data } = await fetchRoles({
      PageSize: 1000,
      Page: 0
    });

    setOptions(
      data.map(({ id, roleName }) => ({ value: id + '', label: roleName }))
    );
  }, [fetchRoles]);

  useEffect(() => {
    handleFetchRoles();
  }, [handleFetchRoles]);

  return (
    <Select
      options={options}
      placeholder="Chọn vai trò"
      style={{ width: '100%' }}
      allowClear
      showSearch
      optionFilterProp="label"
      {...props}
    />
  );
};

export default RoleSelect;
