import React, { useCallback } from 'react';
import { Menu, Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import { Link } from 'react-router-dom';
import { useCurrentUser } from 'stores/useCurrentUser';
import { get } from 'utils/lodash';
import shallow from 'zustand/shallow';

const UserInfoMenuItem = ({ closeDropdown }) => {
  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  const fullName = get(currentUser, 'name', '');
  const avatar = get(currentUser, 'image', '');

  return (
    <Menu.Item className="user-info-menu-item" key="info">
      <Link to="/ca-nhan" onClick={closeDropdown}>
        <div className="user-info-menu-item__inner">
          <UserAvatar fullName={fullName} avatar={avatar} size={42} />
          <div className="user-info-menu-item__text">
            <Typography.Text strong>{fullName}</Typography.Text>
            <Typography.Text>Xem trang cá nhân</Typography.Text>
          </div>
        </div>
      </Link>
    </Menu.Item>
  );
};

export default UserInfoMenuItem;
