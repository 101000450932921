import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

import { useModule } from 'stores/useModule';
import { useSection } from 'stores/useSection';

export const useArticleDetail = create((set, get) => ({
  isArticleDetailLoading: false,
  setIsArticleDetailLoading: isArticleDetailLoading =>
    set({ isArticleDetailLoading }),

  isCreatingArticle: false,
  setIsCreatingArticle: isCreatingArticle => set({ isCreatingArticle }),

  //* ***************** */
  //* NOTE: get article */
  //* ***************** */

  article: null,

  getArticle: async id => {
    get().setIsArticleDetailLoading(true);
    get().reset();

    try {
      const { data } = await API.get(`/article/${id}`);

      get().setIsArticleDetailLoading(false);

      if (data) {
        set({ article: data });
        get().setSyllabusOptions([data?.syllabus]);

        const params = {
          SyllabusId: data?.syllabus?.id,
          Page: 0,
          PageSize: 1000
        };
        const { data: modules } = await useModule
          .getState()
          .fetchModules(params);
        const { data: sections } = await useSection
          .getState()
          .fetchSections(params);

        get().setModuleOptions(modules);
        get().setSectionOptions(sections);
        get().setModuleCondition(data?.module?.sectionId);

        return data;
      }

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch (data) {
      get().setIsArticleDetailLoading(false);

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  fetchArticleContent: async id => {
    try {
      const { data } = await API.get(`/article/${id}/article-detail`);

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      get().setIsArticleDetailLoading(false);

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************** */
  //* NOTE: create article */
  //* ******************** */

  createArticle: async params => {
    get().setIsCreatingArticle(true);

    try {
      const { data } = await API.post('/article', {
        ...params,
        status: 'draft'
      });

      get().setIsCreatingArticle(false);

      if (data) {
        notification.success({
          message: 'Tạo bài học thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    } catch (e) {
      get().setIsCreatingArticle(false);

      notification.error({
        message: e?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw e;
    }
  },

  //* ******************** */
  //* NOTE: update article */
  //* ******************** */

  updateArticle: async (params, id) => {
    get().setIsArticleDetailLoading(true);

    try {
      const { data } = await API.put(`/article/${id}`, params);

      get().setIsArticleDetailLoading(false);

      if (data) {
        notification.success({
          message: 'Cập nhật bài viết thành công!'
        });
        // console.log(data);
        // set({ article: data });

        await get().getArticle(id);
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || 'DEFAULT_ERROR_MESSAGE'
      });

      throw data;
    } catch (data) {
      get().setIsArticleDetailLoading(false);

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },
  updateArticleContent: async (content, id, auto) => {
    get().setIsArticleDetailLoading(true);

    try {
      const { data } = await API.put(`/article/${id}/article-detail`, {
        content
      });

      get().setIsArticleDetailLoading(false);

      notification.success({
        message: auto
          ? 'Tự động cập nhật bài viết thành công!'
          : 'Cập nhật bài viết thành công!'
      });

      return data;
    } catch (data) {
      get().setIsArticleDetailLoading(false);

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */
  attachTagsToArticle: async (id, params) => {
    get().setIsArticleDetailLoading(true);

    try {
      const { data } = await API.patch(`/article/${id}/tags`, params);

      get().setIsArticleDetailLoading(false);

      if (data) {
        return notification.success({
          message: 'Chỉnh sửa bài viết thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsArticleDetailLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  fetchExercisesByArticle: async id => {
    try {
      const { data } = await API.get(`/article/${id}/exercises`, {});

      if (data) {
        return data.data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  attachExercisesToArticle: async (id, exerciseIds) => {
    get().setIsArticleDetailLoading(true);

    try {
      const { data } = await API.patch(`/article/${id}/exercises`, {
        exerciseIds
      });

      get().setIsArticleDetailLoading(false);

      if (data) {
        return notification.success({
          message: 'Chỉnh sửa bài viết thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsArticleDetailLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  linkArticle: async (id, subArticleIds) => {
    get().setIsArticleDetailLoading(true);

    try {
      const { data } = await API.patch(`/article/${id}/related-article`, {
        subArticleIds
      });

      get().setIsArticleDetailLoading(false);

      if (data) {
        return notification.success({
          message: 'Chỉnh sửa bài viết thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsArticleDetailLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  retrieveArticleTemplate: async code => {
    get().setIsCreatingArticle(true);

    try {
      const { data } = await API.get(`/article/${code}/auto-fill`);

      get().setIsCreatingArticle(false);

      if (data) {
        notification.success({
          message: 'Auto-fill thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsCreatingArticle(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  moduleCondition: null,
  setModuleCondition: moduleCondition => set({ moduleCondition }),

  syllabusOptions: [],
  setSyllabusOptions: syllabusOptions => {
    if (!syllabusOptions) {
      return set({ syllabusOptions: [] });
    }

    return set({
      syllabusOptions: syllabusOptions.map(item => ({
        ...item,
        value: item.id,
        label: item.name
      }))
    });
  },

  sectionOptions: [],
  setSectionOptions: sectionOptions => {
    if (!sectionOptions) {
      return set({ sectionOptions: [] });
    }

    return set({
      sectionOptions: sectionOptions.map(item => ({
        ...item,
        value: item.id,
        label: item.name
      }))
    });
  },
  moduleOptions: [],
  setModuleOptions: moduleOptions => {
    if (!moduleOptions) {
      return set({ moduleOptions: [] });
    }

    return set({
      moduleOptions: moduleOptions.map(item => ({
        ...item,
        value: item.id,
        label: item.name
      }))
    });
  },
  reset: () =>
    set({
      moduleOptions: [],
      sectionOptions: [],
      syllabusOptions: [],
      moduleCondition: null
    })
}));
