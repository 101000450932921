import { Editor } from '@tinymce/tinymce-react';

import React, { useState } from 'react';

import imgIcon from '../../assets/images/folder.png';
import FileManagerModal from './FileManagerModal';
import MathMLConvertModal from './MathMLConvertModal';

import {
  content_style,
  external_plugins,
  plugins,
  toolbar
} from './editorConstants';
import HTMLModal from './HTMLModal';
import ExerciseModal from './ExerciseModal';

const pushContent = (editor, content, raw) => {
  editor.execCommand(
    raw ? 'mceInsertRawHTML' : 'mceInsertContent',
    false,
    content
  );
};

const EduEditor = React.forwardRef(
  ({ onInit, initialValue, postSetup = f => f, ...props }, ref) => {
    //File manager
    const [isFMModalVisible, setIsFMModalVisible] = useState(false);
    //MathML
    const [isMMLModalVisible, setIsMMLModalVisible] = useState(false);
    //HTML
    const [isHTMLModalVisible, setIsHTMLModalVisible] = useState(false);
    //Exercise
    const [isExerciseModalVisible, setIsExerciseModalVisible] = useState(false);

    const [item, setItem] = useState(null);

    const showFileMangerModal = () => {
      setIsFMModalVisible(true);
    };

    const setup = editor => {
      editor.ui.registry.addButton('fileManagerBtn', {
        text: `<image src=${imgIcon} style="height: 24px;width: 24px;"/ >`,
        // icon: 'plus',
        tooltip: 'Thêm file',
        onAction: showFileMangerModal
      });

      editor.ui.registry.addButton('mathMLBtn', {
        text: 'MathML',
        tooltip: 'Thêm MathML',
        onAction: () => setIsMMLModalVisible(true)
      });

      editor.ui.registry.addButton('htmlBtn', {
        text: 'HTML',
        tooltip: 'Thêm HTML',
        onAction: () => setIsHTMLModalVisible(true)
      });

      editor.ui.registry.addButton('exerciseBtn', {
        text: 'Bài tập',
        tooltip: 'Thêm bài tập',
        onAction: () => setIsExerciseModalVisible(true)
      });

      editor.on(
        'dblclick',
        ({
          target: {
            nodeName,
            src,
            dataset: { mathml }
          }
        }) => {
          if (nodeName === 'IMG') {
            var sName = src.match(/(gif|svg)\.latex\?(.*)/);
            if (!sName && !mathml) editor.execCommand('mceImage');
          }
        }
      );

      editor.on('click', e => {
        if (editor.dom.getParent(e.target, 'summary')) {
          if (e.target?.parentNode?.nodeName === 'DETAILS') {
            e.target?.parentNode?.toggleAttribute('open');
          }
        }
      });

      postSetup(editor);

      editor.on('init', onInit);

      return () => {
        editor.off();
      };
    };

    const onSetContent = () => {
      const iframe = document.querySelector('iframe');
      const _document = iframe.contentDocument;

      const body = _document.querySelector('body');

      const MjxMathContainers = body.querySelectorAll('mjx-container');
      MjxMathContainers.forEach(MjxMathContainer => {
        if (MjxMathContainer.parentNode.tagName !== 'MJX-ASSISTIVE-MML') {
          const MjxMath = MjxMathContainer.querySelector('mjx-math');
          if (MjxMath) {
            const MathML = MjxMathContainer.querySelector('math');
            if (MathML) MjxMathContainer.outerHTML = MathML.outerHTML;
          }
        }
      });

      const MathJax = window.MathJax;
      if (MathJax) MathJax.typeset([body]);

      const targets = body.querySelectorAll('mjx-assistive-mml');
      targets.forEach(target => target.remove());

      const details = body.querySelectorAll('details');

      details.forEach(detail => {
        detail.removeAttribute('open');
        detail.removeAttribute('data-mce-open');
      });

      const imgs = body.querySelectorAll('img');

      imgs.forEach(img => {
        if (img.hasAttribute('data-src')) {
          img.setAttribute('src', img.dataset.src);
          img.removeAttribute('data-src');
        }
      });
    };

    const init = {
      init_instance_callback: function (editor) {
        editor.on('SetContent', onSetContent);
      },
      selector: 'textarea',
      toc_class: 'edu-toc',
      height: 500,
      dirty: true,
      plugins,
      ...toolbar,
      content_style,
      setup,
      image_caption: true,
      external_plugins,
      valid_elements: '*[*],mjx-container[*],svg[*],path[*],g[*]',
      invalid_elements: 'strong,b,em,i,script,mjx-assistive-mml',
      custom_elements: '~mjx-container', // Notice the ~ prefix to force a span element for the element
      rel_list: [
        { title: 'None', value: '' },
        { title: 'No Follow', value: 'nofollow' },
        { title: 'No Referrer', value: 'noreferrer' },
        { title: 'External Link', value: 'external' }
      ],
      target_list: [
        {title: 'Current window', value: ''},
        {title: 'New window', value: '_blank '},
      ]
    };

    return (
      <>
        <Editor
          apiKey={process.env.REACT_APP_EDITOR_API_KEY}
          onInit={(evt, editor) => {
            if (ref) {
              ref.current = editor;
            }
          }}
          init={init}
          initialValue={initialValue}
        />

        <FileManagerModal
          visible={isFMModalVisible}
          closeModal={() => setIsFMModalVisible(false)}
          item={item}
          setItem={setItem}
          pushContent={content => pushContent(ref.current, content)}
        />

        <MathMLConvertModal
          visible={isMMLModalVisible}
          closeModal={() => setIsMMLModalVisible(false)}
          pushContent={content => pushContent(ref.current, content, true)}
        />

        <HTMLModal
          visible={isHTMLModalVisible}
          closeModal={() => setIsHTMLModalVisible(false)}
          pushContent={content => pushContent(ref.current, content, true)}
        />

        <ExerciseModal
          visible={isExerciseModalVisible}
          closeModal={() => setIsExerciseModalVisible(false)}
          pushContent={content => pushContent(ref.current, content, true)}
        />
      </>
    );
  }
);

export default EduEditor;
