import React, { useCallback, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useKeyword } from 'stores/useKeyword';
import shallow from 'zustand/shallow';

const KeywordFormContent = ({ form }) => {
  const { keywordToEdit } = useKeyword(
    useCallback(
      ({ keywordToEdit }) => ({
        keywordToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (keywordToEdit) {
      form.setFieldsValue({
        name: keywordToEdit.name,
        url: keywordToEdit.url
      });
    } else {
      form.resetFields();
    }
  }, [keywordToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Từ khóa"
        rules={[{ required: true, message: 'Nhập từ khóa!' }]}
      >
        <Input placeholder="Nhập keyword" />
      </Form.Item>

      <Form.Item name="url" label="Url">
        <Input placeholder="Nhập url" />
      </Form.Item>
    </Form>
  );
};

export default KeywordFormContent;
