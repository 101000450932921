import { Typography } from 'antd';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { generateFilterParamsCount } from 'utils/other';
import HTMLTemplateFilter from './HTMLTemplateFilter';
import HTMLTemplateForm from './HTMLTemplateForm';
import HTMLTemplateList from './HTMLTemplateList';

const HTMLTemplate = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div>
      <Typography.Title>HTML template</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={HTMLTemplateList}
        secondary={HTMLTemplateFilter}
        filterDrawerTitle="Lọc HTML template"
      />

      <HTMLTemplateForm />
    </div>
  );
};

export default HTMLTemplate;
