import {
  FileDoneOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import { FEEDBACK_DICTIONARY, FILTER_PARAMS_PREFIX } from 'appConstants';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';

const Feedback = () => {
  const { feedbackStatus } = useDashboard(
    useCallback(
      ({ feedbackStatus }) => ({
        feedbackStatus
      }),
      []
    ),
    shallow
  );

  const status = useMemo(() => feedbackStatus?.status, [feedbackStatus]);
  const getValue = key => {
    return status?.find(s => s.code === key)?.count;
  };

  return (
    <div
      style={{
        background: 'white',
        padding: '10px',
        marginTop: '16px',
        borderRadius: '8px'
      }}
    >
      <Typography.Title level={5} strong>
        Feedback
      </Typography.Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <Link
            to={`/danh-sach-phan-hoi?${FILTER_PARAMS_PREFIX}status=resolved`}
          >
            <Space>
              <FileDoneOutlined
                style={{ fontSize: '32px', color: '#406882' }}
              />
              <Space size={0} direction="vertical">
                <Typography.Text>
                  {FEEDBACK_DICTIONARY['resolved']}
                </Typography.Text>
                <Typography.Text strong>{getValue('resolved')}</Typography.Text>
              </Space>
            </Space>
          </Link>
        </Col>
        <Col span={12}>
          <Link
            to={`/danh-sach-phan-hoi?${FILTER_PARAMS_PREFIX}status=waiting_feedback`}
          >
            <Space>
              <FileSyncOutlined
                style={{ fontSize: '32px', color: '#406882' }}
              />
              <Space size={0} direction="vertical">
                <Typography.Text>
                  {FEEDBACK_DICTIONARY['waiting_feedback']}
                </Typography.Text>
                <Typography.Text strong>
                  {getValue('waiting_feedback')}
                </Typography.Text>
              </Space>
            </Space>
          </Link>
        </Col>
        <Col span={12}>
          <Link
            to={`/danh-sach-phan-hoi?${FILTER_PARAMS_PREFIX}status=unresolved`}
          >
            <Space>
              <FileSearchOutlined
                style={{ fontSize: '32px', color: '#406882' }}
              />
              <Space size={0} direction="vertical">
                <Typography.Text>
                  {FEEDBACK_DICTIONARY['unresolved']}
                </Typography.Text>
                <Typography.Text strong>
                  {getValue('unresolved')}
                </Typography.Text>
              </Space>
            </Space>
          </Link>
        </Col>
        <Col span={12}>
          <Link to={`/danh-sach-phan-hoi`}>
            <Space>
              <FileTextOutlined
                style={{ fontSize: '32px', color: '#406882' }}
              />
              <Space size={0} direction="vertical">
                <Typography.Text>Tổng</Typography.Text>
                <Typography.Text strong>
                  {feedbackStatus?.total}
                </Typography.Text>
              </Space>
            </Space>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Feedback;
