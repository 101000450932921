import { Typography } from 'antd';

import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import PermissionList from './components/PermissionList';
import PermissionForm from './PermissionForm';
import PermissionFilter from './PermissionFilter';

import { generateFilterParamsCount } from 'utils/other';

const Permission = () => {
  const { search } = useLocation();
  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div className="spacing-container">
      <Typography.Title>Quyền hạn</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={PermissionList}
        secondary={PermissionFilter}
        filterDrawerTitle="Lọc quyền hạn"
      />

      <PermissionForm />
    </div>
  );
};

export default Permission;
