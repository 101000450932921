import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useClassSubject = create((set, get) => ({
  isClassSubjectFormLoading: false,
  setIsClassSubjectFormLoading: isClassSubjectFormLoading =>
    set({ isClassSubjectFormLoading }),
  isClassSubjectListLoading: false,
  setIsClassSubjectListLoading: isClassSubjectListLoading =>
    set({ isClassSubjectListLoading }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //* ********************** */
  //* NOTE: get classSubject */
  //* ********************** */

  classSubjectToEdit: null,
  setClassSubjectToEdit: classSubjectToEdit => set({ classSubjectToEdit }),

  updateClassSubject: async (params, id) => {
    get().setIsClassSubjectFormLoading(true);

    try {
      const { data } = await API.put(`/classsubject/${id}`, params);

      get().setIsClassSubjectFormLoading(false);

      if (data) {
        get().getClassSubjects();

        notification.success({
          message: 'Cập nhật môn - lớp thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsClassSubjectFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ************************ */
  //* NOTE: get classSubjects  */
  //* ************************ */

  classSubjects: [],
  totalPage: 1,
  fetchClassSubjects: async (params = {}) => {
    try {
      const { data } = await API.get('/classsubject', {
        params
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getClassSubjects: async (params = {}) => {
    get().setIsClassSubjectListLoading(true);

    try {
      const { data, total } = await get().fetchClassSubjects({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsClassSubjectListLoading(false);

      if (data) {
        set({
          total,
          classSubjects: data,
          totalPage: Math.ceil(total / PAGE_SIZE)
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsClassSubjectListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
