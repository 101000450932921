import { Tree } from 'antd';
import { apolloClient, QUERY_SYLLABUS } from 'api';
import React, { useCallback, useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';

const SyllabusTreeContent = ({ syllabusId }) => {
  const [data, setData] = useState(null);

  const formatTreeData = useCallback(data => {
    if (data) {
      const res = { title: data.name, key: data.id, isLeaf: false };

      if (data.sections.length === 0) {
        res.children = data.modules.map(({ id, moduleNo, name, articles }) => ({
          key: id,
          title: `Chương ${moduleNo} - ${name}`,
          isLeaf: false,
          children: articles.map(({ id, title, articleNo }) => ({
            key: id,
            title: `Bài ${articleNo} - ${title}`,
            isLeaf: true
          }))
        }));

        return [res];
      }

      res.children = data.sections.map(({ id, sectionNo, name, modules }) => ({
        key: id,
        title: `Phần ${sectionNo} - ${name}`,
        isLeaf: false,
        children: modules.map(({ id, moduleNo, name, articles }) => ({
          key: id,
          title: `Chương ${moduleNo} - ${name}`,
          isLeaf: false,
          children: articles.map(({ id, title, articleNo }) => ({
            key: id,
            title: `Bài ${articleNo} - ${title}`,
            isLeaf: true
          }))
        }))
      }));

      return [res];
    }
    return null;
  }, []);

  const fetchSyllabusInfo = useCallback(async () => {
    try {
      const { data } = await apolloClient.query({
        query: QUERY_SYLLABUS,
        variables: { id: syllabusId }
      });

      setData(formatTreeData(data.syllabus));
    } catch (e) {}
  }, [formatTreeData, syllabusId]);

  useEffect(() => {
    if (syllabusId) fetchSyllabusInfo();
  }, [fetchSyllabusInfo, syllabusId]);

  return (
    data && (
      <Tree
        defaultExpandAll
        selectable={false}
        showLine
        switcherIcon={<DownOutlined />}
        treeData={data}
      />
    )
  );
};

export default SyllabusTreeContent;
