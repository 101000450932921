import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';

import OTPInput from './OTPInput';

const OTPTimer = ({ started }) => {
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (!started) {
      return;
    }

    const timerInterval = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(timerInterval);
    }

    return () => {
      return clearInterval(timerInterval);
    };
  }, [started, timer]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Progress
        type="circle"
        percent={((60 - timer) / 60) * 100}
        format={percent => `${Math.ceil((percent / 100) * 60)}`}
      />

      <OTPInput />
    </div>
  );
};

export default OTPTimer;
