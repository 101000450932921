import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useSyllabus = create((set, get) => ({
  isSyllabusFormLoading: false,
  setIsSyllabusFormLoading: isSyllabusFormLoading =>
    set({ isSyllabusFormLoading }),
  isSyllabusListLoading: false,
  setIsSyllabusListLoading: isSyllabusListLoading =>
    set({ isSyllabusListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  syllabusTreeDrawerVisible: null,
  setSyllabusTreeDrawerVisible: syllabusTreeDrawerVisible =>
    set({ syllabusTreeDrawerVisible }),

  //* ****************** */
  //* NOTE: get syllabuses  */
  //* ****************** */

  searchForSyllabuses: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/syllabus/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  syllabuses: [],
  totalPage: 1,
  fetchSyllabuses: async (params = {}) => {
    try {
      const {
        data: { data, total }
      } = await API.get('/syllabus', {
        params
      });

      if (data) {
        return { data, total };
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getSyllabuses: async (params = {}) => {
    get().setIsSyllabusListLoading(true);

    try {
      const { data, total } = await get().fetchSyllabuses({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsSyllabusListLoading(false);

      if (data) {
        set({
          syllabuses: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSyllabusListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ****************** */
  //* NOTE: get syllabus */
  //* ****************** */

  syllabusToEdit: null,
  setSyllabusToEdit: syllabusToEdit => set({ syllabusToEdit }),

  updateSyllabus: async (params, id) => {
    get().setIsSyllabusFormLoading(true);

    try {
      const { data } = await API.put(`/syllabus/${id}`, params);

      get().setIsSyllabusFormLoading(false);

      if (data) {
        get().getSyllabuses();

        notification.success({
          message: 'Cập nhật giáo trình thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSyllabusFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ****************** */
  //* NOTE: get syllabus */
  //* ****************** */

  createSyllabus: async params => {
    get().setIsSyllabusFormLoading(true);

    try {
      const { data } = await API.post('/syllabus', params);

      get().setIsSyllabusFormLoading(false);

      if (data) {
        get().getSyllabuses();

        notification.success({
          message: 'Tạo giáo trình thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSyllabusFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************** */
  //* NOTE: delete syllabus  */
  //* ********************** */

  deleteSyllabus: async id => {
    get().setIsSyllabusListLoading(true);

    try {
      const { data } = await API.delete('/syllabus/' + id);

      get().setIsSyllabusListLoading(false);

      if (data) {
        if (id === get().syllabusToEdit?.id) {
          get().setSyllabusToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getSyllabuses();

        return notification.success({
          message: 'Xoá giáo trình thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSyllabusListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */

  updateSyllabusStatus: async params => {
    get().setIsSyllabusListLoading(true);

    try {
      const { data } = await API.patch('/syllabus', params);

      get().setIsSyllabusListLoading(false);

      if (data) {
        get().getSyllabuses();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa giáo trình thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSyllabusListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateSyllabusOrder: async params => {
    get().setIsSyllabusListLoading(true);

    try {
      const { data } = await API.patch('/syllabus/orders', params);

      get().setIsSyllabusListLoading(false);

      if (data) {
        return notification.success({
          message: 'Chỉnh sửa giáo trình thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSyllabusListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
