import React, { useCallback, useEffect, useMemo } from 'react';

import { Table } from 'antd';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import { columns } from '../columns';
import { useClassSubject } from 'stores/useClassSubject';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE } from 'appConstants';

import SortSelect from 'components/SortSelect/SortSelect';
import ListCard from 'components/ListCard';

const ClassSubjectList = () => {
  const { getClassSubjects, classSubjects, isClassSubjectListLoading, total } =
    useClassSubject(
      useCallback(
        ({
          getClassSubjects,
          classSubjects,
          isClassSubjectListLoading,
          total
        }) => ({
          getClassSubjects,
          classSubjects,
          isClassSubjectListLoading,
          total
        }),
        []
      ),
      shallow
    );

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-mon-lop',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-mon-lop',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getClassSubjects();
  }, [getClassSubjects, search]);

  return (
    <ListCard
      left="Danh sách môn - lớp"
      right={<SortSelect value={sort} onChange={setOrderBy} />}
    >
      <Table
        columns={columns}
        dataSource={classSubjects.map(obj => ({ ...obj, key: obj.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: classSubjects?.length
        }}
        loading={isClassSubjectListLoading}
      />
    </ListCard>
  );
};

export default ClassSubjectList;
