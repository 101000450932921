import React, { useMemo } from 'react';
import { Typography } from 'antd';

import SyllabusForm from './SyllabusForm';
import SyllabusList from './SyllabusList';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import SyllabusFilter from './SyllabusFilter';
import { useLocation } from 'react-router';
import { generateFilterParamsCount } from 'utils/other';
import SyllabusTree from './SyllabusTree';

const Syllabus = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div>
      <Typography.Title>Giáo trình</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={SyllabusList}
        secondary={SyllabusFilter}
        filterDrawerTitle="Lọc giáo trình"
      />

      <SyllabusForm />

      <SyllabusTree />
    </div>
  );
};

export default Syllabus;
