import React, { useCallback } from 'react';

import { DeleteOutlined, ApartmentOutlined } from '@ant-design/icons';
import { Space, Typography, Button } from 'antd';

import { useSyllabus } from 'stores/useSyllabus';
import shallow from 'zustand/shallow';

import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import StatusButton from 'components/StatusButton';
import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';

const EditSyllabusBtn = record => {
  const { deleteSyllabus, setSyllabusTreeDrawerVisible } = useSyllabus(
    useCallback(
      ({ deleteSyllabus, setSyllabusTreeDrawerVisible }) => ({
        deleteSyllabus,
        setSyllabusTreeDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <Button
        onClick={() => setSyllabusTreeDrawerVisible(record.id)}
        icon={<ApartmentOutlined />}
        size="small"
      />

      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deleteSyllabus(record.id)}
          title={'Xoá ' + record?.name + ' ?'}
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>
    </Space>
  );
};

const Status = ({ record }) => {
  const { updateSyllabusStatus } = useSyllabus(
    useCallback(
      ({ updateSyllabusStatus }) => ({
        updateSyllabusStatus
      }),
      []
    ),
    shallow
  );

  const onClick = key => {
    return updateSyllabusStatus([
      {
        ...record,
        status: key
      }
    ]);
  };

  return <StatusButton status={record?.status} onClick={onClick} />;
};

const Name = (name, record) => {
  const { setSyllabusToEdit, setDrawerVisible } = useSyllabus(
    useCallback(
      ({ setSyllabusToEdit, setDrawerVisible }) => ({
        setSyllabusToEdit,

        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setSyllabusToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Giáo trình',
    dataIndex: 'name',
    render: Name,
    align: 'left',
    width: '300px',
    fixed: 'left'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => <Status record={record} />,
    width: '220px'
  },
  {
    title: 'Nhà xuất bản',
    dataIndex: 'publisher',
    key: 'publisher',
    render: publisher => <Typography.Text>{publisher?.name}</Typography.Text>,
    width: '200px'
  },
  {
    title: 'Lớp',
    dataIndex: 'class',
    key: 'class',
    width: '60px',
    render: classObj => <Typography.Text>{classObj?.name}</Typography.Text>
  },
  {
    title: 'Môn học',
    dataIndex: 'subject',
    key: 'subject',
    width: '150px',
    render: subject => <Typography.Text>{subject?.name}</Typography.Text>
  },
  {
    title: 'Tập',
    dataIndex: 'part',
    render: part => <Typography.Text>{part}</Typography.Text>,
    align: 'left',
    width: '60px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: EditSyllabusBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
