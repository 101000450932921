import { Button, Card } from 'antd';
import React from 'react';

const HTMLItem = ({ title, code, closeModal, pushContent }) => {
  return (
    <Card
      size="small"
      title={title}
      extra={
        <Button
          type="link"
          onClick={() => {
            pushContent(code);
            closeModal();
          }}
        >
          Thêm
        </Button>
      }
      style={{ marginBottom: 16 }}
    >
      <div
        dangerouslySetInnerHTML={{ __html: code }}
        style={{ marginBottom: 8 }}
      />
    </Card>
  );
};

export default HTMLItem;
