import React, { useCallback, useMemo } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import {
  UserOutlined,
  FileTextOutlined,
  HomeOutlined,
  BookOutlined,
  UnorderedListOutlined,
  ReadOutlined,
  FieldBinaryOutlined,
  ShopOutlined,
  CrownOutlined,
  MailOutlined,
  TeamOutlined,
  EditOutlined,
  SnippetsOutlined,
  TagsOutlined,
  SearchOutlined,
  InteractionOutlined,
  NumberOutlined,
  CodeOutlined
} from '@ant-design/icons';

import { useLayout } from 'stores/useLayout';

import { get } from 'utils/lodash';
import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';
import logo from 'assets/images/navi-white.png';

import { FILTER_PARAMS_PREFIX, STATUS_VALUE } from 'appConstants';

const { Sider } = Layout;

const iconStyle = { fontSize: '20px' };

const menuItems = [
  {
    to: '/',
    code: '/',
    name: 'Bảng điều khiển',
    icon: <HomeOutlined style={iconStyle} />
  },
  {
    group: true,
    icon: <SnippetsOutlined style={iconStyle} />,
    name: 'Bài viết',
    items: [
      {
        to: '/danh-sach-bai-hoc',
        name: 'Bài tin',
        icon: <FileTextOutlined style={iconStyle} />,
        code: 'article',
        params: `?${FILTER_PARAMS_PREFIX}status=${STATUS_VALUE.DRAFT}`
      },
      {
        to: '/danh-sach-bai-tap',
        name: 'Bài tập',
        icon: <EditOutlined style={iconStyle} />,
        code: 'exercise'
      }
    ]
  },
  {
    to: '/danh-sach-chuong',
    name: 'Chương',
    icon: <UnorderedListOutlined style={iconStyle} />,
    code: 'module'
  },
  {
    to: '/danh-sach-phan',
    name: 'Phần',
    icon: <NumberOutlined style={iconStyle} />,
    code: 'section',
    params: `?${FILTER_PARAMS_PREFIX}status=${STATUS_VALUE.DRAFT}`
  },

  {
    to: '/danh-sach-giao-trinh',
    name: 'Giáo trình',
    icon: <BookOutlined style={iconStyle} />,
    code: 'syllabus',
    params: `?${FILTER_PARAMS_PREFIX}status=${STATUS_VALUE.DRAFT}`
  },
  {
    to: '/danh-sach-mon-lop',
    name: 'Môn - Lớp',
    icon: <InteractionOutlined style={iconStyle} />,
    code: 'classsubject'
  },
  {
    to: '/danh-sach-mon-hoc',
    name: 'Môn học',
    icon: <ReadOutlined style={iconStyle} />,
    code: 'subject'
  },
  {
    to: '/danh-sach-lop-hoc',
    name: 'Lớp',
    icon: <FieldBinaryOutlined style={iconStyle} />,
    code: 'class'
  },

  {
    to: '/danh-sach-nha-xuat-ban',
    name: 'Nhà xuất bản',
    icon: <ShopOutlined style={iconStyle} />,
    code: 'publisher'
  },
  {
    to: '/danh-sach-bien-tap-vien',
    name: 'Biên tập viên',
    icon: <TeamOutlined style={iconStyle} />,
    code: 'user'
  },
  {
    to: '/danh-sach-vai-tro',
    name: 'Vai trò',
    icon: <CrownOutlined style={iconStyle} />,
    code: 'role'
  },
  {
    to: '/danh-sach-quyen-han',
    name: 'Quyền hạn',
    icon: <UserOutlined style={iconStyle} />,
    code: 'permission'
  },
  {
    to: '/danh-sach-tag',
    name: 'Tag',
    icon: <TagsOutlined style={iconStyle} />,
    code: 'tag'
  },
  {
    to: '/danh-sach-phan-hoi',
    name: 'Phản hồi',
    icon: <MailOutlined style={iconStyle} />,
    code: 'feedback'
  },
  {
    to: '/danh-sach-tu-khoa',
    name: 'Từ khóa',
    icon: <SearchOutlined style={iconStyle} />,
    code: 'keyword'
  },

  {
    to: '/danh-sach-html',
    name: 'HTML template',
    icon: <CodeOutlined style={iconStyle} />
  }
];

const Sidebar = () => {
  const location = useLocation();
  const collapsed = useLayout(state => state.collapsed);
  const selectedKey = get(location.pathname.split('/'), [1], '');

  const { currentPermissions } = useCurrentUser(
    useCallback(
      ({ currentPermissions }) => ({
        currentPermissions
      }),
      []
    ),
    shallow
  );

  const pool = useMemo(
    () =>
      currentPermissions
        ? currentPermissions
            .filter(permission => permission.isRead)
            .map(permission => permission.code)
        : [],
    [currentPermissions]
  );

  const renderItem = useCallback(
    ({ to, icon, name, code, params }) => {
      if (pool.includes(code) || code === '/' || !code)
        return (
          <Menu.Item key={to.slice(1)} icon={icon}>
            <Link to={to + (params || '')}>{name}</Link>
          </Menu.Item>
        );

      return null;
    },
    [pool]
  );

  const renderItems = useCallback(() => {
    const stuffs = [];

    menuItems.forEach(({ to, icon, name, code, group, items, params }) => {
      if (group) {
        stuffs.push(
          <Menu.SubMenu key={name} title={name} icon={icon}>
            {items.map(({ to, icon, name, code, params }) =>
              renderItem({ to, icon, name, code, params })
            )}
          </Menu.SubMenu>
        );
      } else {
        stuffs.push(renderItem({ to, icon, name, code, params }));
      }
    });

    return stuffs;
  }, [renderItem]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="navi-logo">
        <Link to="/">
          <img alt="logo" src={logo} className="navi-logo-img" />
        </Link>
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
        {renderItems()}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
