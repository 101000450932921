import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';

import { useServices } from 'hooks/useServices';

const UserAutoComplete = ({ user, ...props }) => {
  const { searchForUsers } = useServices(
    useCallback(
      ({ searchForUsers }) => ({
        searchForUsers
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () => (user ? { value: user.guid, label: user.name } : null),
    [user]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      handleSearch={searchForUsers}
      labelKey="name"
      valueKey="guid"
      style={{ width: '100%' }}
      placeholder="Tìm biên tập viên"
      {...props}
    />
  );
};

export default UserAutoComplete;
