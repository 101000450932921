import React, { useCallback } from 'react';

import { useClassSubject } from 'stores/useClassSubject';
import shallow from 'zustand/shallow';
import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import TableRecordName from 'components/TableRecordName';

const Name = (name, record) => {
  const { setClassSubjectToEdit, setDrawerVisible } = useClassSubject(
    useCallback(
      ({ setClassSubjectToEdit, setDrawerVisible }) => ({
        setClassSubjectToEdit,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={record.subject?.name + ' - ' + record.class?.name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setClassSubjectToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Môn - Lớp',
    dataIndex: 'name',
    render: Name,
    align: 'left',
    width: '60%',
    fixed: 'left'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '40%',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  }
];
