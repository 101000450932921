import React, { useCallback, useEffect } from 'react';
import { Form, Input, Typography } from 'antd';
import { useHTMLTemplate } from 'stores/useHTMLTemplate';
import shallow from 'zustand/shallow';

const HTMLTemplateFormContent = ({ form }) => {
  const { HTMLTemplateToEdit } = useHTMLTemplate(
    useCallback(
      ({ HTMLTemplateToEdit }) => ({
        HTMLTemplateToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (HTMLTemplateToEdit) {
      form.setFieldsValue({
        title: HTMLTemplateToEdit.title,
        description: HTMLTemplateToEdit.description,
        code: HTMLTemplateToEdit.code
      });
    } else {
      form.resetFields();
    }
  }, [HTMLTemplateToEdit, form]);

  return (
    <>
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Tên"
          rules={[{ required: true, message: 'Nhập tên !' }]}
        >
          <Input placeholder="Nhập tên " />
        </Form.Item>

        <Form.Item name="description" label="Mô tả">
          <Input.TextArea placeholder="Nhập mô tả" />
        </Form.Item>

        <Form.Item name="code" label="Nội dung HTML">
          <Input.TextArea rows={10} placeholder="Nhập nội dung HTML" />
        </Form.Item>
      </Form>

      <Typography.Text>Preview</Typography.Text>

      {HTMLTemplateToEdit && (
        <div
          dangerouslySetInnerHTML={{
            __html: HTMLTemplateToEdit ? HTMLTemplateToEdit?.code : ''
          }}
        />
      )}
    </>
  );
};

export default HTMLTemplateFormContent;
