import React, { useCallback, useState } from 'react';
import { Col, Form, Input, Row, Button, Spin, notification } from 'antd';

import MetaCard from 'components/MetaCard';
import useAuth from 'stores/useAuth';
import shallow from 'zustand/shallow';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { changePassword, logout } = useAuth(
    useCallback(
      ({ changePassword, logout }) => ({
        changePassword,
        logout
      }),
      []
    ),
    shallow
  );

  const onSave = async () => {
    try {
      const formParams = await form.validateFields();

      if (formParams.newPassword !== formParams.retypeNewPassword) {
        throw new Error('Nhập lại mật khẩu mới không trùng khớp!');
      }

      const params = {
        newPassword: formParams.newPassword,
        oldPassword: formParams.oldPassword
      };

      setLoading(true);
      await changePassword(params, logout);
      setLoading(false);
    } catch (e) {
      notification.error({
        message: e?.message || DEFAULT_ERROR_MESSAGE
      });
      setLoading(false);
    }
  };

  return (
    <MetaCard title="Đổi mật khẩu" style={{ marginTop: '24px' }}>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Row gutter={32}>
            <Col span={6}>
              <Form.Item
                label="Mật khẩu"
                name="oldPassword"
                rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Mật khẩu mới"
                name="newPassword"
                rules={[{ required: true, message: 'Nhập mật khẩu mới!' }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Nhập lại mật khẩu mới"
                name="retypeNewPassword"
                rules={[{ required: true, message: 'Nhập lại mật khẩu mới!' }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button onClick={onSave} type="primary" loading={loading}>
              Lưu
            </Button>
          </Row>
        </Form>
      </Spin>
    </MetaCard>
  );
};

export default ChangePassword;
