import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { privateRoutes } from 'routes';
import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';
import { notification, Typography } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import { PERMISSION_ACTION_LABEL, PERMISSION_ACTION } from 'appConstants';

const PermissionButton = ({ children, keyAction }) => {
  const { currentPermissions } = useCurrentUser(
    useCallback(
      ({ currentPermissions }) => ({
        currentPermissions
      }),
      []
    ),
    shallow
  );

  const location = useLocation();
  const keyCode = privateRoutes.find(
    route => route.path === location?.pathname
  )?.keyCode;

  const isAuthAction = useMemo(() => {
    if (currentPermissions && keyCode) {
      const isAuth = currentPermissions.some(
        permission => permission.code === keyCode && permission[keyAction]
      );
      return isAuth;
    }
    return false;
  }, [currentPermissions, keyCode, keyAction]);

  const openNotification = () => {
    notification.open({
      message: (
        <Typography.Text strong>
          Không thể {PERMISSION_ACTION_LABEL[keyAction]}
        </Typography.Text>
      ),
      description: `Vui lòng liên hệ quản trị viên để được phân quyền.`,
      icon: <SafetyOutlined style={{ color: '#B00020' }} />
    });
  };

  return isAuthAction
    ? children
    : children &&
        React.cloneElement(
          keyAction === PERMISSION_ACTION.DELETE
            ? children.props.children
            : children,
          {
            onClick: openNotification
          }
        );
};

export default PermissionButton;
