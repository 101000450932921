import React, { useCallback, useEffect } from 'react';
import { Form } from 'antd';
import { useClass } from 'stores/useClass';
import shallow from 'zustand/shallow';

import { setError } from 'utils/other';
import ClassFormContent from './ClassFormContent';
import UpdateCreateModal from 'containers/UpdateCreateModal';

const ClassForm = () => {
  const {
    classToEdit,
    setClassToEdit,
    createClass,
    updateClass,
    setDrawerVisible,
    drawerVisible
  } = useClass(
    useCallback(
      ({
        classToEdit,
        setClassToEdit,
        createClass,
        updateClass,
        setDrawerVisible,
        drawerVisible
      }) => ({
        classToEdit,
        setClassToEdit,
        createClass,
        updateClass,
        setDrawerVisible,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updateClass(params, classToEdit.id);
      setClassToEdit(null);
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createClass(params);
      form.resetFields();
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  useEffect(() => {
    if (classToEdit) {
      form.setFieldsValue({
        name: classToEdit.name,
        slug: classToEdit.slug,
        description: classToEdit.description,
        seoTitle: classToEdit.seoTitle,
        seoDescription: classToEdit.seoDescription,
        seoKeyword: classToEdit.seoKeyword
      });
    } else {
      form.resetFields();
    }
  }, [classToEdit, form]);

  return (
    <UpdateCreateModal
      title={classToEdit ? 'Chỉnh sửa lớp' : 'Tạo lớp'}
      onOk={classToEdit ? onSave : onCreate}
      onClose={() => {
        setDrawerVisible(false);
        setClassToEdit(null);
      }}
      visible={drawerVisible}
    >
      <ClassFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default ClassForm;
