import React, { useCallback, useEffect } from 'react';
import { Form, Input, InputNumber } from 'antd';
import { useSection } from 'stores/useSection';
import shallow from 'zustand/shallow';
import SyllabusAutoComplete from 'containers/SyllabusAutoComplete';

const SectionFormContent = ({ form }) => {
  const { sectionToEdit } = useSection(
    useCallback(
      ({ sectionToEdit }) => ({
        sectionToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (sectionToEdit) {
      form.setFieldsValue({
        name: sectionToEdit.name,
        syllabusId: sectionToEdit.syllabusId,
        sectionNo: sectionToEdit.sectionNo
        // seoTitle: sectionToEdit.seoTitle,
        // seoDescription: sectionToEdit.seoDescription,
        // seoKeyword: sectionToEdit.seoKeyword
      });
    } else {
      form.resetFields();
    }
  }, [sectionToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Tên phần"
        rules={[{ required: true, message: 'Nhập tên phần!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="syllabusId"
        label="Giáo trình"
        rules={[{ required: true, message: 'Chọn giáo trình!' }]}
      >
        <SyllabusAutoComplete syllabus={sectionToEdit?.syllabus} />
      </Form.Item>

      <Form.Item
        name="sectionNo"
        label="Phần số"
        rules={[{ required: true, message: 'Nhập phần số!' }]}
      >
        <InputNumber min={1} style={{ width: '100%' }} />
      </Form.Item>

      {/* <Col span={12}>
          <Form.Item name="description" label="Mô tả">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="seoTitle" label="Tiêu đề SEO">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="seoDescription" label="Mô tả SEO">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="seoKeyword" label="Từ khoá SEO">
            <Input />
          </Form.Item>
        </Col> */}
    </Form>
  );
};

export default SectionFormContent;
