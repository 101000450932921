import { Form, Input } from 'antd';
import { FILTER_PARAMS_PREFIX } from 'appConstants';
import React from 'react';

const HTMLTemplateFilter = () => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'name'} label="Tên HTML template">
        <Input placeholder="Nhập tên" />
      </Form.Item>
    </>
  );
};

export default HTMLTemplateFilter;
