import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'appConstants';

const NaviDatePicker = ({ placeholder, onChange, ...rest }) => {
  return (
    <DatePicker
      placeholder={placeholder}
      onChange={onChange}
      format={DATE_FORMAT}
      {...rest}
    />
  );
};

NaviDatePicker.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

NaviDatePicker.defaultProps = {
  placeholder: 'Chọn ngày'
};

export default NaviDatePicker;
