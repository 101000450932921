import { EditOutlined, FileTextOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import {
  BAI_TAP,
  FILTER_PARAMS_PREFIX,
  LY_THUYET,
  STATUS_DICTIONARY
} from 'appConstants';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';

const numberStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  fontSize: '32px',
  color: 'white'
};

const ArticleCard = ({ status, backgroundColor = 'gray' }) => {
  const { articleStatus } = useDashboard(
    useCallback(
      ({ articleStatus }) => ({
        articleStatus
      }),
      []
    ),
    shallow
  );

  const paramFilterStatus = status
    ? `&${FILTER_PARAMS_PREFIX}status=${status}`
    : '';

  const article = useMemo(() => {
    if (!!articleStatus.length) {
      const article = articleStatus.find(
        item => item.bookTypeSlug === LY_THUYET
      );

      if (!article) return {};

      const quantity = status
        ? article?.status.find(item => item.code === status).count
        : article.total;
      const paramFilter = `?${FILTER_PARAMS_PREFIX}bookTypeId=${article.bookTypeId}${paramFilterStatus}`;
      const data = { quantity, paramFilter };
      return data;
    }
  }, [articleStatus, status, paramFilterStatus]);

  const exercise = useMemo(() => {
    if (!!articleStatus.length) {
      const exercise = articleStatus.find(
        item => item.bookTypeSlug === BAI_TAP
      );

      if (!exercise) return {};

      const quantity = status
        ? exercise?.status.find(item => item.code === status).count
        : exercise.total;
      const paramFilter = `?${FILTER_PARAMS_PREFIX}bookTypeId=${exercise.bookTypeId}${paramFilterStatus}`;
      const data = { quantity, paramFilter };
      return data;
    }
  }, [articleStatus, status, paramFilterStatus]);

  return (
    <div className="article-card" style={{ backgroundColor }}>
      <Typography.Title level={5} style={{ color: 'white' }}>
        {status ? STATUS_DICTIONARY[status] : 'Tổng'}
      </Typography.Title>
      <Row justify="space-between">
        <Col>
          <Link to={`/danh-sach-bai-hoc${article?.paramFilter || ''}`}>
            <FileTextOutlined
              style={{
                fontSize: '46px',
                color: backgroundColor,
                filter: 'brightness(0.95)'
              }}
            />
            <Typography.Text strong style={numberStyle}>
              {article?.quantity || 0}
            </Typography.Text>
          </Link>
        </Col>
        <Col>
          <Link to={`/danh-sach-bai-hoc${exercise?.paramFilter || ''}`}>
            <EditOutlined
              style={{
                fontSize: '46px',
                color: backgroundColor,
                filter: 'brightness(0.95)'
              }}
            />
            <Typography.Text strong style={numberStyle}>
              {exercise?.quantity || 0}
            </Typography.Text>
          </Link>
        </Col>
      </Row>
    </div>
  );
};
export default ArticleCard;
