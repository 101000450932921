import { Input, Form } from 'antd';
import { FILTER_PARAMS_PREFIX } from 'appConstants';
import FeedbackStatusSelect from 'components/FeedbackStatusSelect';
import React from 'react';

const FeedbackFilter = () => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'title'} label="Tiêu đề">
        <Input placeholder="Nhập tên môn học" />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'status'} label="Trạng thái">
        <FeedbackStatusSelect style={{ width: '100%' }} />
      </Form.Item>
    </>
  );
};

export default FeedbackFilter;
