import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useHTMLTemplate } from 'stores/useHTMLTemplate';
import shallow from 'zustand/shallow';

import { setError } from 'utils/other';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import HTMLTemplateFormContent from './HTMLTemplateFormContent';

const HTMLTemplateForm = () => {
  const {
    HTMLTemplateToEdit,
    setHTMLTemplateToEdit,
    updateHTMLTemplate,
    createHTMLTemplate,
    setDrawerVisible,
    drawerVisible
  } = useHTMLTemplate(
    useCallback(
      ({
        HTMLTemplateToEdit,
        setHTMLTemplateToEdit,
        updateHTMLTemplate,
        createHTMLTemplate,
        setDrawerVisible,
        drawerVisible
      }) => ({
        HTMLTemplateToEdit,
        setHTMLTemplateToEdit,
        updateHTMLTemplate,
        createHTMLTemplate,
        setDrawerVisible,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updateHTMLTemplate(params, HTMLTemplateToEdit.id);
      setHTMLTemplateToEdit(null);
      setDrawerVisible(false);
    } catch (errors) {
      setError(form, errors);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createHTMLTemplate(params);
      setDrawerVisible(false);
      form.resetFields();
    } catch (errors) {
      setError(form, errors);
    }
  };

  return (
    <UpdateCreateModal
      title={
        HTMLTemplateToEdit ? 'Chỉnh sửa HTML template' : 'Tạo HTML template'
      }
      onOk={HTMLTemplateToEdit ? onSave : onCreate}
      onClose={() => {
        setHTMLTemplateToEdit(null);
        setDrawerVisible(false);
      }}
      visible={drawerVisible}
      destroyOnClose
    >
      <HTMLTemplateFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default HTMLTemplateForm;
