import React from 'react';
import { Tag } from 'antd';

const ActiveTag = ({ active, ...props }) => {
  return (
    <Tag color={active ? 'success' : 'error'} {...props}>
      {active ? 'Đang hoạt động' : 'Đang bị khoá'}
    </Tag>
  );
};

export default ActiveTag;
