import React, { useState } from 'react';
import { Badge, Button } from 'antd';

import cn from 'classnames';
import { FilterOutlined } from '@ant-design/icons';
import FilterForm from 'components/FilterForm';

const DynamicFlexLayout = ({
  primary,
  secondary,
  badgeCount,
  filterDrawerTitle
}) => {
  const [expand, setExpand] = useState(false);

  const [mouseIn, setMouseIn] = useState(false);

  const onClick = () => {
    setExpand(expand => !expand);
  };

  const onMouseEnter = () => {
    setMouseIn(true);
  };

  const onMouseLeave = () => {
    setMouseIn(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={onClick}
        className={cn('dynamic-flex-layout-anchor', { mouseIn })}
        icon={
          <Badge count={badgeCount} size={'small'}>
            <FilterOutlined />
          </Badge>
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />

      {React.createElement(primary)}

      <FilterForm
        expand={expand}
        setExpand={setExpand}
        drawerTitle={filterDrawerTitle}
      >
        {React.createElement(secondary, { expand })}
      </FilterForm>
    </>
  );
};

export default DynamicFlexLayout;
