import Class from 'pages/Class';
import Subject from 'pages/Subject';
import Article from 'pages/Article';
import Syllabus from 'pages/Syllabus';
import SyllabusDetail from 'pages/SyllabusDetail';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';

import PasswordRecovery from 'pages/PasswordRecovery';
import ArticleDetail from 'pages/ArticleDetail';
import User from 'pages/User';
import Publisher from 'pages/Publisher';
import { Redirect } from 'react-router';
import CurrentUserDetail from 'pages/CurrentUserDetail';

import Module from 'pages/Module';
import Role from 'pages/Role';
import Permission from 'pages/Permission';
import NotFound from 'pages/NotFound';
import Unauthorized from 'pages/Unauthorized';
import FeedBack from 'pages/FeedBack';
import Exercise from 'pages/Exercise';
import ExerciseDetail from 'pages/ExerciseDetail';
import Tag from 'pages/Tag';
import Keyword from 'pages/Keyword';
import ClassSubject from 'pages/ClassSubject';
import Section from 'pages/Section';
import HTMLTemplate from 'pages/HTMLTemplate';
// import { Redirect } from 'react-router-dom';

export const publicRoutes = [
  {
    path: '/dang-nhap',
    component: Login
  },
  {
    path: '/quen-mat-khau',
    component: PasswordRecovery
  }
];

export const privateRoutes = [
  {
    path: '/',
    component: Dashboard,
    exact: true
  },
  {
    path: '/danh-sach-lop-hoc',
    component: Class,
    keyCode: 'class'
  },
  {
    path: '/danh-sach-mon-hoc',
    component: Subject,
    keyCode: 'subject'
  },
  {
    path: '/danh-sach-giao-trinh',
    component: Syllabus,
    keyCode: 'syllabus'
  },
  {
    path: '/danh-sach-phan',
    component: Section,
    keyCode: 'section'
  },
  {
    path: '/chi-tiet-giao-trinh/:id',
    component: SyllabusDetail,
    keyCode: 'syllabus'
  },
  {
    path: '/danh-sach-chuong',
    component: Module,
    keyCode: 'module'
  },

  {
    path: '/danh-sach-bai-hoc',
    component: Article,
    keyCode: 'article'
  },
  {
    path: '/chi-tiet-bai-hoc/:id?',
    component: ArticleDetail,
    keyCode: 'article'
  },

  {
    path: '/danh-sach-bai-tap',
    component: Exercise,
    keyCode: 'exercise'
  },
  {
    path: '/chi-tiet-bai-tap/:id?',
    component: ExerciseDetail,
    keyCode: 'article'
  },
  {
    path: '/danh-sach-bien-tap-vien',
    component: User,
    keyCode: 'user'
  },
  {
    path: '/danh-sach-nha-xuat-ban',
    component: Publisher,
    keyCode: 'publisher'
  },
  {
    path: '/danh-sach-vai-tro',
    component: Role,
    keyCode: 'role'
  },
  {
    path: '/danh-sach-quyen-han',
    component: Permission,
    keyCode: 'permission'
  },
  {
    path: '/ca-nhan',
    exact: true,
    component: () => <Redirect to="/ca-nhan/thong-tin-ca-nhan" />
  },
  {
    path: '/ca-nhan/:id',
    component: CurrentUserDetail
  },
  {
    path: '/danh-sach-phan-hoi',
    component: FeedBack,
    keyCode: 'feedback'
  },
  {
    path: '/danh-sach-tag',
    component: Tag,
    keyCode: 'tag'
  },
  {
    path: '/danh-sach-mon-lop',
    component: ClassSubject,
    keyCode: 'classsubject'
  },
  {
    path: '/danh-sach-tu-khoa',
    component: Keyword,
    keyCode: 'keyword'
  },
  {
    path: '/danh-sach-html',
    component: HTMLTemplate
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/unauthorized',
    component: Unauthorized
  }
];
