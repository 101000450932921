import { Avatar, List } from 'antd';
import {
  AppleOutlined,
  WindowsOutlined,
  QuestionOutlined
} from '@ant-design/icons';
import React from 'react';
import moment from 'moment';
import 'moment/locale/vi';
import UAParser from 'ua-parser-js';

const genIcon = type => {
  switch (type) {
    case 'Windows':
      return <WindowsOutlined />;

    case 'Mac OS X':
    case 'Mac OS':
      return <AppleOutlined />;

    default:
      return <QuestionOutlined />;
  }
};

const LoginHistoryList = ({ loggingHistory }) => {
  const renderItem = ({ ip, createdDate, userAgent }) => {
    const { os, browser } = UAParser(userAgent);

    return (
      <List.Item>
        <List.Item.Meta
          style={{ alignItems: 'center' }}
          avatar={<Avatar icon={genIcon(os?.name)} size={40} />}
          title={os?.name + ' ' + os?.version + ' · ' + ip}
          description={
            browser?.name +
            ' ' +
            browser?.major +
            ' · ' +
            moment(createdDate).format('DD [tháng] MM [lúc] HH:mm')
          }
        />
      </List.Item>
    );
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={loggingHistory}
      renderItem={renderItem}
    />
  );
};

export default LoginHistoryList;
