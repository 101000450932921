import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const usePublisher = create((set, get) => ({
  isPublisherFormLoading: false,
  setIsPublisherFormLoading: isPublisherFormLoading =>
    set({ isPublisherFormLoading }),
  isPublisherListLoading: false,
  setIsPublisherListLoading: isPublisherListLoading =>
    set({ isPublisherListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //* ******************* */
  //* NOTE: get publisher */
  //* ******************* */

  publisherToEdit: null,
  setPublisherToEdit: publisherToEdit => set({ publisherToEdit }),

  updatePublisher: async (params, id) => {
    get().setIsPublisherFormLoading(true);

    try {
      const { data } = await API.put(`/publisher/${id}`, params);

      get().setIsPublisherFormLoading(false);

      if (data) {
        get().getPublishers();

        notification.success({
          message: 'Cập nhật nhà xuất bản thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPublisherFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************** */
  //* NOTE: create publisher */
  //* ********************** */

  createPublisher: async params => {
    get().setIsPublisherFormLoading(true);

    try {
      const { data } = await API.post('/publisher', params);

      get().setIsPublisherFormLoading(false);

      if (data) {
        notification.success({
          message: 'Tạo nhà xuất bản thành công!'
        });

        get().getPublishers();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPublisherFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************* */
  //* NOTE: get publishers  */
  //* ********************* */

  publishers: [],
  totalPage: 1,
  fetchPublishers: async (params = {}) => {
    try {
      const { data } = await API.get('/publisher', {
        params
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getPublishers: async (params = {}) => {
    get().setIsPublisherListLoading(true);

    try {
      const { data, total } = await get().fetchPublishers({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsPublisherListLoading(false);

      if (data) {
        set({
          total,
          publishers: data,
          totalPage: Math.ceil(total / PAGE_SIZE)
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPublisherListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********************** */
  //* NOTE: delete publisher  */
  //* *********************** */

  deletePublisher: async id => {
    get().setIsPublisherListLoading(true);

    try {
      const { data } = await API.delete('/publisher/' + id);

      get().setIsPublisherListLoading(false);

      if (data) {
        if (id === get().publisherToEdit?.id) {
          get().setPublisherToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getPublishers();

        return notification.success({
          message: 'Xoá nhà xuất bản thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPublisherListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */

  updatePublisherStatus: async params => {
    get().setIsPublisherListLoading(true);

    try {
      const { data } = await API.patch('/publisher', params);

      get().setIsPublisherListLoading(false);

      if (data) {
        get().getPublishers();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa nhà xuất bản thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPublisherListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
