import React, { useCallback } from 'react';
import { Form } from 'antd';

import PermissionFormContent from './PermissionFormContent';

import { usePermission } from 'stores/usePermission';
import shallow from 'zustand/shallow';

import UpdateCreateModal from 'containers/UpdateCreateModal';

const PermissionForm = () => {
  const {
    drawerVisible,
    setDrawerVisible,
    permissionToEdit,
    setPermissionToEdit,
    updatePermission,
    createPermission
  } = usePermission(
    useCallback(
      ({
        drawerVisible,
        setDrawerVisible,
        permissionToEdit,
        setPermissionToEdit,
        updatePermission,
        createPermission
      }) => ({
        drawerVisible,
        setDrawerVisible,
        permissionToEdit,
        setPermissionToEdit,
        updatePermission,
        createPermission
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updatePermission({ ...params }, permissionToEdit.id);
      setPermissionToEdit(null);
      setDrawerVisible(false);
    } catch (e) {}
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createPermission(params);
      form.resetFields();
      setDrawerVisible(false);
    } catch (e) {}
  };

  return (
    <UpdateCreateModal
      title={permissionToEdit ? 'Chỉnh sửa quyền hạn' : 'Tạo quyền hạn'}
      onOk={permissionToEdit ? onSave : onCreate}
      onClose={() => {
        setDrawerVisible(false);
        setPermissionToEdit(null);
      }}
      visible={drawerVisible}
      destroyOnClose
    >
      <PermissionFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default PermissionForm;
