import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Space, Table } from 'antd';
import queryString from 'query-string';
import { PlusOutlined } from '@ant-design/icons';

import { columns } from '../columns';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useExercise } from 'stores/useExercise';
import shallow from 'zustand/shallow';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE, TABLE_PROPS } from 'appConstants';

import SortSelect from 'components/SortSelect';

import StatusSelectForList from 'components/StatusSelectForList';
import ListCard from 'components/ListCard';

const ExerciseList = () => {
  const {
    getExercises,
    exercises,
    isExerciseLoading,
    total,
    updateExerciseStatus,
    selectedRows,
    setSelectedRows
  } = useExercise(
    useCallback(
      ({
        getExercises,
        exercises,
        isExerciseLoading,
        total,
        updateExerciseStatus,
        selectedRows,
        setSelectedRows
      }) => ({
        getExercises,
        exercises,
        isExerciseLoading,
        total,
        updateExerciseStatus,
        selectedRows,
        setSelectedRows
      }),
      []
    ),
    shallow
  );
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const rowSelection = {
    selectedRowKeys: selectedRows.map(obj => obj.id),
    onChange: (_, selectedRows) => {
      return setSelectedRows(selectedRows);
    }
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-bai-tap',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-bai-tap',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getExercises();
  }, [getExercises, search]);

  return (
    <ListCard
      left="Danh sách bài tập"
      right={
        <>
          <Space>
            <StatusSelectForList
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              updateService={updateExerciseStatus}
            />

            <Link to="/chi-tiet-bai-tap">
              <Button type="primary" icon={<PlusOutlined />}>
                Thêm mới
              </Button>
            </Link>

            <SortSelect value={sort} onChange={setOrderBy} />
          </Space>
        </>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={exercises.map(obj => ({ ...obj, key: obj.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: exercises?.length
        }}
        rowSelection={rowSelection}
        loading={isExerciseLoading}
      />
    </ListCard>
  );
};

export default ExerciseList;
