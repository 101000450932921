import React, { useCallback } from 'react';
import { Avatar, Space, Typography } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { useSyllabusDetail } from 'stores/useSyllabusDetail';
import shallow from 'zustand/shallow';

const SyllabusInfoCard = () => {
  const { syllabus } = useSyllabusDetail(
    useCallback(
      ({ syllabus }) => ({
        syllabus
      }),
      []
    ),
    shallow
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
      <Avatar size={102} style={{ marginRight: 32 }} icon={<BookOutlined />} />

      <Space direction="vertical">
        <span>
          <Typography.Text strong>Tên giáo trình: </Typography.Text>
          <Typography.Text>{syllabus?.name}</Typography.Text>
        </span>

        <span>
          <Typography.Text strong>Nhà xuất bản: </Typography.Text>
          <Typography.Text>{syllabus?.publisher?.name}</Typography.Text>
        </span>

        <span>
          <Typography.Text strong>Lớp: </Typography.Text>
          <Typography.Text>{syllabus?.class?.name}</Typography.Text>
        </span>

        <span>
          <Typography.Text strong>Môn học: </Typography.Text>
          <Typography.Text>{syllabus?.subject?.name}</Typography.Text>
        </span>
      </Space>
    </div>
  );
};

export default SyllabusInfoCard;
