import { Input, Form } from 'antd';
import { FILTER_PARAMS_PREFIX } from 'appConstants';

import React from 'react';

const TagFilter = () => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'name'} label="Tag">
        <Input placeholder="Nhập tag" />
      </Form.Item>
    </>
  );
};

export default TagFilter;
