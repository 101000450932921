import React from 'react';

import { Form, Input } from 'antd';

import { FILTER_PARAMS_PREFIX } from 'appConstants';

const UserFilter = () => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'name'} label="Họ tên">
        <Input placeholder="Nhập họ tên" />
      </Form.Item>

      <Form.Item
        name={FILTER_PARAMS_PREFIX + 'phoneNumber'}
        label="Số điện thoại"
      >
        <Input placeholder="Nhập số điện thoại" />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'email'} label="Email">
        <Input placeholder="Nhập email" />
      </Form.Item>
    </>
  );
};

export default UserFilter;
