import React, { useCallback, useEffect } from 'react';
import { Checkbox, Form, Input, Space, Typography } from 'antd';
import { usePermission } from 'stores/usePermission';
import shallow from 'zustand/shallow';

import MasterDataSelect from 'containers/MasterDataSelect';

const PermissionFormContent = ({ form }) => {
  const { permissionToEdit } = usePermission(
    useCallback(
      ({ permissionToEdit }) => ({
        permissionToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (permissionToEdit) {
      form.setFieldsValue({
        title: permissionToEdit?.title,
        description: permissionToEdit?.description,
        code: permissionToEdit?.code,
        isRead: permissionToEdit?.isRead,
        isCreate: permissionToEdit?.isCreate,
        isUpdate: permissionToEdit?.isUpdate,
        isDelete: permissionToEdit?.isDelete
      });
    } else {
      form.resetFields();
    }
  }, [permissionToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="title"
        label="Tên quyền hạn"
        rules={[{ required: true, message: 'Nhập tên quyền hạn!' }]}
      >
        <Input placeholder="Nhập tên quyền hạn" />
      </Form.Item>

      <Form.Item name="description" label="Mô tả">
        <Input.TextArea placeholder="Nhập mô tả" />
      </Form.Item>

      <Form.Item
        name="code"
        label="Đối tượng"
        rules={[{ required: true, message: 'Chọn đối tượng!' }]}
      >
        <MasterDataSelect groupCode="resource" placeholder="Chọn đối tượng" />
      </Form.Item>

      <Space direction="vertical" size={0}>
        <Typography.Text className="ant-form-item-label">Quyền</Typography.Text>
        <Space>
          <Form.Item name="isRead" valuePropName="checked">
            <Checkbox>Đọc</Checkbox>
          </Form.Item>
          <Form.Item name="isCreate" valuePropName="checked">
            <Checkbox>Tạo</Checkbox>
          </Form.Item>
          <Form.Item name="isUpdate" valuePropName="checked">
            <Checkbox>Cập nhật</Checkbox>
          </Form.Item>
          <Form.Item name="isDelete" valuePropName="checked">
            <Checkbox>Xoá</Checkbox>
          </Form.Item>
        </Space>
      </Space>
    </Form>
  );
};

export default PermissionFormContent;
