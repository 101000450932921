import { Typography } from 'antd';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { generateFilterParamsCount } from 'utils/other';
import TagFilter from './TagFilter';
import TagForm from './TagForm';
import TagList from './TagList';

const Tag = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div className="spacing-container">
      <Typography.Title>Tag</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={TagList}
        secondary={TagFilter}
        filterDrawerTitle="Lọc tag"
      />

      <TagForm />
    </div>
  );
};

export default Tag;
