export const plugins = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount media image toc emoticons hr'
];
export const toolbar = {
  toolbar1:
    'undo redo | formatselect | ' +
    'blockquote bold italic strikethrough superscript subscript codeformat forecolor backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent toc | ' +
    'removeformat  | help',
  toolbar2:
    'image link media code | charmap emoticons hr | preview fullscreen | fileManagerBtn eqneditor mathMLBtn htmlBtn exerciseBtn previewBtn'
};
export const external_plugins = {
  eqneditor: '/eqneditor/plugin.min.js'
};
export const content_style =
  //body
  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' +
  //toc
  '.mce-toc { border: 1px solid rgba(1,1,1,0.2); } .mce-toc li { list-style-type: none; }';
