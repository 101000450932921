import React, { useCallback, useEffect } from 'react';
import { Form } from 'antd';
import { useSubject } from 'stores/useSubject';
import shallow from 'zustand/shallow';

import { setError } from 'utils/other';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import SubjectFormContent from './SubjectFormContent';

const SubjectForm = () => {
  const {
    subjectToEdit,
    setSubjectToEdit,
    createSubject,
    drawerVisible,
    updateSubject,
    setDrawerVisible
  } = useSubject(
    useCallback(
      ({
        subjectToEdit,
        setSubjectToEdit,
        createSubject,
        drawerVisible,
        updateSubject,
        setDrawerVisible
      }) => ({
        subjectToEdit,
        setSubjectToEdit,
        createSubject,
        drawerVisible,
        updateSubject,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updateSubject(
        {
          ...params,
          image: params?.image?.response?.preview || params.image
        },
        subjectToEdit.id
      );
      setSubjectToEdit(null);
      setDrawerVisible(false);
    } catch (errors) {
      setError(form, errors);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createSubject({
        ...params,
        image: params?.image?.response?.preview || params.image
      });
      form.resetFields();
      setDrawerVisible(false);
    } catch (errors) {
      setError(form, errors);
    }
  };

  useEffect(() => {
    if (subjectToEdit) {
      const classIds = subjectToEdit?.class?.map(({ id }) => id + '') || [];

      form.setFieldsValue({
        name: subjectToEdit.name,
        slug: subjectToEdit.slug,
        description: subjectToEdit.description,
        seoTitle: subjectToEdit.seoTitle,
        seoDescription: subjectToEdit.seoDescription,
        seoKeyword: subjectToEdit.seoKeyword,
        classIds,
        image: subjectToEdit?.image
      });
    } else {
      form.resetFields();
    }
  }, [subjectToEdit, form]);

  return (
    <UpdateCreateModal
      title={subjectToEdit ? 'Chỉnh sửa môn học' : 'Tạo môn học'}
      onOk={subjectToEdit ? onSave : onCreate}
      onClose={() => {
        setDrawerVisible(false);
        setSubjectToEdit(null);
      }}
      visible={drawerVisible}
      destroyOnClose
    >
      <SubjectFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default SubjectForm;
