import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useTag = create((set, get) => ({
  isTagFormLoading: false,
  setIsTagFormLoading: isTagFormLoading => set({ isTagFormLoading }),
  isTagListLoading: false,
  setIsTagListLoading: isTagListLoading => set({ isTagListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //* ******************* */
  //* NOTE: get tag */
  //* ******************* */

  tagToEdit: null,
  setTagToEdit: tagToEdit => set({ tagToEdit }),

  updateTag: async (params, id) => {
    get().setIsTagFormLoading(true);

    try {
      const { data } = await API.put(`/tag/${id}`, params);

      get().setIsTagFormLoading(false);

      if (data) {
        get().getTags();

        notification.success({
          message: 'Cập nhật tag thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsTagFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************** */
  //* NOTE: create tag */
  //* ********************** */

  createTag: async params => {
    get().setIsTagFormLoading(true);

    try {
      const { data } = await API.post('/tag', params);

      get().setIsTagFormLoading(false);

      if (data) {
        notification.success({
          message: 'Tạo tag thành công!'
        });

        get().getTags();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsTagFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************* */
  //* NOTE: get tags  */
  //* ********************* */

  tags: [],
  totalPage: 1,
  fetchTags: async (params = {}) => {
    try {
      const { data } = await API.get('/tag', {
        params
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getTags: async (params = {}) => {
    get().setIsTagListLoading(true);

    try {
      const { data, total } = await get().fetchTags({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsTagListLoading(false);

      if (data) {
        set({
          total,
          tags: data,
          totalPage: Math.ceil(total / PAGE_SIZE)
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsTagListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********************** */
  //* NOTE: delete tag  */
  //* *********************** */

  deleteTag: async id => {
    get().setIsTagListLoading(true);

    try {
      const { data } = await API.delete('/tag/' + id);

      get().setIsTagListLoading(false);

      if (data) {
        if (id === get().tagToEdit?.id) {
          get().setTagToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getTags();

        return notification.success({
          message: 'Xoá tag thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsTagListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */

  updateTagStatus: async params => {
    get().setIsTagListLoading(true);

    try {
      const { data } = await API.patch('/tag', params);

      get().setIsTagListLoading(false);

      if (data) {
        get().getTags();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa tag thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsTagListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
