import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useSubject = create((set, get) => ({
  isSubjectFormLoading: false,
  setIsSubjectFormLoading: isSubjectFormLoading =>
    set({ isSubjectFormLoading }),
  isSubjectListLoading: false,
  setIsSubjectListLoading: isSubjectListLoading =>
    set({ isSubjectListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //* ***************** */
  //* NOTE: get subject */
  //* ***************** */

  subjectToEdit: null,
  setSubjectToEdit: subjectToEdit => set({ subjectToEdit }),

  updateSubject: async (params, id) => {
    get().setIsSubjectFormLoading(true);

    await get().attachClassesToSubject(id, {
      classIds: params?.classIds
    });

    try {
      const { data } = await API.put(`/subject/${id}`, params);

      get().setIsSubjectFormLoading(false);

      if (data) {
        get().getSubjects();

        notification.success({
          message: 'Cập nhật môn học thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSubjectFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************** */
  //* NOTE: create subject */
  //* ********************** */

  createSubject: async params => {
    get().setIsSubjectFormLoading(true);

    try {
      const { data } = await API.post('/subject', params);

      get().setIsSubjectFormLoading(false);

      if (data) {
        await get().attachClassesToSubject(data.id, {
          classIds: params?.classIds
        });

        notification.success({
          message: 'Tạo môn học thành công!'
        });

        get().getSubjects();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSubjectFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ******************* */
  //* NOTE: get subjects  */
  //* ******************* */

  subjects: [],
  totalPage: 1,
  fetchSubjects: async (params = {}) => {
    try {
      const { data } = await API.get('/subject', {
        params: {
          PageSize: PAGE_SIZE,
          ...params
        }
      });

      if (!data) return null;

      return data;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getSubjects: async (params = {}) => {
    get().setIsSubjectListLoading(true);

    try {
      const res = await get().fetchSubjects({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsSubjectListLoading(false);

      if (res) {
        const { data, total } = res;
        set({ total, subjects: data, totalPage: Math.ceil(total / PAGE_SIZE) });
        return data.result;
      }

      notification.error({
        message: res?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSubjectListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************* */
  //* NOTE: delete subject  */
  //* ********************* */

  deleteSubject: async id => {
    get().setIsSubjectListLoading(true);

    try {
      const { data } = await API.delete('/subject/' + id);

      get().setIsSubjectListLoading(false);

      if (data) {
        if (id === get().subjectToEdit?.id) {
          get().setSubjectToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getSubjects();

        return notification.success({
          message: 'Xoá môn học thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSubjectListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************ */
  //* NOTE: other  */
  //* ************ */

  attachClassesToSubject: async (id, params) => {
    get().setIsSubjectListLoading(true);

    try {
      const { data } = await API.patch('/subject/' + id + '/class', params);

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      get().setIsSubjectListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateSubjectStatus: async params => {
    get().setIsSubjectListLoading(true);

    try {
      const { data } = await API.patch('/subject', params);

      get().setIsSubjectListLoading(false);

      if (data) {
        get().getSubjects();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa môn học thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSubjectListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateSubjectOrder: async params => {
    get().setIsSubjectListLoading(true);

    try {
      const { data } = await API.patch('/subject/orders', params);

      get().setIsSubjectListLoading(false);

      if (data) {
        return notification.success({
          message: 'Chỉnh sửa môn học thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSubjectListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
