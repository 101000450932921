import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useFeedback = create((set, get) => ({
  isFeedbackFormLoading: false,
  setIsFeedbackFormLoading: isFeedbackFormLoading =>
    set({ isFeedbackFormLoading }),
  isFeedbackListLoading: false,
  setIsFeedbackListLoading: isFeedbackListLoading =>
    set({ isFeedbackListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  feedbackToView: null,
  setFeedbackToView: feedbackToView => set({ feedbackToView }),

  //* ********************** */
  //* NOTE: create feedback */
  //* ********************** */

  createFeedback: async params => {
    get().setIsFeedbackFormLoading(true);

    try {
      const { data } = await API.post('/feedback', params);

      get().setIsFeedbackFormLoading(false);

      if (data) {
        // await get().attachClassesToFeedback(data.id, {
        //   classIds: params?.classIds
        // });

        notification.success({
          message: 'Tạo phản hồi thành công!'
        });

        // get().getFeedbacks();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsFeedbackFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ******************* */
  //* NOTE: get feedbacks  */
  //* ******************* */

  feedbacks: [],
  totalPage: 1,
  fetchFeedbacks: async (params = {}) => {
    try {
      const { data } = await API.get('/feedback', {
        params: {
          PageSize: PAGE_SIZE,
          ...params
        }
      });

      if (!data) return null;

      return data;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getFeedbacks: async (params = {}) => {
    get().setIsFeedbackListLoading(true);

    try {
      const res = await get().fetchFeedbacks({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsFeedbackListLoading(false);

      if (res) {
        const { data, total } = res;
        set({
          total,
          feedbacks: data,
          totalPage: Math.ceil(total / PAGE_SIZE)
        });
        return data.result;
      }

      notification.error({
        message: res?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsFeedbackListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************* */
  //* NOTE: delete feedback  */
  //* ********************* */

  deleteFeedback: async id => {
    get().setIsFeedbackListLoading(true);

    try {
      const { data } = await API.delete('/feedback/' + id);

      get().setIsFeedbackListLoading(false);

      if (data) {
        if (id === get().feedbackToEdit?.id) {
          get().setFeedbackToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getFeedbacks();

        return notification.success({
          message: 'Xoá phản hồi thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsFeedbackListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************ */
  //* NOTE: other  */
  //* ************ */

  updateFeedbackStatus: async params => {
    get().setIsFeedbackListLoading(true);

    try {
      const { data } = await API.patch('/feedback', params);

      get().setIsFeedbackListLoading(false);

      if (data) {
        get().getFeedbacks();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa phản hồi thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsFeedbackListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
