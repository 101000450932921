import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';
import { generateFileDownload } from 'utils/other';

export const useArticle = create((set, get) => ({
  isArticleLoading: false,
  setIsArticleLoading: isArticleLoading => set({ isArticleLoading }),
  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),
  //* ********************* */
  //* NOTE: get articles  */
  //* ********************* */

  articles: [],
  totalPage: 1,
  total: 1,
  getArticles: async (params = {}) => {
    get().setIsArticleLoading(true);

    try {
      const {
        data: { data, total }
      } = await API.get('/article', {
        params: {
          PageSize: PAGE_SIZE,
          ...params,
          ...getQueryParams()
        }
      });

      get().setIsArticleLoading(false);

      if (data) {
        set({ articles: data, totalPage: Math.ceil(total / PAGE_SIZE), total });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsArticleLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************* */
  //* NOTE: delete article  */
  //* ********************* */

  deleteArticle: async id => {
    get().setIsArticleLoading(true);

    try {
      const { data } = await API.delete('/article/' + id);

      get().setIsArticleLoading(false);

      if (data) {
        if (id === get().articleToEdit?.id) {
          get().setArticleToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getArticles();

        return notification.success({
          message: 'Xoá bài viết thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsArticleLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  deleteArticles: async params => {
    get().setIsArticleLoading(true);

    try {
      const { data } = await API.delete('/article', {
        data: params
      });

      get().setIsArticleLoading(false);

      if (data) {
        get().getArticles();

        return notification.success({
          message: 'Xoá bài viết thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsArticleLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */

  updateArticleStatus: async params => {
    get().setIsArticleLoading(true);

    try {
      const { data } = await API.patch('/article', params);

      get().setIsArticleLoading(false);

      if (data) {
        get().getArticles();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa bài viết thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsArticleLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: Export */
  //* *********** */

  exportArticles: async () => {
    get().setIsArticleLoading(true);

    try {
      const { data } = await API.get('/export/article', {
        params: {
          PageSize: PAGE_SIZE,
          ...getQueryParams()
        }
      });

      get().setIsArticleLoading(false);

      if (data?.link) {
        const { link } = data;
        generateFileDownload(link);
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsArticleLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
