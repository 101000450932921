import { Drawer } from 'antd';
import React, { useCallback } from 'react';
import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';
import UserLoginHistoryContent from './UserLoginHistoryContent';

const UserLoginHistory = () => {
  const { logDrawerVisible, setLogDrawerVisible } = useUser(
    useCallback(
      ({ logDrawerVisible, setLogDrawerVisible }) => ({
        logDrawerVisible,
        setLogDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <Drawer
      visible={!!logDrawerVisible}
      onClose={() => setLogDrawerVisible(null)}
      destroyOnClose
      title="Lịch sử đăng nhập"
    >
      <UserLoginHistoryContent userId={logDrawerVisible} />
    </Drawer>
  );
};

export default UserLoginHistory;
