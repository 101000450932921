import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

export const useServices = create((set, get) => ({
  searchForClasses: async (params = {}) => {
    try {
      const {
        data: { data }
      } = await API.get('/class/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          ...params
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForSubjects: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/subject/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForPublishers: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/publisher/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForUsers: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/users', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForSections: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/section/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  fetchArticleTypes: async (params = {}) => {
    try {
      const { data } = await API.get('/booktype', {
        params: {
          PageSize: 1000,
          Page: 0,
          ...params
        }
      });

      if (!data) return null;

      return data;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForPermissionCode: async (FreeText, GroupCode) => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          GroupCode,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForRoles: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/role/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForExercises: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/exercise/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForTags: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/tag/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  createTag: async params => {
    try {
      const { data } = await API.post('/tag', params);

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      throw data;
    }
  },
  searchForArticles: async args => {
    try {
      const {
        data: { data }
      } = await API.get('/article/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          ...args
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
