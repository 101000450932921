import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useServices } from 'hooks/useServices';

const SubjectAutoComplete = ({ subject, ...props }) => {
  const { searchForSubjects } = useServices(
    useCallback(
      ({ searchForSubjects }) => ({
        searchForSubjects
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () => (subject ? { value: subject.id, label: subject.name } : null),
    [subject]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeholder="Tìm môn học"
      handleSearch={searchForSubjects}
      labelKey="name"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default SubjectAutoComplete;
