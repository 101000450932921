import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { useSubject } from 'stores/useSubject';
import shallow from 'zustand/shallow';
import { Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MenuOutlined } from '@ant-design/icons';

const reOrder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SubjectOrderContent = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    subjects
  }));

  const [subjects, setSubjects] = useState([]);

  const { fetchSubjects } = useSubject(
    useCallback(
      ({ fetchSubjects }) => ({
        fetchSubjects
      }),
      []
    ),
    shallow
  );

  const handleFetchSubjects = useCallback(async () => {
    const { data } = await fetchSubjects({
      PageSize: 1000,
      Sort: 'Priority',
      Page: 0
    });

    setSubjects(
      data.map(({ id, ...other }) => ({ id: id + '', ...other })).reverse()
    );
  }, [fetchSubjects]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    setSubjects(subjects =>
      reOrder(subjects, result.source.index, result.destination.index)
    );
  };

  useEffect(() => {
    handleFetchSubjects();
  }, [handleFetchSubjects]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {subjects.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="subject-dnd-item">
                      <span className="subject-dnd-index">{index + 1}</span>

                      <UserAvatar
                        avatar={item.image}
                        fullName={item.name}
                        size={40}
                        className="subject-dnd-item-img"
                      />

                      <Typography.Text className="subject-dnd-item-text">
                        {item.name}
                      </Typography.Text>

                      <MenuOutlined />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default SubjectOrderContent;
