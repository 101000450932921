import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useServices } from 'hooks/useServices';

const ClassAutoComplete = ({ classObj, ...props }) => {
  const { searchForClasses } = useServices(
    useCallback(
      ({ searchForClasses }) => ({
        searchForClasses
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () => (classObj ? { value: classObj.id, label: classObj.name } : null),
    [classObj]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeholder="Tìm lớp"
      handleSearch={searchForClasses}
      labelKey="name"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ClassAutoComplete;
