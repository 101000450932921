import React, { useCallback } from 'react';
import { Card, Spin } from 'antd';

import { useSyllabusDetail } from 'stores/useSyllabusDetail';
import shallow from 'zustand/shallow';

import SyllabusInfoCard from './SyllabusInfoCard';
import SyllabusSelect from './SyllabusSelect';

const SyllabusInfo = () => {
  const { isSyllabusDetailLoading } = useSyllabusDetail(
    useCallback(
      ({ isSyllabusDetailLoading }) => ({
        isSyllabusDetailLoading
      }),
      []
    ),
    shallow
  );

  return (
    <Card title="Giáo trình" style={{ marginBottom: 16 }}>
      <Spin spinning={isSyllabusDetailLoading}>
        <SyllabusSelect />

        <SyllabusInfoCard />
      </Spin>
    </Card>
  );
};

export default SyllabusInfo;
