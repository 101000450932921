import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import {
  DEFAULT_ERROR_MESSAGE,
  PAGE_SIZE,
  PAGE_SIZE_ALL
} from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useRole = create((set, get) => ({
  isRolesFormLoading: false,
  setIsRolesFormLoading: isRolesFormLoading => set({ isRolesFormLoading }),
  isRolesListLoading: false,
  setIsRolesListLoading: isRolesListLoading => set({ isRolesListLoading }),
  //* ************** */
  //* NOTE: get role */
  //* ************** */
  roleToEdit: null,
  setRoleToEdit: roleToEdit => set({ roleToEdit }),
  isCreatingRole: false,
  createRole: async params => {
    get().setIsRolesFormLoading(true);

    try {
      const { data } = await API.post('/role', {
        ...params,
        active: true
      });
      get().setIsRolesFormLoading(false);

      // set({ isCreatingRole: false });

      if (data) {
        get().getRoles();

        notification.success({
          message: 'Tạo vai trò thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsRolesFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* **************** */
  //* NOTE: get roles  */
  //* **************** */

  roles: [],
  totalPage: 1,
  currentRole: null,
  setCurrentRole: role => set({ currentRole: role }),
  fetchRoles: async (params = {}) => {
    try {
      const { data } = await API.get('/role', {
        params
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getRoles: async (params, isGetAll) => {
    get().setIsRolesListLoading(true);

    try {
      const { data, total } = await get().fetchRoles({
        PageSize: isGetAll ? PAGE_SIZE_ALL : PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsRolesListLoading(false);

      if (data) {
        set({
          roles: data.map(role => ({ ...role, key: role.id })),
          currentRole: data[0],
          totalPage: Math.ceil(total / PAGE_SIZE),
          total
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsRolesListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ****************** */
  //* NOTE: update roles */
  //* ****************** */

  updateRole: async (params, id) => {
    get().setIsRolesFormLoading(true);

    try {
      const { data } = await API.put(`/role/${id}`, params);

      if (data) {
        set(({ roles }) => ({
          roles: roles.map(role =>
            role.id === data.id
              ? { ...data, permissions: role.permissions }
              : role
          )
        }));
        get().setIsRolesFormLoading(false);
        // get().getRoles();

        notification.success({
          message: 'Cập nhật vai trò thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsRolesFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: delete roles  */
  //* ******************* */

  deleteRoles: async id => {
    get().setIsRolesFormLoading(true);
    try {
      const { data } = await API.delete(`/role/${id}`);

      if (data) {
        // get().getRoles();
        const newRoles = get().roles.filter(role => role.id !== data.id);
        set({ roles: newRoles, currentRole: newRoles[0] });

        get().setIsRolesFormLoading(false);

        return notification.success({
          message: 'Xoá vai trò thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ****************** */
  //* NOTE: update role permissions */
  //* ****************** */

  updateRolePermission: async (params, id) => {
    get().setIsRolesFormLoading(true);

    try {
      const { data } = await API.patch(`/role/${id}/permission`, params);

      get().setIsRolesFormLoading(false);

      if (data) {
        set(({ roles }) => ({
          roles: roles.map(role =>
            role.id === id
              ? {
                  ...role,
                  permissions: params.permissionIds.map(permission => ({
                    id: permission
                  }))
                }
              : role
          )
        }));
        // get().getRoles();

        notification.success({
          message: 'Cập nhật quyền vai trò thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsRolesFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
