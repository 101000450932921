import React, { useCallback, useRef, useState } from 'react';

import 'moment/locale/vi';

import {
  HistoryOutlined,
  MailOutlined,
  CalendarOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { Space, Typography, Button, Modal, Form, Input, Tag } from 'antd';

import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';

import {
  GENDER_DICTIONARY,
  PERMISSION_ACTION,
  STATUS_ACTION_TIMEOUT
} from 'appConstants';

import UserAvatar from 'components/UserAvatar';
import ActiveTag from './components/DeactiveTag';
import ChangePassword from 'components/Icons/ChangePassword';
import PermissionButton from 'containers/PermissionButton';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';

import UnlockOutlined from '@ant-design/icons/UnlockOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import TableRecordName from 'components/TableRecordName';

const ChangePasswordModal = ({ record, changePassword }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const onClickPasswordChange = async () => {
    try {
      const params = await form.validateFields();
      await changePassword(record.id, params);
      setVisible(false);
      form.resetFields();
    } catch (e) {}
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        type="default"
        size="small"
        icon={<ChangePassword />}
      />

      <Modal
        title={'Đổi mật khẩu cho ' + record?.name}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        visible={visible}
        closable={false}
        onOk={onClickPasswordChange}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Mật khẩu mới"
            name="password"
            rules={[{ required: true, message: 'Nhập mật khẩu' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const EditUserBtn = record => {
  const { changePassword, setLogDrawerVisible } = useUser(
    useCallback(
      ({ changePassword, setLogDrawerVisible }) => ({
        changePassword,
        setLogDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <ChangePasswordModal record={record} changePassword={changePassword} />
      <Button
        onClick={() => setLogDrawerVisible(record.id)}
        type="default"
        size="small"
        icon={<HistoryOutlined />}
      />
    </Space>
  );
};

const UserStatus = ({ record }) => {
  const [actionVisible, setActionVisible] = useState(false);
  const timerRef = useRef();
  const { changeUserStatus } = useUser(
    useCallback(
      ({ changeUserStatus }) => ({
        changeUserStatus
      }),
      []
    ),
    shallow
  );

  const buttonStyle = useCallback(
    (unlocked = false) => ({
      style: {
        padding: '0 7px',
        height: 'auto',
        cursor: 'pointer'
      },
      icon: unlocked ? <LockOutlined /> : <UnlockOutlined />,
      type: 'primary',
      color: unlocked ? 'error' : 'success'
    }),
    []
  );

  const onClick = useCallback(
    val => {
      changeUserStatus([
        { id: record.id, active: !!val, status: record.status }
      ]);
    },
    [changeUserStatus, record.id, record.status]
  );

  const renderButtonAction = useCallback(() => {
    switch (record.active) {
      case false:
        return (
          <Tag {...buttonStyle()} onClick={() => onClick(true)}>
            Mở khóa
          </Tag>
        );

      case true:
        return (
          <Tag {...buttonStyle(true)} onClick={() => onClick()}>
            Khóa
          </Tag>
        );

      default:
        break;
    }
  }, [record.active, buttonStyle, onClick]);

  const onMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setActionVisible(true);
    }, STATUS_ACTION_TIMEOUT);
  };

  const onMouseLeave = () => {
    setActionVisible(false);
    clearTimeout(timerRef.current);
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ width: 'fit-content' }}
    >
      {actionVisible ? (
        renderButtonAction()
      ) : (
        <ActiveTag active={record?.active} style={{ cursor: 'pointer' }} />
      )}
    </div>
  );
};

const Name = (name, record) => {
  const { setUserToEdit, setDrawerVisible } = useUser(
    useCallback(
      ({ setUserToEdit, setDrawerVisible }) => ({
        setUserToEdit,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <Space direction="vertical">
      <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
        <TableRecordName
          name={name}
          setDrawerVisible={setDrawerVisible}
          setRecordToEdit={() => setUserToEdit(record)}
        />
      </PermissionButton>

      <UserStatus record={record} />
    </Space>
  );
};

export const columns = [
  {
    title: 'Avatar',
    dataIndex: 'image',
    key: 'image',
    render: (image, { name }) => <UserAvatar fullName={name} avatar={image} />,
    fixed: 'left',
    align: 'center',
    width: '100px'
  },
  {
    title: 'Họ tên',
    dataIndex: 'name',
    render: Name,
    align: 'left',
    width: '200px',
    fixed: 'left'
  },
  {
    title: 'Bút danh',
    dataIndex: 'pseudonym',
    render: pseudonym => <Typography.Text>{pseudonym}</Typography.Text>,
    width: '200px'
  },
  {
    title: 'Vai trò',
    dataIndex: 'role',
    render: role => <Typography.Text>{role?.roleName}</Typography.Text>,
    width: '150px'
  },
  {
    title: 'Thông tin',
    dataIndex: 'description',
    key: 'description',
    render: (_, { phoneNumber, email, gender }) => (
      <Space direction="vertical">
        <Typography.Text>
          <PhoneOutlined /> {phoneNumber}
        </Typography.Text>

        <Typography.Text>
          <MailOutlined /> {email}
        </Typography.Text>

        <Typography.Text>
          <CalendarOutlined /> {GENDER_DICTIONARY[gender]}
        </Typography.Text>
      </Space>
    ),
    width: '200px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: EditUserBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
