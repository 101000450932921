import { Input, Form } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import React from 'react';
import slugify from 'slugify';

const setting = {
  replacement: '-',
  lower: true,
  locale: 'vi',
  remove: /[*+~.()'"!:@,^#]/g
};

const SlugFormItem = ({ form, target, ...other }) => {
  const onClick = () => {
    const value = form.getFieldInstance([target])?.input?.value;
    form.setFieldsValue({ slug: slugify(value, setting) });
  };

  return (
    <Form.Item
      name="slug"
      label="Slug"
      rules={[{ required: true, message: 'Nhập slug!' }]}
      {...other}
    >
      <Input.Search
        onSearch={onClick}
        enterButton={<SyncOutlined />}
        placeholder="Nhập slug"
      />
    </Form.Item>
  );
};

export default SlugFormItem;
