import { Typography } from 'antd';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { generateFilterParamsCount } from 'utils/other';
import ExerciseList from './components/ExerciseList';
import ExerciseFilter from './ExerciseFilter';

const Exercise = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div>
      <Typography.Title>Bài tập</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={ExerciseList}
        secondary={ExerciseFilter}
        filterDrawerTitle="Lọc bài học"
      />
    </div>
  );
};

export default Exercise;
