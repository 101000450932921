import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useHTMLTemplate = create((set, get) => ({
  isHTMLTemplateFormLoading: false,
  setIsHTMLTemplateFormLoading: isHTMLTemplateFormLoading =>
    set({ isHTMLTemplateFormLoading }),
  isHTMLTemplateListLoading: false,
  setIsHTMLTemplateListLoading: isHTMLTemplateListLoading =>
    set({ isHTMLTemplateListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  HTMLTemplateTreeDrawerVisible: null,
  setHTMLTemplateTreeDrawerVisible: HTMLTemplateTreeDrawerVisible =>
    set({ HTMLTemplateTreeDrawerVisible }),

  //* ************************ */
  //* NOTE: get HTMLTemplates  */
  //* ************************ */

  searchForHTMLTemplates: async FreeText => {
    try {
      const {
        data: { data }
      } = await API.get('/htmlcodetemplate/search', {
        params: {
          PageSize: 10000,
          Page: 0,
          FreeText
        }
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  HTMLTemplates: [],
  totalPage: 1,
  fetchHTMLTemplates: async (params = {}) => {
    try {
      const {
        data: { data, total }
      } = await API.get('/htmlcodetemplate', {
        params
      });

      if (data) {
        return { data, total };
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getHTMLTemplates: async (params = {}) => {
    get().setIsHTMLTemplateListLoading(true);

    try {
      const { data, total } = await get().fetchHTMLTemplates({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsHTMLTemplateListLoading(false);

      if (data) {
        set({
          HTMLTemplates: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsHTMLTemplateListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************** */
  //* NOTE: get HTMLTemplate */
  //* ********************** */

  HTMLTemplateToEdit: null,
  setHTMLTemplateToEdit: HTMLTemplateToEdit => set({ HTMLTemplateToEdit }),

  updateHTMLTemplate: async (params, id) => {
    get().setIsHTMLTemplateFormLoading(true);

    try {
      const { data } = await API.put(`/htmlcodetemplate/${id}`, params);

      get().setIsHTMLTemplateFormLoading(false);

      if (data) {
        get().getHTMLTemplates();

        notification.success({
          message: 'Cập nhật HTML template thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsHTMLTemplateFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************** */
  //* NOTE: get HTMLTemplate */
  //* ********************** */

  createHTMLTemplate: async params => {
    get().setIsHTMLTemplateFormLoading(true);

    try {
      const { data } = await API.post('/htmlcodetemplate', params);

      get().setIsHTMLTemplateFormLoading(false);

      if (data) {
        get().getHTMLTemplates();

        notification.success({
          message: 'Tạo HTML template thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsHTMLTemplateFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ************************** */
  //* NOTE: delete HTMLTemplate  */
  //* ************************** */

  deleteHTMLTemplate: async id => {
    get().setIsHTMLTemplateListLoading(true);

    try {
      const { data } = await API.delete('/htmlcodetemplate/' + id);

      get().setIsHTMLTemplateListLoading(false);

      if (data) {
        if (id === get().HTMLTemplateToEdit?.id) {
          get().setHTMLTemplateToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getHTMLTemplates();

        return notification.success({
          message: 'Xoá HTML template thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsHTMLTemplateListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
