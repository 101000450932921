import React, {
  useCallback
  // ,
  //  useEffect, useMemo
} from 'react';
import { Select } from 'antd';
import { debounce } from 'utils/lodash';
import { DEBOUNCE_VALUE } from 'appConstants';
import { useSyllabus } from 'stores/useSyllabus';
import shallow from 'zustand/shallow';
import { useArticleDetail } from 'stores/useArticleDetail';

const ArticleSyllabusAutoComplete = ({ syllabus, ...props }) => {
  const { syllabusOptions, setSyllabusOptions } = useArticleDetail(
    useCallback(
      ({ syllabusOptions, setSyllabusOptions }) => ({
        syllabusOptions,
        setSyllabusOptions
      }),
      []
    ),
    shallow
  );

  const { searchForSyllabuses } = useSyllabus(
    useCallback(
      ({ searchForSyllabuses }) => ({
        searchForSyllabuses
      }),
      []
    ),
    shallow
  );

  const onSearch = async searchText => {
    if (!searchText) {
      return;
    }

    const results = await searchForSyllabuses(searchText);

    if (!results) {
      return;
    }

    return setSyllabusOptions(results);
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  return (
    <Select
      filterOption={false}
      notFoundContent={null}
      showSearch
      onSearch={debouncedOnSearch}
      options={syllabusOptions}
      placeholder="Tìm giáo trình"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ArticleSyllabusAutoComplete;
