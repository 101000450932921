import React, { useCallback } from 'react';

import 'moment/locale/vi';

import { DeleteOutlined } from '@ant-design/icons';
import { Space, Typography, Button } from 'antd';

import { usePermission } from 'stores/usePermission';
import shallow from 'zustand/shallow';

import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';

const { Text } = Typography;

const EditPermisionBtn = record => {
  const { deletePermissions } = usePermission(
    useCallback(
      ({ deletePermissions }) => ({
        deletePermissions
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deletePermissions(record)}
          title={
            <Text>
              Xoá quyền <Text strong>{record?.title} ?</Text>
            </Text>
          }
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>
    </Space>
  );
};

const Name = (name, record) => {
  const { setPermissionToEdit, setDrawerVisible } = usePermission(
    useCallback(
      ({ setPermissionToEdit, setDrawerVisible }) => ({
        setPermissionToEdit,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setPermissionToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Tên quyền',
    dataIndex: 'title',
    render: Name,
    align: 'left',
    width: '220px',
    fixed: 'left'
  },
  {
    title: 'Đối tượng',
    dataIndex: 'masterData',
    render: masterData => <Text>{masterData?.name}</Text>,
    width: '100px'
  },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: EditPermisionBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
