import API from 'api';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import create from 'zustand';
import { notification } from 'antd';

export const useCurrentUser = create(set => ({
  currentUser: null,
  currentUserRole: null,
  currentPermissions: null,

  //NOTE: cheat
  bookTypes: [],
  resetCurrentUser: () => set({ currentUser: null }),
  getCurrentUser: async () => {
    try {
      const { data } = await API.get('/auth/me');

      if (data) {
        set({
          currentUser: data,
          currentUserRole: data.role,
          currentPermissions: data.permissions,
          bookTypes: data.bookTypes
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateCurrentUserInfo: async params => {
    try {
      const { data } = await API.put('/auth/updateprofile', params);

      if (data) {
        set({
          currentUser: data,
          currentUserRole: data.role,
          currentPermissions: data.permissions
        });

        notification.success({
          message: 'Cập nhật thông tin thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
