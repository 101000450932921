import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useKeyword } from 'stores/useKeyword';
import shallow from 'zustand/shallow';

import { setError } from 'utils/other';
import KeywordFormContent from './KeywordFormContent';
import UpdateCreateModal from 'containers/UpdateCreateModal';

const KeywordForm = () => {
  const {
    keywordToEdit,
    setKeywordToEdit,
    createKeyword,
    updateKeyword,
    setDrawerVisible,
    drawerVisible
  } = useKeyword(
    useCallback(
      ({
        keywordToEdit,
        setKeywordToEdit,
        createKeyword,
        updateKeyword,
        setDrawerVisible,
        drawerVisible
      }) => ({
        keywordToEdit,
        setKeywordToEdit,
        createKeyword,
        updateKeyword,
        setDrawerVisible,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updateKeyword(params, keywordToEdit.id);
      setKeywordToEdit(null);
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createKeyword(params);
      form.resetFields();
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  return (
    <UpdateCreateModal
      title={keywordToEdit ? 'Chỉnh sửa từ khóa' : 'Tạo từ khóa'}
      onOk={keywordToEdit ? onSave : onCreate}
      onClose={() => {
        setDrawerVisible(false);
        setKeywordToEdit(null);
      }}
      visible={drawerVisible}
    >
      <KeywordFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default KeywordForm;
