import React, { useCallback, useEffect, useState } from 'react';

import shallow from 'zustand/shallow';
import { Select } from 'antd';

import { useClass } from 'stores/useClass';

const ClassSelect = ({ condition, ...props }) => {
  const [options, setOptions] = useState([]);
  const { fetchClasses } = useClass(
    useCallback(
      ({ fetchClasses }) => ({
        fetchClasses
      }),
      []
    ),
    shallow
  );

  const handleFetchSubjects = useCallback(async () => {
    const data = await fetchClasses({
      PageSize: 1000,
      Page: 0,
      SubjectId: condition
    });

    setOptions(
      data?.data.map(({ id, name }) => ({ value: id + '', label: name }))
    );
  }, [fetchClasses, condition]);

  useEffect(() => {
    handleFetchSubjects();
  }, [handleFetchSubjects]);

  return (
    <Select
      options={options}
      placeholder="Chọn lớp"
      style={{ width: '100%' }}
      allowClear
      showSearch
      optionFilterProp="label"
      {...props}
    />
  );
};

export default ClassSelect;
