import React, { useCallback, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useClass } from 'stores/useClass';
import shallow from 'zustand/shallow';
import SlugFormItem from 'components/SlugFormItem';

const ClassFormContent = ({ form }) => {
  const { classToEdit } = useClass(
    useCallback(
      ({ classToEdit }) => ({
        classToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (classToEdit) {
      form.setFieldsValue({
        name: classToEdit.name,
        slug: classToEdit.slug,
        description: classToEdit.description,
        seoTitle: classToEdit.seoTitle,
        seoDescription: classToEdit.seoDescription,
        seoKeyword: classToEdit.seoKeyword
      });
    } else {
      form.resetFields();
    }
  }, [classToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Tên lớp"
        rules={[{ required: true, message: 'Nhập tên lớp!' }]}
      >
        <Input placeholder="Nhập tên lớp" />
      </Form.Item>

      <SlugFormItem form={form} target="name" />

      <Form.Item name="description" label="Mô tả">
        <Input.TextArea placeholder="Nhập mô tả" />
      </Form.Item>

      <Form.Item name="seoTitle" label="Tiêu đề SEO">
        <Input placeholder="Nhập tiêu đề SEO" />
      </Form.Item>

      <Form.Item name="seoDescription" label="Mô tả SEO">
        <Input placeholder="Nhập mô tả SEO" />
      </Form.Item>

      <Form.Item name="seoKeyword" label="Từ khoá SEO">
        <Input placeholder="Nhập từ khoá SEO" />
      </Form.Item>
    </Form>
  );
};

export default ClassFormContent;
