import React from 'react';
import { Form, Input } from 'antd';

import StatusSelect from 'components/StatusSelect';
import { FILTER_PARAMS_PREFIX } from 'appConstants';
import UserAutoCompleteFilter from 'containers/UserAutoCompleteFilter';

const ExerciseFilter = ({ form }) => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'title'} label="Từ khóa">
        <Input placeholder="Nhập tiêu đề" />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'status'} label="Trạng thái">
        <StatusSelect style={{ width: '100%', fontWeight: 'normal' }} />
      </Form.Item>

      <UserAutoCompleteFilter form={form} />
    </>
  );
};

export default ExerciseFilter;
