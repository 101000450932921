import React, { useCallback, useEffect, useState } from 'react';

import shallow from 'zustand/shallow';
import { Select } from 'antd';

import { useServices } from 'hooks/useServices';

const ArticleTypeSelect = ({ condition, form, ...props }) => {
  const [options, setOptions] = useState([]);

  const { fetchArticleTypes } = useServices(
    useCallback(
      ({ fetchArticleTypes }) => ({
        fetchArticleTypes
      }),
      []
    ),
    shallow
  );

  const handleFetchSubjects = useCallback(async () => {
    const { data } = await fetchArticleTypes();

    setOptions(
      data.map(({ id, name, slug }) => ({ value: id + '', label: name, slug }))
    );
  }, [fetchArticleTypes]);

  useEffect(() => {
    handleFetchSubjects();
  }, [handleFetchSubjects]);

  return (
    <Select
      options={options}
      placeholder="Chọn loại bài"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ArticleTypeSelect;
