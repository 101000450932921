import { Col, Row, Space, Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import React from 'react';

const UserCard = ({ user }) => {
  const { name, image, email } = user;
  return (
    <Row align="middle" gutter={8} className="user-card" wrap={false}>
      <Col>
        <UserAvatar shape="square" fullName={name} avatar={image} size={42} />
      </Col>
      <Col flex="auto">
        <Space size={0} direction="vertical" style={{ width: '100%' }}>
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            style={{ marginBottom: '0' }}
          >
            {name}
          </Typography.Paragraph>
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            style={{ marginBottom: '0' }}
          >
            {email}
          </Typography.Paragraph>
        </Space>
      </Col>
    </Row>
  );
};

export default UserCard;
