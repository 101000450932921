import { Spin, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSyllabusDetail } from 'stores/useSyllabusDetail';
import shallow from 'zustand/shallow';
import SyllabusInfo from './components/SyllabusInfo';
import SyllabusTree from './components/SyllabusTree';

const SyllabusDetail = () => {
  const { id } = useParams();

  const { isSyllabusDetailLoading, getSyllabus } = useSyllabusDetail(
    useCallback(
      ({ isSyllabusDetailLoading, getSyllabus }) => ({
        isSyllabusDetailLoading,
        getSyllabus
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getSyllabus(id);
  }, [id, getSyllabus]);

  return (
    <Spin spinning={isSyllabusDetailLoading}>
      <Typography.Title>Chi tiết giáo trình</Typography.Title>

      <SyllabusInfo />

      <SyllabusTree />
    </Spin>
  );
};

export default SyllabusDetail;
