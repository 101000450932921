import React, { useState } from 'react';
import { Dropdown, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import UserDropdownContent from './UserDropdownContent';

const UserDropdown = () => {
  const [visible, setVisible] = useState(false);
  const closeDropdown = () => setVisible(false);

  return (
    <>
      <Dropdown
        overlay={<UserDropdownContent closeDropdown={closeDropdown} />}
        placement="bottomRight"
        trigger="click"
        visible={visible}
        onVisibleChange={visible => setVisible(visible)}
        arrow
      >
        <Button
          style={{ minWidth: 40, minHeight: 40 }}
          type="normal"
          shape="circle"
          icon={<CaretDownOutlined />}
        />
      </Dropdown>
    </>
  );
};

export default UserDropdown;
