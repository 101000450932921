import { Button, Drawer, Space } from 'antd';
import React, { useCallback } from 'react';
import { useFeedback } from 'stores/useFeedback';
import shallow from 'zustand/shallow';
import FeedbackDetail from './FeedbackDetail';

const FeedbackViewingDrawer = () => {
  const { setDrawerVisible, setFeedbackToView, drawerVisible } = useFeedback(
    useCallback(
      ({ setDrawerVisible, setFeedbackToView, drawerVisible }) => ({
        setDrawerVisible,
        setFeedbackToView,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const onClose = () => {
    setDrawerVisible(false);
    setFeedbackToView(null);
  };

  return (
    <Drawer
      title="Xem phản hồi"
      onClose={onClose}
      visible={drawerVisible}
      destroyOnClose
      width={600}
      closable={false}
      extra={
        <Space>
          <Button onClick={onClose}>Hủy</Button>
        </Space>
      }
    >
      <FeedbackDetail />
    </Drawer>
  );
};

export default FeedbackViewingDrawer;
