import React from 'react';
import { BellOutlined } from '@ant-design/icons';

import { Button, notification } from 'antd';

const NotificationDropdown = () => {
  const onClick = () => {
    notification.success({ message: 'Có gì đâu mà bấm' });
  };

  return (
    <>
      {/* <Dropdown
        overlay={<div>a</div>}
        placement="bottomRight"
        trigger="click"
        visible={visible}
        onVisibleChange={visible => setVisible(visible)}
        arrow
      > */}
      <Button
        style={{ minWidth: 40, minHeight: 40 }}
        type="normal"
        shape="circle"
        icon={<BellOutlined />}
        onClick={onClick}
      />
      {/* </Dropdown> */}
    </>
  );
};

export default NotificationDropdown;
