import { Checkbox, Collapse, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePermission } from 'stores/usePermission';
import { useRole } from 'stores/useRole';
import { generateKeyPanel, groupBy } from 'utils/lodash';
import shallow from 'zustand/shallow';

const { Panel } = Collapse;

const RolePanel = ({ form }) => {
  const [groupPermissions, setGroupPermissions] = useState([]);
  const { getPermissions, permissions } = usePermission(
    useCallback(
      ({ getPermissions, permissions }) => ({
        getPermissions,
        permissions
      }),
      []
    ),
    shallow
  );

  const { currentRole } = useRole(
    useCallback(
      ({ currentRole }) => ({
        currentRole
      }),
      []
    ),
    shallow
  );

  const isUpdateRole = useMemo(() => currentRole, [currentRole]);

  useEffect(() => {
    if (!permissions.length) {
      getPermissions({}, true);
    }
  }, [getPermissions, permissions]);

  useEffect(() => {
    if (!!permissions.length) {
      const groupPermissions = groupBy(permissions, 'code');
      setGroupPermissions(Object.values(groupPermissions));
    }
  }, [permissions]);

  useEffect(() => {
    if (!!permissions.length && currentRole) {
      // Set role
      const permissionIds = currentRole.permissions.map(
        permission => permission.id
      );
      form.setFieldsValue({ permissions: permissionIds });
    } else {
      form.setFieldsValue({ permissions: [] });
    }
  }, [permissions, currentRole, form]);

  return !!groupPermissions.length ? (
    <Collapse defaultActiveKey={generateKeyPanel(groupPermissions)}>
      {groupPermissions.map((group, index) => (
        <Panel
          // header={
          //   <Checkbox
          //     onClick={e => e.stopPropagation()}
          //     value={`${group[0].code}`}
          //     //   disabled={isAddingRole}
          //   >
          //     {group[0]?.masterData?.name}
          //   </Checkbox>
          // }
          header={group[0]?.masterData?.name}
          key={index + 1}
        >
          <Space direction="vertical">
            {group.map(permission => (
              <Checkbox
                value={permission.id}
                key={permission.id}
                disabled={!isUpdateRole}
              >
                {permission.title}
              </Checkbox>
            ))}
          </Space>
        </Panel>
      ))}
    </Collapse>
  ) : null;
};

export default RolePanel;
