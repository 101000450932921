import React from 'react';
import { Layout } from 'antd';

import CurrentUser from 'containers/CurrentUserMenu';

const { Header } = Layout;

const NaviHeader = () => {
  // const toggleMenu = useLayout(state => state.toggleMenu);
  return (
    <Header className="navi-header">
      {/* <MenuUnfoldOutlined onClick={toggleMenu} className="icon-toggle" /> */}

      <CurrentUser />
    </Header>
  );
};

export default NaviHeader;
