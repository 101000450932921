import React, { useCallback, useEffect, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Space, Button } from 'antd';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import { columns } from '../columns';
import { useKeyword } from 'stores/useKeyword';
import {
  ORDER_BY_SELECT_VALUE,
  PAGE_SIZE,
  PERMISSION_ACTION
} from 'appConstants';

import SortSelect from 'components/SortSelect/SortSelect';
import StatusSelectForList from 'components/StatusSelectForList';
import PermissionButton from 'containers/PermissionButton';
import ListCard from 'components/ListCard';

const KeywordList = () => {
  const {
    getKeywords,
    keywords,
    isKeywordListLoading,
    total,
    selectedRows,
    setSelectedRows,
    updateKeywordStatus,
    setDrawerVisible
  } = useKeyword(
    useCallback(
      ({
        getKeywords,
        keywords,
        isKeywordListLoading,
        total,
        selectedRows,
        setSelectedRows,
        updateKeywordStatus,
        setDrawerVisible
      }) => ({
        getKeywords,
        keywords,
        isKeywordListLoading,
        total,
        selectedRows,
        setSelectedRows,
        updateKeywordStatus,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRows.map(obj => obj.id),
      onChange: (_, selectedRows) => {
        return setSelectedRows(selectedRows);
      }
    }),
    [setSelectedRows, selectedRows]
  );

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-keyword',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-keyword',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getKeywords();
  }, [getKeywords, search]);

  return (
    <ListCard
      left={'Danh sách từ khóa'}
      right={
        <Space>
          <StatusSelectForList
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            updateService={updateKeywordStatus}
          />

          <PermissionButton keyAction={PERMISSION_ACTION.CREATE}>
            <Button
              onClick={() => setDrawerVisible(true)}
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </PermissionButton>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        columns={columns}
        dataSource={keywords.map(obj => ({ ...obj, key: obj.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: keywords?.length
        }}
        rowSelection={rowSelection}
        loading={isKeywordListLoading}
      />
    </ListCard>
  );
};

export default KeywordList;
