import React, { useRef } from 'react';
import { Modal } from 'antd';
import FileManager from 'containers/FileManager';
import {
  AUDIO_FILE_TYPES,
  IMG_FILE_TYPES,
  VIDEO_FILE_TYPES
} from 'appConstants';
import { processFileUrl } from 'utils/other';
import axios from 'axios';
import { getCookie, setCookie } from 'utils/authority';

const FileManagerModal = ({
  closeModal,
  visible,
  setItem,
  item,
  pushContent
}) => {
  const fileManagerRef = useRef(null);

  const onFileSelect = event => {
    const { action, fileDetails } = event;
    if (
      action === 'unselect' ||
      ![
        ...IMG_FILE_TYPES,
        ...VIDEO_FILE_TYPES,
        ...AUDIO_FILE_TYPES,
        '.html'
      ].includes(fileDetails.type)
    ) {
      return setItem(null);
    }

    return setItem({
      name: fileDetails.name,
      type: fileDetails.type,
      url: processFileUrl(fileDetails),
      path: '/' + fileDetails?.filterPath
    });
  };

  const closeFileManagerModal = () => {
    closeModal();
    setItem(null);
    fileManagerRef.current.clearSelection();
  };

  const handleOk = async () => {
    if (!item) return;

    const { name, url, type, path } = item;

    let htmlString = '';
    setCookie('FILE_MANAGER_DEFAULT_PATH', path);

    switch (true) {
      case IMG_FILE_TYPES.includes(type):
        htmlString = `<img src="${url}" alt="${name.replace(type, '')}" />`;
        break;

      case AUDIO_FILE_TYPES.includes(type):
        htmlString = `<audio controls src="${url}" />`;
        break;

      case VIDEO_FILE_TYPES.includes(type):
        htmlString = `<video controls src="${url}"  />`;
        break;

      case type === '.html':
        const { data } = await axios.get(url);
        htmlString = data;
        break;

      default:
        break;
    }

    pushContent(htmlString);

    closeFileManagerModal();
  };

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      onOk={handleOk}
      onCancel={closeModal}
      cancelText="Hủy"
      width="90%"
      destroyOnClose
    >
      <FileManager
        fileSelect={onFileSelect}
        ref={fileManagerRef}
        height="600px"
        path={getCookie('FILE_MANAGER_DEFAULT_PATH') || '/'}
      />
    </Modal>
  );
};

export default FileManagerModal;
