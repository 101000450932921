import React from 'react';
import { Tag } from 'antd';
import { FEEDBACK_DICTIONARY, FEEDBACK_VALUE } from 'appConstants';

const generateColor = status => {
  switch (status) {
    case FEEDBACK_VALUE.UNRESOLVED:
      return 'orange';

    case FEEDBACK_VALUE.WAITING_FEEDBACK:
      return 'blue';

    case FEEDBACK_VALUE.RESOLVED:
      return 'green';

    default:
      break;
  }
};

const FeedbackStatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {FEEDBACK_DICTIONARY[status]}
    </Tag>
  );
};

export default FeedbackStatusTag;
