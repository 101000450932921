import React, { useMemo } from 'react';
import { Form, Input } from 'antd';
import queryString from 'query-string';

import SectionAutoComplete from 'containers/SectionAutoComplete';

import { useLocation } from 'react-router';
import { FILTER_PARAMS_PREFIX } from 'appConstants';

const SectionAutoCompleteFilter = ({ form }) => {
  const { search } = useLocation();

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const section = useMemo(() => {
    if (
      !searchParams[FILTER_PARAMS_PREFIX + 'sectionId'] ||
      !searchParams.sectionName
    )
      return null;

    return {
      id: searchParams[FILTER_PARAMS_PREFIX + 'sectionId'],
      name: searchParams.sectionName
    };
  }, [searchParams]);

  const onSelect = (_, record) => {
    form.setFieldsValue({ sectionName: record.label || record.children });
  };

  const onChange = value => {
    if (!value) {
      form.resetFields(['sectionName']);
    }
  };

  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'sectionId'} label="Phần">
        <SectionAutoComplete
          allowClear
          section={section}
          onSelect={onSelect}
          onChange={onChange}
        />
      </Form.Item>

      <Form.Item name="sectionName" style={{ display: 'none' }}>
        <Input readOnly />
      </Form.Item>
    </>
  );
};

export default SectionAutoCompleteFilter;
