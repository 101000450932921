import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useSection } from 'stores/useSection';
import shallow from 'zustand/shallow';

import UpdateCreateModal from 'containers/UpdateCreateModal';
import SectionFormContent from './SectionFormContent';

const SectionForm = () => {
  const {
    sectionToEdit,
    setSectionToEdit,
    createSection,
    drawerVisible,
    updateSection,
    setDrawerVisible
  } = useSection(
    useCallback(
      ({
        sectionToEdit,
        setSectionToEdit,
        createSection,
        drawerVisible,
        updateSection,
        setDrawerVisible
      }) => ({
        sectionToEdit,
        setSectionToEdit,
        createSection,
        drawerVisible,
        updateSection,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();

      await updateSection(params, sectionToEdit.id);
      setSectionToEdit(null);
      setDrawerVisible(false);
    } catch (e) {}
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createSection(params);
      setDrawerVisible(false);
      form.resetFields();
    } catch (e) {}
  };

  return (
    <UpdateCreateModal
      title={sectionToEdit ? 'Chỉnh sửa môn học' : 'Tạo môn học'}
      onOk={sectionToEdit ? onSave : onCreate}
      onClose={() => {
        setDrawerVisible(false);
        setSectionToEdit(null);
      }}
      visible={drawerVisible}
      destroyOnClose
    >
      <SectionFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default SectionForm;
