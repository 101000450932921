import { Modal, Space, Button } from 'antd';
import React from 'react';

import HTMLModalContent from './HTMLModalContent';

const HTMLModal = ({ visible, closeModal, pushContent }) => {
  const onOk = () => {
    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      title={'Thêm HTML template'}
      cancelText="Hủy"
      width="90%"
      className="html-template-modal"
      destroyOnClose
      centered
      footer={
        <Space>
          <Button onClick={onCancel}>Đóng</Button>
        </Space>
      }
    >
      <HTMLModalContent pushContent={pushContent} closeModal={closeModal} />
    </Modal>
  );
};

export default HTMLModal;
