import React, { useCallback, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useSubject } from 'stores/useSubject';
import shallow from 'zustand/shallow';
import SlugFormItem from 'components/SlugFormItem';
import ClassSelect from 'containers/ClassSelect';

import SubjectImgSelect from './SubjectImgSelect';

const SubjectFormContent = ({ form }) => {
  const { subjectToEdit } = useSubject(
    useCallback(
      ({ subjectToEdit }) => ({
        subjectToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (subjectToEdit) {
      const classIds = subjectToEdit?.class?.map(({ id }) => id + '') || [];

      form.setFieldsValue({
        name: subjectToEdit.name,
        slug: subjectToEdit.slug,
        description: subjectToEdit.description,
        seoTitle: subjectToEdit.seoTitle,
        seoDescription: subjectToEdit.seoDescription,
        seoKeyword: subjectToEdit.seoKeyword,
        classIds,
        image: subjectToEdit?.image
      });
    } else {
      form.resetFields();
    }
  }, [subjectToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Tên môn học"
        rules={[{ required: true, message: 'Nhập tên môn học!' }]}
      >
        <Input placeholder="Nhập tên môn học" />
      </Form.Item>

      <SlugFormItem form={form} target="name" />

      <Form.Item
        name="classIds"
        label="Lớp"
        // rules={[{ required: true, message: 'Chọn lớp!' }]}
      >
        <ClassSelect mode="multiple" />
      </Form.Item>

      <Form.Item name="description" label="Mô tả">
        <Input.TextArea placeholder="Nhập mô tả" />
      </Form.Item>

      <Form.Item name="seoTitle" label="Tiêu đề SEO">
        <Input placeholder="Nhập tiêu đề SEO" />
      </Form.Item>

      <Form.Item name="seoDescription" label="Mô tả SEO">
        <Input placeholder="Nhập mô tả SEO" />
      </Form.Item>

      <Form.Item name="seoKeyword" label="Từ khoá SEO">
        <Input placeholder="Nhập từ khoá SEO" />
      </Form.Item>

      <Form.Item name="image" label="Ảnh đại diện môn học">
        <SubjectImgSelect />
      </Form.Item>
    </Form>
  );
};

export default SubjectFormContent;
