import { Typography } from 'antd';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { generateFilterParamsCount } from 'utils/other';
import KeywordFilter from './KeywordFilter';
import KeywordForm from './KeywordForm';
import KeywordList from './KeywordList';

const Keyword = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div className="spacing-container">
      <Typography.Title>Từ khóa</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={KeywordList}
        secondary={KeywordFilter}
        filterDrawerTitle="Lọc tag"
      />

      <KeywordForm />
    </div>
  );
};

export default Keyword;
