import { Card, Col, Divider, Row, Space, Tag } from 'antd';
import React from 'react';

const ListCard = ({ left, right, children, tag, ...props }) => {
  return (
    <Card
      title={
        <Row align="middle" justify="space-between">
          <Col>
            <Space>
              {left}

              <Divider type="vertical" />

              <Tag color="processing">
                {tag || 'Ấn shift + scroll để scroll ngang  👨‍🚒'}
              </Tag>
            </Space>
          </Col>

          <Col>{right}</Col>
        </Row>
      }
      {...props}
    >
      {children}
    </Card>
  );
};

export default ListCard;
