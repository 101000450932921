import React, { useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Space, Button, Tag, Avatar } from 'antd';

import { useSubject } from 'stores/useSubject';
import shallow from 'zustand/shallow';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import StatusButton from 'components/StatusButton';
import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';

const EditClassBtn = record => {
  const { deleteSubject } = useSubject(
    useCallback(
      ({ deleteSubject, updateSubjectStatus }) => ({
        deleteSubject,
        updateSubjectStatus
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deleteSubject(record.id)}
          title={'Xoá ' + record?.name + ' ?'}
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>
    </Space>
  );
};

const Status = ({ record }) => {
  const { updateSubjectStatus } = useSubject(
    useCallback(
      ({ updateSubjectStatus }) => ({
        updateSubjectStatus
      }),
      []
    ),
    shallow
  );

  const onClick = key => {
    return updateSubjectStatus([
      {
        ...record,
        status: key
      }
    ]);
  };

  return <StatusButton status={record?.status} onClick={onClick} />;
};

const Classes = classes => {
  if (!classes) return <></>;

  return classes.map(({ id, name }) => <Tag key={id}>{name}</Tag>);
};

const Name = (name, record) => {
  const { setSubjectToEdit, setDrawerVisible } = useSubject(
    useCallback(
      ({ setSubjectToEdit, updateSubjectStatus, setDrawerVisible }) => ({
        setSubjectToEdit,
        updateSubjectStatus,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setSubjectToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Ảnh đại diện',
    dataIndex: 'image',
    key: 'image',
    render: (image, { name }) => (
      <Avatar
        style={{ backgroundColor: 'transparent' }}
        size={64}
        shape="square"
        icon={
          <svg style={{ width: '32px', height: '32px' }}>
            <image
              style={{ width: '32px', height: '32px' }}
              xlinkHref={window.origin + '/subjects/' + image + '.svg'}
            />
          </svg>
        }
      />
    ),
    // render: (image, { name }) => <UserAvatar fullName={name} avatar={image} />,
    fixed: 'left',
    align: 'center',
    width: '110px'
  },
  {
    title: 'Tên môn',
    dataIndex: 'name',
    render: Name,
    align: 'left',
    width: '200px',
    fixed: 'left'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    // fixed: 'left',
    render: (_, record) => <Status record={record} />,
    width: '220px'
  },
  {
    title: 'Lớp',
    dataIndex: 'class',
    key: 'class',
    render: Classes,
    width: '300px'
  },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '120px',
    render: EditClassBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
