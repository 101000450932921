import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';

import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import StatusButton from 'components/StatusButton';

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import shallow from 'zustand/shallow';
import RelatedUser from 'components/RelatedUser';
import TimeLine from 'components/TimeLine';
import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import { useExercise } from 'stores/useExercise';

const EditSyllabusBtn = record => {
  const { deleteExercise } = useExercise(
    useCallback(
      ({ deleteExercise }) => ({
        deleteExercise
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deleteExercise(record.id)}
          title={'Xoá ' + record?.title + ' ?'}
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>
    </Space>
  );
};

const Status = ({ record }) => {
  const { updateExerciseStatus } = useExercise(
    useCallback(
      ({ updateExerciseStatus }) => ({
        updateExerciseStatus
      }),
      []
    ),
    shallow
  );

  const onClick = key => {
    return updateExerciseStatus([
      {
        ...record,
        status: key
      }
    ]);
  };

  return <StatusButton status={record?.status} onClick={onClick} />;
};

export const columns = [
  {
    title: 'Tên bài',
    dataIndex: 'title',
    render: (title, { id }) => (
      <Link to={'/chi-tiet-bai-tap/' + id}>
        <Typography.Text strong>
          #{title} <EditOutlined />
        </Typography.Text>
      </Link>
    ),
    align: 'left',
    width: '300px',
    fixed: 'left'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => <Status record={record} />,
    width: '220px'
  },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    align: 'center',
    render: EditSyllabusBtn
  }
];
