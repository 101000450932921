import { Col, Row, Typography, Form, Spin, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import shallow from 'zustand/shallow';

import { useArticleDetail } from 'stores/useArticleDetail';

import ArticleDetailLeft from './ArticleDetailLeft';
import ArticleDetailRight from './ArticleDetailRight';
import { useParams } from 'react-router';
import moment from 'moment';

import { setupSignalRConnection } from './signalr';
import { useCurrentUser } from 'stores/useCurrentUser';
const ArticleDetailContext = React.createContext(null);

const connectionHub = process.env.REACT_APP_HUB_API;

const ArticleDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const isEditingArticle = useMemo(() => !!id, [id]);
  const connectionRef = useRef(null);

  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  const { article, getArticle, isArticleDetailLoading, isCreatingArticle } =
    useArticleDetail(
      useCallback(
        ({
          article,
          getArticle,
          isArticleDetailLoading,
          isCreatingArticle
        }) => ({
          article,
          getArticle,
          isArticleDetailLoading,
          isCreatingArticle
        }),
        []
      ),
      shallow
    );

  useEffect(() => {
    if (id) {
      getArticle(id);
    }
  }, [id, getArticle]);

  const invokeMethod = useCallback(
    async connection => {
      try {
        // if (article) {
        await connection.invoke('JoinRoom', {
          userId: currentUser?.id + '',
          articleId: id + ''
        });
        // }

        return;
      } catch (err) {
        console.error(err);
      }
    },
    [currentUser?.id, id]
  );

  useEffect(() => {
    const setup = async () => {
      const connection = await setupSignalRConnection(connectionHub, [
        {
          code: 'ReceiveMessage',
          callback: userId => {
            if (currentUser?.id + '' !== userId) {
              notification.warn({
                message: 'Người dùng ' + userId + ' vừa vào bài viết.'
              });
            }
          }
        },
        {
          code: 'CountMember',
          callback: (count, a) => {
            if (count > 1) {
              notification.warn({
                message: 'Có người đang chỉnh sửa bài viết.'
              });
            }
          }
        }
      ]);

      connectionRef.current = connection;

      await invokeMethod(connection);

      return connection;
    };

    setup();
  }, [currentUser?.id, invokeMethod]);

  useEffect(() => {
    return () => {
      connectionRef.current && connectionRef.current?.stop();
    };
  }, []);

  useEffect(() => {
    if (id && article) {
      form.setFieldsValue({
        title: article?.title,
        description: article?.description,
        image: article?.image,
        isPrivate: article?.isPrivate,
        bookTypeId: article?.bookTypeId,
        moduleId: article?.moduleId,
        sectionId: article?.module?.sectionId,
        syllabusId: article?.syllabusId,
        seo301: article?.seo301,
        seoCanonical: article?.seoCanonical,
        seoDescription: article?.seoDescription,
        seoKeyword: article?.seoKeyword,
        seoTitle: article?.seoTitle,
        slug: article?.slug,
        publishedDate: moment(article?.publishedDate),
        pageNo: article?.pageNo,
        articleNo: article?.articleNo,
        code: article?.code,
        source: article?.source
      });
    }
  }, [id, article, form]);

  return (
    <>
      <Typography.Title>Chi tiết bài học</Typography.Title>

      <Spin spinning={isCreatingArticle || isArticleDetailLoading}>
        <Form form={form} layout="vertical">
          <ArticleDetailContext.Provider value={{ form, isEditingArticle }}>
            <Row gutter={16}>
              <Col span={20}>
                <ArticleDetailLeft />
              </Col>

              <Col span={4}>
                <ArticleDetailRight />
              </Col>
            </Row>
          </ArticleDetailContext.Provider>
        </Form>
      </Spin>
    </>
  );
};

export { ArticleDetailContext };
export default ArticleDetail;
