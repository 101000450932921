import React from 'react';
import { Select } from 'antd';
import { FEEDBACK_DICTIONARY } from 'appConstants';

const FeedbackStatusSelect = props => {
  return (
    <Select
      options={Object.entries(FEEDBACK_DICTIONARY).map(arr => ({
        value: arr[0],
        label: arr[1]
      }))}
      style={{ width: 200, fontWeight: 'normal' }}
      placeholder="Chọn trạng thái"
      allowClear
      {...props}
    />
  );
};

export default FeedbackStatusSelect;
