import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useModule } from 'stores/useModule';
import shallow from 'zustand/shallow';
import { setError } from 'utils/other';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import ModuleFormContent from './ModuleFormContent';

const ModuleForm = () => {
  const {
    moduleToEdit,
    setModuleToEdit,
    createModule,
    updateModule,
    setDrawerVisible,
    drawerVisible
  } = useModule(
    useCallback(
      ({
        moduleToEdit,
        setModuleToEdit,
        createModule,
        updateModule,
        setDrawerVisible,
        drawerVisible
      }) => ({
        moduleToEdit,
        setModuleToEdit,
        createModule,
        updateModule,
        setDrawerVisible,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();

      await updateModule(
        { ...params, sectionId: params?.sectionId || null },
        moduleToEdit.id
      );
      setModuleToEdit(null);
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createModule(params);
      form.resetFields();
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  return (
    <UpdateCreateModal
      title={moduleToEdit ? 'Chỉnh sửa chương' : 'Tạo chương'}
      onOk={moduleToEdit ? onSave : onCreate}
      onClose={() => {
        setDrawerVisible(false);
        setModuleToEdit(null);
      }}
      visible={drawerVisible}
      destroyOnClose
    >
      <ModuleFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default ModuleForm;
