import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useTag } from 'stores/useTag';
import shallow from 'zustand/shallow';

import { setError } from 'utils/other';
import TagFormContent from './TagFormContent';
import UpdateCreateModal from 'containers/UpdateCreateModal';

const TagForm = () => {
  const {
    tagToEdit,
    setTagToEdit,
    createTag,
    updateTag,
    setDrawerVisible,
    drawerVisible
  } = useTag(
    useCallback(
      ({
        tagToEdit,
        setTagToEdit,
        createTag,
        updateTag,
        setDrawerVisible,
        drawerVisible
      }) => ({
        tagToEdit,
        setTagToEdit,
        createTag,
        updateTag,
        setDrawerVisible,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      await updateTag(params, tagToEdit.id);
      setTagToEdit(null);
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      await createTag(params);
      form.resetFields();
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  return (
    <UpdateCreateModal
      title={tagToEdit ? 'Chỉnh sửa tag' : 'Tạo tag'}
      onOk={tagToEdit ? onSave : onCreate}
      onClose={() => {
        setDrawerVisible(false);
        setTagToEdit(null);
      }}
      visible={drawerVisible}
    >
      <TagFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default TagForm;
