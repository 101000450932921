import { Form, Input, Button, Collapse, Col, Row } from 'antd';
import { BAI_TAP } from 'appConstants';

import SlugFormItem from 'components/SlugFormItem';
import ArticleAutoComplete from 'containers/ArticleAutoComplete';

import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useCurrentUser } from 'stores/useCurrentUser';
import { useExerciseDetail } from 'stores/useExerciseDetail';
import shallow from 'zustand/shallow';
import ExerciseMainContent from './ExerciseMainContent';

const ExerciseMainDetail = ({ isEditingExercise }) => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const history = useHistory();

  const { createExercise, getExercise, updateExercise, reset, exercise } =
    useExerciseDetail(
      useCallback(
        ({ createExercise, getExercise, updateExercise, reset, exercise }) => ({
          createExercise,
          getExercise,
          updateExercise,
          reset,
          exercise
        }),
        []
      ),
      shallow
    );

  const { bookTypes } = useCurrentUser(
    useCallback(
      ({ bookTypes }) => ({
        bookTypes
      }),
      []
    ),
    shallow
  );

  const onClick = async () => {
    try {
      const params = await form.validateFields([
        'title',
        'slug',
        'articleId',
        'seoTitle',
        'seoDescription',
        'seoKeyword'
      ]);

      if (isEditingExercise) {
        await updateExercise(params, id);
      } else {
        const res = await createExercise(params);

        history.push('/chi-tiet-bai-tap/' + res?.id);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  useEffect(() => {
    if (!!id) {
      getExercise(id);
    }

    return () => {
      reset();
    };
  }, [id, getExercise, reset]);

  useEffect(() => {
    if (id && exercise) {
      form.setFieldsValue({
        title: exercise?.title,
        slug: exercise?.slug,
        seoTitle: exercise?.seoTitle,
        seoDescription: exercise?.seoDescription,
        seoKeyword: exercise?.seoKeyword
      });
    }
  }, [id, exercise, form]);

  const bookTypeCondition = useMemo(
    () => bookTypes.find(({ slug }) => slug === BAI_TAP)?.id,
    [bookTypes]
  );

  return (
    <>
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel header="Thông tin chính" key="1">
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Tiêu đề"
                  rules={[{ required: true, message: 'Nhập tiêu đề!' }]}
                >
                  <Input placeholder="Nhập title" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <SlugFormItem form={form} target="title" />
              </Col>
            </Row>

            <Form.Item name="seoTitle" label="Tiêu đề SEO">
              <Input placeholder="Nhập tiêu đề SEO" />
            </Form.Item>

            <Form.Item name="seoDescription" label="Mô tả SEO">
              <Input placeholder="Nhập mô tả SEO" />
            </Form.Item>

            <Form.Item name="seoKeyword" label="Từ khoá SEO">
              <Input placeholder="Nhập từ khoá SEO" />
            </Form.Item>

            {isEditingExercise ? (
              <></>
            ) : (
              <Form.Item
                name="articleId"
                label="Bài viết"
                style={{ marginBottom: 8 }}
              >
                <ArticleAutoComplete bookType={bookTypeCondition} />
              </Form.Item>
            )}

            <Button
              block
              type="primary"
              onClick={onClick}
              style={{ marginTop: 12 }}
            >
              {isEditingExercise ? 'Lưu' : 'Tạo'}
            </Button>
          </Form>
        </Collapse.Panel>
      </Collapse>

      {isEditingExercise && (
        <ExerciseMainContent isEditingExercise={isEditingExercise} id={id} />
      )}
    </>
  );
};

export default ExerciseMainDetail;
