import {
  Collapse,
  Typography,
  Select,
  Row,
  Button,
  Divider,
  Input,
  Empty
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DEBOUNCE_VALUE } from 'appConstants';

import { useServices } from 'hooks/useServices';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useArticleDetail } from 'stores/useArticleDetail';

import { debounce } from 'utils/lodash';
import shallow from 'zustand/shallow';

const ArticleDetailTags = () => {
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState([]);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [text, setText] = useState('');

  const inputRef = useRef(null);

  const { searchForTags, createTag } = useServices(
    useCallback(
      ({ searchForTags, createTag }) => ({
        searchForTags,
        createTag
      }),
      []
    ),
    shallow
  );

  const { article, attachTagsToArticle } = useArticleDetail(
    useCallback(
      ({ article, attachTagsToArticle }) => ({
        article,
        attachTagsToArticle
      }),
      []
    ),
    shallow
  );

  const onSelect = async value => {
    if (isInputFocus) return;

    setValues(prevValue => [...prevValue, value]);
  };

  const onSearch = async searchText => {
    if (!searchText) {
      return;
    }
    const results = await searchForTags(searchText);
    if (!results) {
      return;
    }
    return setOptions(() => {
      return results.map(result => ({
        value: result.id,
        label: result.name
      }));
    });
  };

  const onDeselect = valueToDeselect => {
    if (isInputFocus) return;

    setValues(prevValues =>
      prevValues.filter(value => valueToDeselect !== value)
    );
  };

  useEffect(() => {
    const tags = article?.tag || [];
    setValues(tags.map(({ id }) => id));
    setOptions(tags.map(({ id, name }) => ({ value: id, label: name })));
  }, [article?.tag]);

  const onSave = () => {
    attachTagsToArticle(article?.id, { tagIds: values });
  };

  const handleAddItem = async text => {
    const res = await createTag({ name: text });

    setOptions(options => [...options, { value: res.id, label: res.name }]);
    setValues(prevValue => [...prevValue, res.id]);
  };

  const onAddItem = async () => {
    if (!text) return;

    await handleAddItem(text);

    setText('');
  };

  const onInputKeyDown = ({ code }) => {
    if (code === 'Enter') {
      onAddItem();
    }
  };

  const onSelectKeyDown = async ({ code, target }) => {
    if (
      code === 'Enter' &&
      options.length === 0 &&
      target.id === 'select-target' &&
      !!target.value
    ) {
      await handleAddItem(target.value);
      target.value = '';
    }
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const NewMenu = menu => (
    <div>
      {menu}

      <Divider style={{ margin: '8px 0 4px 0' }} />

      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          padding: '4px 8px 8px 8px'
        }}
      >
        <Input
          value={text}
          onChange={e => setText(e.target.value)}
          style={{ flex: 'auto' }}
          ref={inputRef}
          onFocus={() => {
            setIsInputFocus(true);
          }}
          onBlur={() => {
            setIsInputFocus(false);
          }}
          onKeyDown={onInputKeyDown}
        />

        <Button type="link" onClick={onAddItem}>
          <PlusOutlined /> Add item
        </Button>
      </div>
    </div>
  );

  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel
        header={<Typography.Text strong>Tag</Typography.Text>}
        key="1"
      >
        <>
          <Select
            id="select-target"
            mode="multiple"
            style={{ width: '100%', marginBottom: '24px' }}
            placeholder="Tìm tag"
            options={options}
            filterOption={false}
            notFoundContent={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Empty
                  description="Không tìm thấy tag 😿. Nhấn Enter để tạo tag."
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </div>
            }
            showSearch
            onSearch={debouncedOnSearch}
            onSelect={onSelect}
            onDeselect={onDeselect}
            value={values}
            dropdownRender={NewMenu}
            onKeyDown={onSelectKeyDown}
          />

          <Row justify="end">
            <Button onClick={onSave} type="primary">
              Lưu
            </Button>
          </Row>
        </>
      </Collapse.Panel>
    </Collapse>
  );
};

export default ArticleDetailTags;
