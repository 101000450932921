import { Typography } from 'antd';
import React from 'react';
import RoleTab from './RoleTab/RoleTab';

const Role = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Vai trò</Typography.Title>
      <RoleTab />
    </div>
  );
};

export default Role;
