import React, { useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Space, Button } from 'antd';

import { useHTMLTemplate } from 'stores/useHTMLTemplate';
import shallow from 'zustand/shallow';

import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';

const EditHTMLTemplateBtn = record => {
  const { deleteHTMLTemplate } = useHTMLTemplate(
    useCallback(
      ({ deleteHTMLTemplate }) => ({
        deleteHTMLTemplate
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <DeleteConfirmPopup
        onDelete={() => deleteHTMLTemplate(record.id)}
        title={'Xoá ' + record?.name + ' ?'}
      >
        <Button type="default" size="small" icon={<DeleteOutlined />} />
      </DeleteConfirmPopup>
    </Space>
  );
};

const Name = (name, record) => {
  const { setHTMLTemplateToEdit, setDrawerVisible } = useHTMLTemplate(
    useCallback(
      ({ setHTMLTemplateToEdit, setDrawerVisible }) => ({
        setHTMLTemplateToEdit,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setHTMLTemplateToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Tên',
    dataIndex: 'title',
    render: Name,
    align: 'left',
    width: '200px',
    fixed: 'left'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '120px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '150px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: EditHTMLTemplateBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
