import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import Table from 'components/Table';
import SortSelect from 'components/SortSelect';

import { columns } from '../columns';
import {
  ORDER_BY_SELECT_VALUE,
  PAGE_SIZE,
  PERMISSION_ACTION
} from 'appConstants';
import { usePermission } from 'stores/usePermission';
import PermissionButton from 'containers/PermissionButton';
import ListCard from 'components/ListCard';

const PermissionList = () => {
  const {
    getPermissions,
    total,
    permissions,
    isPermissionsListLoading,
    setDrawerVisible
  } = usePermission(
    useCallback(
      ({
        getPermissions,
        total,
        permissions,
        isPermissionsListLoading,
        setDrawerVisible
      }) => ({
        getPermissions,
        total,
        permissions,
        isPermissionsListLoading,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const onPaginate = page => {
    history.push({
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getPermissions();
  }, [getPermissions, search]);

  return (
    <ListCard
      left={'Danh sách quyền hạn'}
      right={
        <Space>
          <PermissionButton keyAction={PERMISSION_ACTION.CREATE}>
            <Button
              onClick={() => setDrawerVisible(true)}
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </PermissionButton>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        columns={columns}
        dataSource={permissions}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: permissions?.length
        }}
        // rowSelection={{ rowSelection }}
        loading={isPermissionsListLoading}
      />
    </ListCard>
  );
};

export default PermissionList;
