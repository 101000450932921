import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';

import UpdateCreateModal from 'containers/UpdateCreateModal';
import UserFormContent from './UserFormContent';
import { setError } from 'utils/other';

const UserForm = () => {
  const {
    userToEdit,
    setUserToEdit,
    updateUser,
    createUser,
    setDrawerVisible,
    attachSyllabusesToUser,
    drawerVisible
  } = useUser(
    useCallback(
      ({
        userToEdit,
        setUserToEdit,
        updateUser,
        createUser,
        setDrawerVisible,
        attachSyllabusesToUser,
        drawerVisible
      }) => ({
        userToEdit,
        setUserToEdit,
        updateUser,
        createUser,
        setDrawerVisible,
        attachSyllabusesToUser,
        drawerVisible
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const { syllabusIds, ...params } = await form.validateFields();

      await updateUser(params, userToEdit.id);
      await attachSyllabusesToUser({ syllabusIds }, userToEdit.id);
      setUserToEdit(null);
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const { syllabusIds, ...params } = await form.validateFields();
      const res = await createUser(params);
      await attachSyllabusesToUser({ syllabusIds }, res.id);

      form.resetFields();
      setDrawerVisible(false);
    } catch (e) {
      setError(form, e);
    }
  };

  return (
    <UpdateCreateModal
      title={userToEdit ? 'Chỉnh sửa biên tập viên' : 'Tạo biên tập viên'}
      onOk={userToEdit ? onSave : onCreate}
      onClose={() => {
        form.resetFields();
        setDrawerVisible(false);
        setUserToEdit(null);
      }}
      visible={drawerVisible}
      destroyOnClose
    >
      <UserFormContent form={form} />
    </UpdateCreateModal>
  );
};

export default UserForm;
