import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import { useModule } from 'stores/useModule';
import { useSection } from 'stores/useSection';
import shallow from 'zustand/shallow';
import SyllabusAutoComplete from 'containers/SyllabusAutoComplete';

import SlugFormItem from 'components/SlugFormItem';

const ModuleFormContent = ({ form }) => {
  const { moduleToEdit } = useModule(
    useCallback(
      ({ moduleToEdit }) => ({
        moduleToEdit
      }),
      []
    ),
    shallow
  );

  const { fetchSections } = useSection(
    useCallback(
      ({ fetchSections }) => ({
        fetchSections
      }),
      []
    ),
    shallow
  );

  const [sectionOptions, setSectionOptions] = useState([]);

  const onSelectSyllabus = async value => {
    form.resetFields(['sectionId']);

    const params = {
      SyllabusId: value,
      Page: 0,
      PageSize: 1000
    };

    const { data } = await fetchSections(params);
    setSectionOptions(data.map(({ id, name }) => ({ value: id, label: name })));
  };

  const handeInit = useCallback(async () => {
    if (moduleToEdit) {
      const params = {
        SyllabusId: moduleToEdit.syllabusId,
        Page: 0,
        PageSize: 1000
      };

      const { data } = await fetchSections(params);

      setSectionOptions(
        data.map(({ id, name }) => ({ value: id, label: name }))
      );

      form.setFieldsValue({
        name: moduleToEdit.name,
        slug: moduleToEdit.slug,
        syllabusId: moduleToEdit.syllabusId,
        moduleNo: moduleToEdit.moduleNo,
        sectionId: moduleToEdit?.sectionId,
        seoTitle: moduleToEdit.seoTitle,
        seoDescription: moduleToEdit.seoDescription,
        seoKeyword: moduleToEdit.seoKeyword,
        description: moduleToEdit.description
      });
    } else {
      form.resetFields();
    }
  }, [moduleToEdit, fetchSections, form]);

  useEffect(() => {
    handeInit();
  }, [handeInit]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Tên chương"
        rules={[{ required: true, message: 'Nhập tên chương!' }]}
      >
        <Input placeholder="Nhập tên chương" />
      </Form.Item>

      <Form.Item name="description" label="Mô tả">
        <Input.TextArea placeholder="Nhập mô tả" />
      </Form.Item>

      <SlugFormItem form={form} target="name" />

      <Form.Item
        name="syllabusId"
        label="Giáo trình"
        rules={[{ required: true, message: 'Chọn giáo trình!' }]}
      >
        <SyllabusAutoComplete
          onSelect={onSelectSyllabus}
          syllabus={moduleToEdit?.syllabus}
        />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: sectionOptions.length !== 0,
            message: 'Chọn phần!'
          }
        ]}
        name="sectionId"
        label="Phần"
      >
        <Select
          options={sectionOptions}
          placeholder="Chọn phần"
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>

      <Form.Item
        name="moduleNo"
        label="Chương số"
        rules={[{ required: true, message: 'Nhập chương số!' }]}
      >
        <InputNumber
          min={1}
          style={{ width: '100%' }}
          placeholder="Nhập chương số"
        />
      </Form.Item>

      <Form.Item name="seoTitle" label="Tiêu đề SEO">
        <Input placeholder="Nhập tiêu đề SEO" />
      </Form.Item>

      <Form.Item name="seoDescription" label="Mô tả SEO">
        <Input placeholder="Nhập mô tả SEO" />
      </Form.Item>

      <Form.Item name="seoKeyword" label="Từ khoá SEO">
        <Input placeholder="Nhập từ khoá SEO" />
      </Form.Item>
    </Form>
  );
};

export default ModuleFormContent;
