import { Col, Row } from 'antd';
import { STATUS_VALUE } from 'appConstants';
import React from 'react';
import ArticleCard from './components/ArticleCard';

const ArticleQuantity = () => {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <ArticleCard
          status={STATUS_VALUE.PUBLISHED}
          backgroundColor="#35858B"
        />
      </Col>
      <Col span={6}>
        <ArticleCard status={STATUS_VALUE.APPROVED} backgroundColor="#548CFF" />
      </Col>
      <Col span={6}>
        <ArticleCard status={STATUS_VALUE.DRAFT} backgroundColor="#FF5959" />
      </Col>
      <Col span={6}>
        <ArticleCard backgroundColor="#BAABDA" />
      </Col>
    </Row>
  );
};

export default ArticleQuantity;
