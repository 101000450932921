import { Button, Popover } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import FeedbackWidgetForm from './FeedbackWidgetForm';

const FeedbackWidget = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Popover
        title="Góp ý 🥑"
        trigger="click"
        visible={visible}
        placement="rightBottom"
        content={<FeedbackWidgetForm setVisible={setVisible} />}
      >
        <Button
          onClick={() => setVisible(a => !a)}
          type="primary"
          style={{ position: 'fixed', left: '20px', bottom: '50px' }}
          shape="circle"
          icon={<QuestionOutlined />}
          size="large"
        />
      </Popover>
    </>
  );
};

export default FeedbackWidget;
