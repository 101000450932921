import { notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import create from 'zustand';

export const useDashboard = create((set, get) => ({
  isDashboardLoading: false,
  articleStatus: [],
  feedbackStatus: [],
  users: [],
  articles: [],
  getDashboard: async () => {
    set({ isDashboardLoading: true });
    try {
      const { data } = await API.get('/dashboard');
      set({ isDashboardLoading: false });
      if (data) {
        const { articleStatus, feedbackStatus, users, articles } = data;
        set({
          articleStatus,
          feedbackStatus,
          users,
          articles
        });
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch (data) {
      set({ isDashboardLoading: false });

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  }
}));
