import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';

import GenderSelect from 'components/GenderSelect';

import RoleSelect from 'containers/RoleSelect';
import SyllabusAutoComplete from 'containers/SyllabusAutoComplete';
import { useSyllabus } from 'stores/useSyllabus';
import SlugFormItem from 'components/SlugFormItem';
import DatePicker from 'components/DatePicker';
import moment from 'moment';

const UserFormContent = ({ form }) => {
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const { userToEdit } = useUser(
    useCallback(
      ({ userToEdit }) => ({
        userToEdit
      }),
      []
    ),
    shallow
  );

  const { fetchSyllabuses } = useSyllabus(
    useCallback(
      ({ fetchSyllabuses }) => ({
        fetchSyllabuses
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (userToEdit) {
      const getSyllabusOptions = async UserId => {
        const { data } = await fetchSyllabuses({
          PageSize: 1000,
          Page: 0,
          UserId
        });

        setSyllabusOptions(data);

        form.setFieldsValue({
          name: userToEdit?.name,
          slug: userToEdit?.slug,
          phoneNumber: userToEdit?.phoneNumber,
          email: userToEdit?.email,
          gender: userToEdit?.gender,
          pseudonym: userToEdit?.pseudonym,
          roleId: userToEdit?.roleId + '',
          syllabusIds: data.map(({ id }) => id),
          description: userToEdit.description,
          dateOfBirth: moment(userToEdit.dateOfBirth)
        });
      };
      getSyllabusOptions(userToEdit.id);
    }
  }, [userToEdit, form, fetchSyllabuses]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Họ tên"
        rules={[{ required: true, message: 'Nhập họ tên!' }]}
      >
        <Input placeholder="Nhập họ tên" />
      </Form.Item>
      {userToEdit && <SlugFormItem form={form} target={'name'} />}
      {!userToEdit && (
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
        >
          <Input.Password placeholder="Nhập mật khẩu" />
        </Form.Item>
      )}
      <Form.Item
        name="pseudonym"
        label="Bút danh"
        rules={[{ required: true, message: 'Nhập bút danh!' }]}
      >
        <Input placeholder="Nhập bút danh" />
      </Form.Item>

      <Form.Item
        name="phoneNumber"
        label="Số điện thoại"
        rules={[{ required: true, message: 'Nhập số điện thoại!' }]}
      >
        <Input placeholder="Nhập số điện thoại" />
      </Form.Item>
      {!userToEdit && (
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Nhập email!' }]}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>
      )}
      <Form.Item
        name="gender"
        label="Giới tính"
        rules={[{ required: true, message: 'Chọn giới tính!' }]}
      >
        <GenderSelect />
      </Form.Item>

      <Form.Item
        name="dateOfBirth"
        label="Ngày sinh"
        rules={[{ required: true, message: 'Chọn ngày sinh!' }]}
      >
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="roleId"
        label="Vai trò"
        rules={[{ required: true, message: 'Chọn quyền hạn!' }]}
      >
        <RoleSelect />
      </Form.Item>

      <Form.Item name="syllabusIds" label="Giáo trình phụ trách">
        <SyllabusAutoComplete syllabus={syllabusOptions} mode="multiple" />
      </Form.Item>

      <Form.Item name="description" label="Mô tả">
        <Input.TextArea placeholder="Nhập mô tả" />
      </Form.Item>
    </Form>
  );
};

export default UserFormContent;
