import React, { useCallback } from 'react';

import { DeleteOutlined, SelectOutlined } from '@ant-design/icons';
import { Space, Button, Typography } from 'antd';

import shallow from 'zustand/shallow';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';
import { useFeedback } from 'stores/useFeedback';
import FeedbackStatusBtn from './components/FeedbackStatusBtn';

const EditFeedbackBtn = record => {
  const { deleteFeedback } = useFeedback(
    useCallback(
      ({ deleteFeedback }) => ({
        deleteFeedback
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deleteFeedback(record.id)}
          title={'Xoá ' + record?.title + ' ?'}
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>
    </Space>
  );
};

const Status = ({ record }) => {
  const { updateFeedbackStatus } = useFeedback(
    useCallback(
      ({ updateFeedbackStatus }) => ({
        updateFeedbackStatus
      }),
      []
    ),
    shallow
  );

  const onClick = key => {
    return updateFeedbackStatus([
      {
        ...record,
        status: key
      }
    ]);
  };

  return <FeedbackStatusBtn status={record?.status} onClick={onClick} />;
};

const Name = (name, record) => {
  const { setFeedbackToView, setDrawerVisible } = useFeedback(
    useCallback(
      ({ setFeedbackToView, setDrawerVisible }) => ({
        setFeedbackToView,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setFeedbackToView(record)}
        icon={<SelectOutlined />}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    render: Name,
    align: 'left',
    width: '200px',
    fixed: 'left'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    // fixed: 'left',
    render: (_, record) => <Status record={record} />,
    width: '240px'
  },
  {
    title: 'Nội dung',
    dataIndex: 'comment',
    key: 'comment',
    render: comment => <Typography.Text>{comment}</Typography.Text>,
    ellipsis: true,
    width: '300px'
  },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '120px',
    render: EditFeedbackBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
