import { Affix, Row, Typography, Form, Input, Col, Button } from 'antd';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import { CommonContainer } from 'components/StyledComponents';
import MasterDataSelect from 'containers/MasterDataSelect';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRole } from 'stores/useRole';
import shallow from 'zustand/shallow';

const RoleInfo = ({ form }) => {
  const {
    currentRole,
    updateRole,
    createRole,
    deleteRoles,
    roles,
    setCurrentRole
  } = useRole(
    useCallback(
      ({
        currentRole,
        updateRole,
        createRole,
        deleteRoles,
        roles,
        setCurrentRole
      }) => ({
        currentRole,
        updateRole,
        createRole,
        deleteRoles,
        roles,
        setCurrentRole
      }),
      []
    ),
    shallow
  );

  const isUpdateRole = useMemo(() => currentRole, [currentRole]);

  useEffect(() => {
    if (currentRole) {
      const { roleName, roleDescription, roleType } = currentRole;
      form.setFieldsValue({ roleName, roleDescription, roleType });
    }
    return () => {
      form.setFieldsValue({
        roleName: '',
        roleDescription: '',
        roleType: null
      });
    };
  }, [currentRole, form]);

  const onCancelNewRole = () => {
    setCurrentRole(roles[0]);
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const { roleName, roleDescription, roleType } = values;
      const params = { roleName, roleDescription, roleType };

      if (isUpdateRole) {
        await updateRole(params, currentRole.id);
      } else {
        createRole(params);
      }
    } catch (error) {}
  };

  const onDeleteRole = async () => {
    try {
      if (isUpdateRole) {
        await deleteRoles(currentRole?.id);
      }
    } catch (error) {}
  };

  return (
    <Affix>
      <div>
        <CommonContainer>
          <Row justify="center" gutter={8} align="middle">
            {/* <Role style={{ fontSize: 18 }} /> */}
            <Typography.Text strong>{currentRole?.name}</Typography.Text>
          </Row>
          <Form form={form} layout="vertical">
            <Form.Item
              label="Tên vai trò"
              name="roleName"
              rules={[{ required: true, message: 'Nhập tên vai trò!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="roleType"
              label="Loại user"
              rules={[{ required: true, message: 'Chọn loại user!' }]}
            >
              <MasterDataSelect
                placeholder="Chọn loại user"
                groupCode="role_type"
              />
            </Form.Item>
            <Form.Item label="Mô tả" name="roleDescription">
              <Input />
            </Form.Item>
            <Row gutter={8}>
              {!isUpdateRole && (
                <Col>
                  <Button onClick={onCancelNewRole}>Huỷ</Button>
                </Col>
              )}

              <Col>
                <Button type="primary" onClick={onSave}>
                  {!isUpdateRole ? 'Tạo' : 'Lưu'}
                </Button>
              </Col>
            </Row>
          </Form>
        </CommonContainer>
        <DeleteConfirmPopup
          title={
            <Typography.Text>
              Xoá vai trò{' '}
              <Typography.Text strong>{currentRole?.roleName}</Typography.Text>?
            </Typography.Text>
          }
          onDelete={onDeleteRole}
          disabled={!isUpdateRole}
        >
          <Button
            type="default"
            disabled={!isUpdateRole}
            block
            danger
            style={{ marginTop: 16 }}
          >
            Xoá vai trò
          </Button>
        </DeleteConfirmPopup>
      </div>
    </Affix>
  );
};

export default RoleInfo;
