import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import {
  DEFAULT_ERROR_MESSAGE,
  PAGE_SIZE,
  PAGE_SIZE_ALL
} from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const usePermission = create((set, get) => ({
  isPermissionsFormLoading: false,
  setIsPermissionsFormLoading: isPermissionsFormLoading =>
    set({ isPermissionsFormLoading }),
  isPermissionsListLoading: false,
  setIsPermissionsListLoading: isPermissionsListLoading =>
    set({ isPermissionsListLoading }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //* ******************** */
  //* NOTE: get permission */
  //* ******************** */
  permissionToEdit: null,
  setPermissionToEdit: permissionToEdit => set({ permissionToEdit }),
  isCreatingPermission: false,
  createPermission: async params => {
    get().setIsPermissionsFormLoading(true);

    try {
      const { data } = await API.post('/permission', {
        ...params,
        active: true
      });
      get().setIsPermissionsFormLoading(false);

      set({ isCreatingPermission: false });

      if (data) {
        get().getPermissions();

        notification.success({
          message: 'Tạo vai trò thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPermissionsFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************** */
  //* NOTE: get permissions  */
  //* ********************** */

  permissions: [],
  totalPage: 1,
  getPermissions: async (params, isGetAll) => {
    get().setIsPermissionsListLoading(true);

    try {
      const {
        data: { data, total }
      } = await API.get('/permission', {
        params: {
          PageSize: isGetAll ? PAGE_SIZE_ALL : PAGE_SIZE,
          ...params,
          ...getQueryParams()
        }
      });

      get().setIsPermissionsListLoading(false);

      if (data) {
        set({
          permissions: data.map(permission => ({
            ...permission,
            key: permission.id
          })),
          totalPage: Math.ceil(total / PAGE_SIZE),
          total
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPermissionsListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************ */
  //* NOTE: update permissions */
  //* ************************ */

  updatePermission: async (params, id) => {
    get().setIsPermissionsFormLoading(true);

    try {
      const { data } = await API.put(`/permission/${id}`, params);

      if (data) {
        get().getPermissions();

        get().setIsPermissionsFormLoading(false);

        notification.success({
          message: 'Cập nhật vai trò thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPermissionsFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************* */
  //* NOTE: delete permissions  */
  //* ************************* */

  deletePermissions: async params => {
    get().setIsPermissionsFormLoading(true);
    try {
      const { data } = await API.delete(`/permission/${params.id}`);

      if (data) {
        set(({ permissions }) => ({
          permissions: permissions.filter(
            permission => permission.id !== data.id
          )
        }));
        get().setIsPermissionsFormLoading(false);
        return notification.success({
          message: 'Xoá vai trò thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch (data) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
