import React, { useState } from 'react';

import OtpInput from 'react-otp-input';

const OTPInput = () => {
  const [otp, setOtp] = useState('');

  const handleChange = otp => setOtp(otp);

  return (
    <OtpInput
      value={otp}
      onChange={handleChange}
      numInputs={6}
      containerStyle="otp-input__container"
      inputStyle="otp-input__input ant-input"
    />
  );
};

export default OTPInput;
