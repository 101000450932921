import { Button, Space } from 'antd';
import { STATUS_ACTION_TIMEOUT, FEEDBACK_VALUE } from 'appConstants';
import Upgrade from 'components/Icons/Upgrade';

import React, { useCallback, useRef, useState } from 'react';
import FeedbackStatusTag from './FeedbackStatusTag';

const FeedbackStatusBtn = ({ status, onClick }) => {
  const [actionVisible, setActionVisible] = useState(false);
  const timerRef = useRef();

  const buttonStyle = useCallback(
    (downStatus = false) => ({
      style: {
        padding: '0 7px',
        height: 'auto'
      },
      icon: downStatus ? <Upgrade rotate={180} /> : <Upgrade />,
      type: 'primary',
      danger: downStatus
    }),
    []
  );
  const onMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setActionVisible(true);
    }, STATUS_ACTION_TIMEOUT);
  };

  const onMouseLeave = () => {
    setActionVisible(false);
    clearTimeout(timerRef.current);
  };

  const renderButtonAction = useCallback(() => {
    switch (status) {
      case FEEDBACK_VALUE.UNRESOLVED:
        return (
          <Button
            {...buttonStyle()}
            onClick={() => onClick(FEEDBACK_VALUE.WAITING_FEEDBACK)}
          >
            Chờ xác nhận
          </Button>
        );

      case FEEDBACK_VALUE.WAITING_FEEDBACK:
        return (
          <Space>
            <Button
              {...buttonStyle(true)}
              onClick={() => onClick(FEEDBACK_VALUE.UNRESOLVED)}
            >
              Bỏ chờ
            </Button>
            <Button
              {...buttonStyle()}
              onClick={() => onClick(FEEDBACK_VALUE.RESOLVED)}
            >
              Xác nhận
            </Button>
          </Space>
        );

      case FEEDBACK_VALUE.RESOLVED:
        return (
          <Button
            {...buttonStyle(true)}
            onClick={() => onClick(FEEDBACK_VALUE.WAITING_FEEDBACK)}
          >
            Bỏ xác nhận
          </Button>
        );

      default:
        break;
    }
  }, [status, buttonStyle, onClick]);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ width: 'fit-content' }}
    >
      {actionVisible ? (
        renderButtonAction()
      ) : (
        <FeedbackStatusTag status={status} style={{ cursor: 'pointer' }} />
      )}
    </div>
  );
};

export default FeedbackStatusBtn;
