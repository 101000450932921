import gql from 'graphql-tag';

export const QUERY_SYLLABUS = gql`
  query ($id: Int!) {
    syllabus(id: $id) {
      sections {
        id
        name
        sectionNo
        modules {
          id
          name
          slug
          moduleNo
          articles {
            id
            title
            slug
            articleNo
          }
        }
      }
      modules {
        id
        name
        slug
        moduleNo
        articles {
          id
          title
          slug
          articleNo
        }
      }
      id
      name
      slug
    }
  }
`;
