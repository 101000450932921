import create from 'zustand';
import API from 'api';

import { notification } from 'antd';

import {
  setAccessToken,
  setRefreshToken,
  isLoggedIn,
  removeAccessToken,
  removeRefreshToken
} from 'utils/authority';

import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import { useCurrentUser } from './useCurrentUser';
import axios from 'axios';

const useAuth = create(set => ({
  isLoggedIn: isLoggedIn(),
  isLoggingIn: false,
  loggingHistory: [],
  refreshAccessToken: async (token, refreshToken, logout = f => f) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_API + '/auth/refreshtoken',
        {
          token,
          refreshToken
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (data.token && data.refreshToken) {
        return { token: data.token, refreshToken: data.refreshToken };
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch (data) {
      logout();
      throw data;
    }
  },
  login: async (params, callback = f => f) => {
    set({ isLoggingIn: true });
    try {
      const { data } = await API.post('/auth/login', params);

      set({ isLoggingIn: false });

      if (data.token && data.refreshToken) {
        setAccessToken(data.token);
        setRefreshToken(data.refreshToken);

        notification.success({ message: 'Đăng nhập thành công!' });

        set({ isLoggedIn: true });
        callback();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoggingIn: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  changePassword: async (params, callback = f => f) => {
    try {
      const { data } = await API.put('/auth/changepassword', params);

      if (data) {
        notification.success({ message: 'Đổi mật khẩu thành công!' });
        callback();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getLoginHistory: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/auth/login-history');
      set({ isLoggingIn: false });

      if (data) {
        set({ loggingHistory: data });
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  logout: () => {
    set({ isLoggedIn: false });
    useCurrentUser.getState().resetCurrentUser();
    removeAccessToken();
    removeRefreshToken();
    window.location.href = window.location.origin + '/dang-nhap';
  }
}));

export default useAuth;
