export const DEFAULT_ERROR_MESSAGE = 'Có gì đó nó sai!';

export const PAGE_SIZE = 20;

export const PAGE_SIZE_ALL = 10000;

export const ORDER_BY_SELECT_VALUE = {
  LATEST: 'Latest',
  EARLIEST: 'Earliest',
  RECENTLY_CHANGE: 'RecentlyChange'
};

export const ORDER_BY_DICTIONARY = {
  [ORDER_BY_SELECT_VALUE.LATEST]: 'Mới nhất',
  [ORDER_BY_SELECT_VALUE.EARLIEST]: 'Cũ nhất',
  [ORDER_BY_SELECT_VALUE.RECENTLY_CHANGE]: 'Vừa thay đổi'
};

export const DATE_FORMAT = 'DD/MM/YYYY';

export const BE_FILTER_DATE_FORMAT = 'YYYY-MM-DD';

export const DEBOUNCE_VALUE = 300;

//* *************** */
//* NOTE: HARD CODE */
//* *************** */

export const ROLE_CHECK = {
  ROLE: 'ROLE',
  SONG: 'SONG',
  PARTNER: 'PARTNER',
  USER: 'USER'
};

export const EDITABLE_CELL_TYPE = {
  CHECK_BOX: 'CHECK_BOX',
  TEXT: 'TEXT',
  SELECT: 'SELECT'
};

export const GENDER_DICTIONARY = {
  male: 'Nam',
  female: 'Nữ'
};

export const APPROVE_STATUS = {
  waiting: 'Đợi duyệt',
  approved: 'Đã duyệt'
};

export const SINGER_RANK = [
  {
    label: 'A',
    value: 1
  },
  {
    label: 'B',
    value: 2
  },
  {
    label: 'C',
    value: 3
  },
  {
    label: 'Không xếp hạng',
    value: 0
  }
];

export const IMG_FILE_TYPES = ['.bmp', '.jpg', '.jpeg', '.gif', '.png'];
export const AUDIO_FILE_TYPES = ['.mp3', '.wav'];
export const VIDEO_FILE_TYPES = ['.mp4', '.mov', '.wmv'];

export const STATUS_VALUE = {
  DRAFT: 'draft',
  APPROVED: 'approved',
  PUBLISHED: 'published'
};

export const STATUS_DICTIONARY = {
  [STATUS_VALUE.DRAFT]: 'Nháp',
  [STATUS_VALUE.APPROVED]: 'Đã duyệt',
  [STATUS_VALUE.PUBLISHED]: 'Đã phát hành'
};

export const FILTER_PARAMS_PREFIX = 'filter__';
export const FILTER_PARAMS_PREFIX_DATE = 'filter_date__';

export const TABLE_PROPS = {
  scroll: {
    x: 1500
  }
};

export const PERMISSION_ACTION = {
  READ: 'isRead',
  CREATE: 'isCreate',
  UPDATE: 'isUpdate',
  DELETE: 'isDelete'
};

export const PERMISSION_ACTION_LABEL = {
  [PERMISSION_ACTION.READ]: 'đọc',
  [PERMISSION_ACTION.CREATE]: 'thêm mới',
  [PERMISSION_ACTION.UPDATE]: 'cập nhật',
  [PERMISSION_ACTION.DELETE]: 'xoá'
};

//don vi tinh la mb nha cac ban
export const IMG_UPLOAD_SIZE_LIMIT = 2;

export const STATUS_ACTION_TIMEOUT = 200;

export const FEEDBACK_VALUE = {
  UNRESOLVED: 'unresolved',
  WAITING_FEEDBACK: 'waiting_feedback',
  RESOLVED: 'resolved'
};

export const FEEDBACK_DICTIONARY = {
  [FEEDBACK_VALUE.UNRESOLVED]: 'Chưa giải quyết',
  [FEEDBACK_VALUE.WAITING_FEEDBACK]: 'Chờ xác nhận',
  [FEEDBACK_VALUE.RESOLVED]: 'Đã giải quyết'
};

export const AUTO_SAVE_INTERVAL = 30000;

export const LY_THUYET = 'ly-thuyet';
export const BAI_TAP = 'bai-tap';
