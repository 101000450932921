import { Input, Pagination, Spin } from 'antd';

import React, { useCallback, useEffect, useState } from 'react';
import { useHTMLTemplate } from 'stores/useHTMLTemplate';
import shallow from 'zustand/shallow';
import HTMLItem from './HTMLItem';

const HTMLModalContent = ({ closeModal, pushContent }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');

  const { fetchHTMLTemplates } = useHTMLTemplate(
    useCallback(
      ({ fetchHTMLTemplates }) => ({
        fetchHTMLTemplates
      }),
      []
    ),
    shallow
  );

  const getHTMLTemplates = useCallback(async () => {
    setLoading(true);
    try {
      const { data, total } = await fetchHTMLTemplates({
        page: page - 1,
        name: filter
      });

      setTotal(total);
      setLoading(false);
      setData(data);
    } catch (e) {
      setLoading(false);
    }
  }, [filter, fetchHTMLTemplates, page]);

  useEffect(() => {
    getHTMLTemplates();
  }, [getHTMLTemplates]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 80,
          position: 'absolute',
          zIndex: 10,

          top: 55,
          left: 24,
          right: 24,
          backgroundColor: 'white'
        }}
      >
        <Pagination
          total={total}
          current={page}
          onChange={page => setPage(page)}
        />

        <Input.Search
          onSearch={value => {
            setFilter(value);
            setPage(1);
          }}
          placeholder="Tìm kiếm theo tên nha  🧙‍♀️"
          style={{ width: 300 }}
        />
      </div>

      <div
        style={{
          height: 80
        }}
      ></div>

      <Spin spinning={loading}>
        {data.map(val => (
          <HTMLItem
            key={val.id}
            {...val}
            closeModal={closeModal}
            pushContent={pushContent}
          />
        ))}
      </Spin>
    </>
  );
};

export default HTMLModalContent;
