import React, { useCallback, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useClassSubject } from 'stores/useClassSubject';
import shallow from 'zustand/shallow';

const ClassSubjectFormContent = ({ form }) => {
  const { classSubjectToEdit } = useClassSubject(
    useCallback(
      ({ classSubjectToEdit }) => ({
        classSubjectToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (classSubjectToEdit) {
      form.setFieldsValue({
        seoTitle: classSubjectToEdit.seoTitle,
        seoDescription: classSubjectToEdit.seoDescription,
        seoKeyword: classSubjectToEdit.seoKeyword,
        description: classSubjectToEdit.description
      });
    } else {
      form.resetFields();
    }
  }, [classSubjectToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item name="description" label="Mô tả">
        <Input placeholder="Nhập mô tả" />
      </Form.Item>

      <Form.Item name="seoTitle" label="Tiêu đề SEO">
        <Input placeholder="Nhập tiêu đề SEO" />
      </Form.Item>

      <Form.Item name="seoDescription" label="Mô tả SEO">
        <Input placeholder="Nhập mô tả SEO" />
      </Form.Item>

      <Form.Item name="seoKeyword" label="Từ khoá SEO">
        <Input placeholder="Nhập từ khoá SEO" />
      </Form.Item>
    </Form>
  );
};

export default ClassSubjectFormContent;
