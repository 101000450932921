import { Spin, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useExerciseDetail } from 'stores/useExerciseDetail';
import shallow from 'zustand/shallow';
import ExerciseDetailArticle from './ExerciseDetailArticle';
import ExerciseMainDetail from './ExerciseMainDetail';

const ExerciseDetail = () => {
  const { id } = useParams();

  const isEditingExercise = useMemo(() => !!id, [id]);

  const { isExerciseDetailLoading, isCreatingExercise } = useExerciseDetail(
    useCallback(
      ({ isExerciseDetailLoading, isCreatingExercise }) => ({
        isExerciseDetailLoading,
        isCreatingExercise
      }),
      []
    ),
    shallow
  );

  return (
    <Spin spinning={isExerciseDetailLoading || isCreatingExercise}>
      <div className="spacing-container">
        <Typography.Title>
          {isEditingExercise ? 'Chi tiết bài tập' : 'Tạo bài tập'}
        </Typography.Title>

        <ExerciseMainDetail isEditingExercise={isEditingExercise} />

        {isEditingExercise && <ExerciseDetailArticle exerciseId={id} />}
      </div>
    </Spin>
  );
};

export default ExerciseDetail;
