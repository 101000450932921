import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSyllabusDetail } from 'stores/useSyllabusDetail';
import shallow from 'zustand/shallow';
import SyllabusAutoComplete from 'containers/SyllabusAutoComplete';

const SyllabusSelect = () => {
  const { syllabus } = useSyllabusDetail(
    useCallback(
      ({ syllabus }) => ({
        syllabus
      }),
      []
    ),
    shallow
  );

  const history = useHistory();

  const onSelect = value => {
    history.push('/chi-tiet-giao-trinh/' + value);
  };

  return (
    <SyllabusAutoComplete
      syllabus={syllabus}
      value={syllabus?.id}
      onSelect={onSelect}
    />
  );
};

export default SyllabusSelect;
