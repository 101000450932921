import React, { useCallback, useEffect, useMemo } from 'react';
import { Space, Table, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import { columns } from '../columns';
import { useModule } from 'stores/useModule';

import {
  ORDER_BY_SELECT_VALUE,
  PAGE_SIZE,
  PERMISSION_ACTION,
  TABLE_PROPS
} from 'appConstants';

import SortSelect from 'components/SortSelect';
import StatusSelectForList from 'components/StatusSelectForList';
import PermissionButton from 'containers/PermissionButton';
import DeleteListPopup from 'components/DeleteListPopup';
import ListCard from 'components/ListCard';

const ModuleList = () => {
  const {
    getModules,
    modules,
    isModuleListLoading,
    total,
    selectedRows,
    deleteModules,
    setSelectedRows,
    updateModuleStatus,
    setDrawerVisible
  } = useModule(
    useCallback(
      ({
        getModules,
        modules,
        isModuleListLoading,
        total,
        selectedRows,
        deleteModules,
        setSelectedRows,
        updateModuleStatus,
        setDrawerVisible
      }) => ({
        getModules,
        modules,
        isModuleListLoading,
        total,
        selectedRows,
        deleteModules,
        setSelectedRows,
        updateModuleStatus,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRows.map(obj => obj.id),
      onChange: (_, selectedRows) => {
        return setSelectedRows(selectedRows);
      }
    }),
    [setSelectedRows, selectedRows]
  );

  const onPaginate = page => {
    history.push({
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getModules();
  }, [getModules, search]);

  return (
    <ListCard
      left="Danh sách chương"
      right={
        <Space>
          <DeleteListPopup
            selectedRows={selectedRows}
            deleteService={deleteModules}
            setSelectedRows={setSelectedRows}
            additionalContidion={selectedRows.every(
              item => item?.articleStatus.total === 0
            )}
          />

          <StatusSelectForList
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            updateService={updateModuleStatus}
          />

          <PermissionButton keyAction={PERMISSION_ACTION.CREATE}>
            <Button
              onClick={() => setDrawerVisible(true)}
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </PermissionButton>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={modules.map(obj => ({ ...obj, key: obj.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: modules?.length
        }}
        rowSelection={rowSelection}
        loading={isModuleListLoading}
      />
    </ListCard>
  );
};

export default ModuleList;
