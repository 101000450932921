import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';

import { useServices } from 'hooks/useServices';

const ArticleAutoComplete = ({ article, bookType, ...props }) => {
  const { searchForArticles } = useServices(
    useCallback(
      ({ searchForArticles }) => ({
        searchForArticles
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      article
        ? Array.isArray(article)
          ? article.map(({ id, title }) => ({ value: id, label: title }))
          : { value: article.id, label: article.title }
        : null,
    [article]
  );

  const anotherSearchForArticles = freeText => {
    return searchForArticles({ FreeText: freeText, BookTypeId: bookType });
  };

  return (
    <AutoComplete
      defaultOption={defaultOption}
      handleSearch={anotherSearchForArticles}
      labelKey="title"
      valueKey="id"
      placeholder="Tìm bài học"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ArticleAutoComplete;
