import { Button, Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { CloseOutlined, FullscreenOutlined } from '@ant-design/icons';
import cn from 'classnames';

const FeedbackImages = ({ images = [] }) => {
  const [visible, setVisible] = useState(false);

  const showModal = v => setVisible(v);

  const closeModal = () => setVisible(false);

  const FeedBackImg = ({ alt, src, onClick, ...props }) => {
    const [visible, setVisible] = useState(false);
    return (
      <div
        className="feedback-img-container"
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        onClick={onClick}
      >
        <div className={cn(['feedback-img-mask', { visible }])}>
          <FullscreenOutlined />
        </div>

        <img className="feedback-img" alt={alt} src={src} {...props} />
      </div>
    );
  };

  return (
    <>
      <div className="ant-form-item-label">Hình ảnh đính kèm</div>

      <Row style={{ height: 200, cursor: 'pointer' }} gutter={16}>
        {images.map((img, i) => (
          <Col key={img + i} span={8} style={{ maxHeight: '100%' }}>
            <FeedBackImg src={img} alt={img} onClick={() => showModal(img)} />
          </Col>
        ))}
      </Row>

      <Modal
        visible={!!visible}
        onCancel={closeModal}
        width="95vw"
        bodyStyle={{ height: '85vh', display: 'flex', padding: 0 }}
        centered
        footer={null}
        closeIcon={
          <Button icon={<CloseOutlined />} size="small" shape="circle"></Button>
        }
      >
        <img className="feedback-carousel-img" src={visible} alt={visible} />
      </Modal>
    </>
  );
};

export default FeedbackImages;
