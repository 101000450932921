import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import Table from 'components/Table';
import SortSelect from 'components/SortSelect';

import { columns } from '../columns';
import {
  ORDER_BY_SELECT_VALUE,
  PAGE_SIZE,
  PERMISSION_ACTION,
  TABLE_PROPS
} from 'appConstants';
import { useUser } from 'stores/useUser';
import PermissionButton from 'containers/PermissionButton';
import ListCard from 'components/ListCard';

const UserList = () => {
  const { getUsers, total, users, isUsersListLoading, setDrawerVisible } =
    useUser(
      useCallback(
        ({ getUsers, total, users, isUsersListLoading, setDrawerVisible }) => ({
          getUsers,
          total,
          users,
          isUsersListLoading,
          setDrawerVisible
        }),
        []
      ),
      shallow
    );

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedRows.map(obj => obj.id),
    onChange: (_, selectedRows) => {
      return setSelectedRows(selectedRows);
    }
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-bien-tap-vien',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-bien-tap-vien',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getUsers();
  }, [getUsers, search]);

  return (
    <ListCard
      left="Danh sách biên tập viên"
      right={
        <Space>
          <PermissionButton keyAction={PERMISSION_ACTION.CREATE}>
            <Button
              onClick={() => setDrawerVisible(true)}
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </PermissionButton>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={users.map(item => ({ ...item, key: item.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: users?.length
        }}
        rowSelection={{ rowSelection }}
        loading={isUsersListLoading}
      />
    </ListCard>
  );
};

export default UserList;
