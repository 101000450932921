import React, { useCallback, useEffect, useMemo, useState } from 'react';

import shallow from 'zustand/shallow';
import { Select, Form, InputNumber } from 'antd';

import { get } from 'utils/lodash';
import { useArticleDetail } from 'stores/useArticleDetail';
import { LY_THUYET } from 'appConstants';
import { useCurrentUser } from 'stores/useCurrentUser';

const ArticleTypeSelect = ({ condition, form, ...props }) => {
  const [requiredPageNumber, setRequiredPageNumber] = useState(false);

  const { bookTypes } = useCurrentUser(
    useCallback(
      ({ bookTypes }) => ({
        bookTypes
      }),
      []
    ),
    shallow
  );

  const options = useMemo(
    () =>
      bookTypes.map(({ id, name, slug }) => ({ value: id, label: name, slug })),
    [bookTypes]
  );

  const { article } = useArticleDetail(
    useCallback(
      ({ article }) => ({
        article
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (options.length > 0) {
      const found = options.find(({ value }) => value === article?.bookTypeId);
      setRequiredPageNumber(found?.slug !== LY_THUYET);

      if (found?.slug === LY_THUYET) {
        form.setFieldsValue({ pageNo: null });
      }
    }
  }, [article, options, form]);

  const defaultValue = get(
    options.find(item => item.slug === LY_THUYET),
    'value',
    undefined
  );

  const onArticleTypeSelect = (value, record) => {
    setRequiredPageNumber(record.slug !== LY_THUYET);

    if (record.slug === LY_THUYET) {
      form.setFieldsValue({ pageNo: null });
    }
  };

  return (
    <>
      <Form.Item
        label="Loại bài"
        name="bookTypeId"
        rules={[{ required: true, message: 'Chọn thời loại bài!' }]}
      >
        <Select
          options={options}
          placeholder="Chọn loại bài"
          style={{ width: '100%' }}
          optionFilterProp="label"
          onSelect={onArticleTypeSelect}
          {...props}
          value={props.value || defaultValue}
        />
      </Form.Item>

      <Form.Item
        style={{ display: requiredPageNumber ? 'block' : 'none' }}
        label="Số trang"
        name="pageNo"
        // rules={[{ required: requiredPageNumber, message: 'Nhập số trang!' }]}
      >
        <InputNumber
          min={0}
          style={{ width: '100%' }}
          placeholder="Nhập số trang"
        />
      </Form.Item>
    </>
  );
};

export default ArticleTypeSelect;
