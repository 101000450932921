import React, { useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Space, Button } from 'antd';

import { useClass } from 'stores/useClass';
import shallow from 'zustand/shallow';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import StatusButton from 'components/StatusButton';
import PermissionButton from 'containers/PermissionButton';
import { PERMISSION_ACTION } from 'appConstants';
import TimeLine from 'components/TimeLine';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';

const EditSyllabusBtn = record => {
  const { deleteClass } = useClass(
    useCallback(
      ({ deleteClass }) => ({
        deleteClass
      }),
      []
    ),
    shallow
  );

  return (
    <Space>
      <PermissionButton keyAction={PERMISSION_ACTION.DELETE}>
        <DeleteConfirmPopup
          onDelete={() => deleteClass(record.id)}
          title={'Xoá ' + record?.name + ' ?'}
        >
          <Button type="default" size="small" icon={<DeleteOutlined />} />
        </DeleteConfirmPopup>
      </PermissionButton>
    </Space>
  );
};

const Status = ({ record }) => {
  const { updateClassStatus } = useClass(
    useCallback(
      ({ updateClassStatus }) => ({
        updateClassStatus
      }),
      []
    ),
    shallow
  );

  const onClick = key => {
    return updateClassStatus([
      {
        ...record,
        status: key
      }
    ]);
  };

  return <StatusButton status={record?.status} onClick={onClick} />;
};

const Name = (name, record) => {
  const { setClassToEdit, setDrawerVisible } = useClass(
    useCallback(
      ({ setClassToEdit, setDrawerVisible }) => ({
        setClassToEdit,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  return (
    <PermissionButton keyAction={PERMISSION_ACTION.UPDATE}>
      <TableRecordName
        name={name}
        setDrawerVisible={setDrawerVisible}
        setRecordToEdit={() => setClassToEdit(record)}
      />
    </PermissionButton>
  );
};

export const columns = [
  {
    title: 'Tên lớp',
    dataIndex: 'name',
    render: Name,
    align: 'left',
    width: '80px',
    fixed: 'left'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => <Status record={record} />,
    width: '240px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '200px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: EditSyllabusBtn,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
