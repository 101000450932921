import { Typography } from 'antd';
import React, { useMemo } from 'react';

import PublisherForm from './PublisherForm';
import PublisherList from './components/PublisherList';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import PublisheFilter from './PublisheFilter';
import { useLocation } from 'react-router';
import { generateFilterParamsCount } from 'utils/other';

const Publisher = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div className="spacing-container">
      <Typography.Title>Nhà xuất bản</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={PublisherList}
        secondary={PublisheFilter}
        filterDrawerTitle="Lọc nhà xuất bản"
      />

      <PublisherForm />
    </div>
  );
};

export default Publisher;
