import React, { useCallback, useContext } from 'react';
import ArticleInfoForm from './components/ArticleInfoForm';
import ArticleEditorForm from './components/ArticleEditorForm';
import ArticleSEOForm from './components/ArticleSEOForm';
import { ArticleDetailContext } from '..';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { useArticleDetail } from 'stores/useArticleDetail';
import shallow from 'zustand/shallow';
import moment from 'moment';
import ArticleDetailTags from './components/ArticleDetailTags';
import ArticleDetailLinkingArticle from './components/ArticleDetailLinkingArticle';
import ArticleDetailExercises from './components/ArticleDetailExercises';
import { useCurrentUser } from 'stores/useCurrentUser';
import { BAI_TAP } from 'appConstants';

const isArticleTypeValid = (id, arr) =>
  arr.find(({ id: _id }) => id === _id)?.slug === BAI_TAP;

const ArticleDetailLeft = () => {
  const { isEditingArticle, form } = useContext(ArticleDetailContext);

  const history = useHistory();
  const { createArticle, article } = useArticleDetail(
    useCallback(
      ({ createArticle, article }) => ({
        createArticle,
        article
      }),
      []
    ),
    shallow
  );

  const { bookTypes } = useCurrentUser(
    useCallback(
      ({ bookTypes }) => ({
        bookTypes
      }),
      []
    ),
    shallow
  );

  const onClick = async () => {
    try {
      const params = await form.validateFields();

      const res = await createArticle({
        ...params,
        image: params.image?.response?.preview || null,
        publishedDate: params.publishedDate || moment()
      });

      history.push('/chi-tiet-bai-hoc/' + res?.id);
    } catch (e) {
      form.setFields(
        Object.entries(e.data).map(([key, value]) => ({
          name: [key[0].toLocaleLowerCase() + key.slice(1)],
          errors: value
        }))
      );
    }
  };

  return (
    <div className="spacing-container">
      <ArticleInfoForm />

      <ArticleSEOForm />

      {isEditingArticle && <ArticleDetailTags />}

      {isEditingArticle && <ArticleDetailLinkingArticle />}

      {isEditingArticle &&
        isArticleTypeValid(article?.bookTypeId, bookTypes) && (
          <ArticleDetailExercises />
        )}

      {isEditingArticle && <ArticleEditorForm />}

      {!isEditingArticle && (
        <Button block type="primary" onClick={onClick}>
          Tạo bài viết
        </Button>
      )}
    </div>
  );
};

export default ArticleDetailLeft;
