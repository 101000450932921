import React from 'react';
import { Tag } from 'antd';
import { STATUS_DICTIONARY, STATUS_VALUE } from 'appConstants';

const generateColor = status => {
  switch (status) {
    case STATUS_VALUE.DRAFT:
      return 'orange';

    case STATUS_VALUE.APPROVED:
      return 'blue';

    case STATUS_VALUE.PUBLISHED:
      return 'green';

    default:
      break;
  }
};

const StatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {STATUS_DICTIONARY[status]}
    </Tag>
  );
};

export default StatusTag;
