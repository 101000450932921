import React, { useCallback, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useTag } from 'stores/useTag';
import shallow from 'zustand/shallow';
import SlugFormItem from 'components/SlugFormItem';

const TagFormContent = ({ form }) => {
  const { tagToEdit } = useTag(
    useCallback(
      ({ tagToEdit }) => ({
        tagToEdit
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (tagToEdit) {
      form.setFieldsValue({
        name: tagToEdit.name,
        slug: tagToEdit.slug,
        description: tagToEdit.description,
        seoTitle: tagToEdit.seoTitle,
        seoDescription: tagToEdit.seoDescription,
        seoKeyword: tagToEdit.seoKeyword
      });
    } else {
      form.resetFields();
    }
  }, [tagToEdit, form]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Tag"
        rules={[{ required: true, message: 'Nhập tag!' }]}
      >
        <Input placeholder="Nhập tag" />
      </Form.Item>

      <SlugFormItem form={form} target="name" />

      <Form.Item name="seoTitle" label="Tiêu đề SEO">
        <Input placeholder="Nhập tiêu đề SEO" />
      </Form.Item>

      <Form.Item name="seoDescription" label="Mô tả SEO">
        <Input placeholder="Nhập mô tả SEO" />
      </Form.Item>

      <Form.Item name="seoKeyword" label="Từ khoá SEO">
        <Input placeholder="Nhập từ khoá SEO" />
      </Form.Item>
    </Form>
  );
};

export default TagFormContent;
