import React from 'react';

import {
  FileManagerComponent,
  Inject,
  NavigationPane,
  DetailsView,
  Toolbar
} from '@syncfusion/ej2-react-filemanager';

const hostUrl = 'https://naviedu.namvietmedia.vn/';

const FileManager = React.forwardRef((props, ref) => {
  return (
    <div className="control-section">
      <FileManagerComponent
        ref={ref}
        id="file"
        allowMultiSelection={false}
        contextMenuSettings={{
          file: ['Open', '|', 'Cut', 'Copy', '|', 'Rename', '|', 'Details'],
          folder: [
            'Open',
            '|',
            'Cut',
            'Copy',
            'Paste',
            '|',
            'Rename',
            '|',
            'Details'
          ],
          layout: [
            'SortBy',
            'View',
            'Refresh',
            '|',
            'Paste',
            '|',
            'NewFolder',
            'Upload',
            '|',
            'Details',
            '|',
            'SelectAll'
          ],
          visible: true
        }}
        toolbarSettings={{
          items: [
            'NewFolder',
            'Upload',
            'Cut',
            'Copy',
            'Paste',
            'Download',
            'Rename',
            'SortBy',
            'Refresh',
            'Selection',
            'View',
            'Details'
          ],
          visible: true
        }}
        ajaxSettings={{
          url: hostUrl + 'api/cloud/AmazonS3Provider/AmazonS3FileOperations',
          downloadUrl: hostUrl + 'api/cloud/AmazonS3Provider/AmazonS3Download',
          uploadUrl: hostUrl + 'api/cloud/AmazonS3Provider/AmazonS3Upload',
          getImageUrl: hostUrl + 'api/cloud/AmazonS3Provider/AmazonS3GetImage'
        }}
        view={'Details'}
        {...props}
      >
        <Inject services={[NavigationPane, DetailsView, Toolbar]} />
      </FileManagerComponent>
    </div>
  );
});

export default FileManager;
