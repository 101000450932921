import React, { useCallback, useEffect, useState } from 'react';
import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';
import LoginHistoryList from 'components/LoginHistoryList';

const UserLoginHistoryContent = ({ userId }) => {
  const [items, setItems] = useState([]);
  const { getUserLoginHistory } = useUser(
    useCallback(
      ({ getUserLoginHistory }) => ({
        getUserLoginHistory
      }),
      []
    ),
    shallow
  );

  const fetchItems = useCallback(async () => {
    try {
      if (!userId) return;

      const res = await getUserLoginHistory(userId);
      setItems(res);
    } catch (e) {}
  }, [getUserLoginHistory, userId]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return <LoginHistoryList loggingHistory={items} />;
};

export default UserLoginHistoryContent;
