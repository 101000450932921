import React, { useCallback } from 'react';
import { Avatar, Spin } from 'antd';

const UserAvatar = ({
  fullName,
  defaultAvatar = <></>,
  avatar,
  loading,
  ...props
}) => {
  const generateAvatarContent = useCallback((fullName, defaultAvatar) => {
    if (!fullName || typeof fullName !== 'string') {
      return defaultAvatar;
    }

    const arr = fullName.split(' ');

    if (arr.length === 1) {
      return arr.shift()[0].toUpperCase();
    }

    return (arr.shift()[0] + arr.pop()[0]).toUpperCase();
  }, []);

  return (
    <Spin spinning={!!loading}>
      {avatar ? (
        <Avatar size={64} {...props} src={avatar} />
      ) : (
        <Avatar size={64} {...props}>
          {generateAvatarContent(fullName, defaultAvatar)}
        </Avatar>
      )}
    </Spin>
  );
};

export default UserAvatar;
