import React from 'react';
import { Form, Input } from 'antd';

import { FILTER_PARAMS_PREFIX } from 'appConstants';
import MasterDataSelect from 'containers/MasterDataSelect';

const PermissionFilter = ({ form }) => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'title'} label="Tên quyền hạn">
        <Input placeholder="Nhập tên quyền hạn" />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'code'} label="Đối tượng">
        <MasterDataSelect form={form} />
      </Form.Item>
    </>
  );
};

export default PermissionFilter;
