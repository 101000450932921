import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useKeyword = create((set, get) => ({
  isKeywordFormLoading: false,
  setIsKeywordFormLoading: isKeywordFormLoading =>
    set({ isKeywordFormLoading }),
  isKeywordListLoading: false,
  setIsKeywordListLoading: isKeywordListLoading =>
    set({ isKeywordListLoading }),

  selectedRows: [],
  setSelectedRows: selectedRows => set({ selectedRows }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //* ***************** */
  //* NOTE: get keyword */
  //* ***************** */

  keywordToEdit: null,
  setKeywordToEdit: keywordToEdit => set({ keywordToEdit }),

  updateKeyword: async (params, id) => {
    get().setIsKeywordFormLoading(true);

    try {
      const { data } = await API.put(`/keyword/${id}`, params);

      get().setIsKeywordFormLoading(false);

      if (data) {
        get().getKeywords();

        notification.success({
          message: 'Cập nhật keyword thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsKeywordFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ********************** */
  //* NOTE: create keyword */
  //* ********************** */

  createKeyword: async params => {
    get().setIsKeywordFormLoading(true);

    try {
      const { data } = await API.post('/keyword', params);

      get().setIsKeywordFormLoading(false);

      if (data) {
        notification.success({
          message: 'Tạo keyword thành công!'
        });

        get().getKeywords();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsKeywordFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ******************* */
  //* NOTE: get keywords  */
  //* ******************* */

  keywords: [],
  totalPage: 1,
  fetchKeywords: async (params = {}) => {
    try {
      const { data } = await API.get('/keyword', {
        params
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      return notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getKeywords: async (params = {}) => {
    get().setIsKeywordListLoading(true);

    try {
      const { data, total } = await get().fetchKeywords({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsKeywordListLoading(false);

      if (data) {
        set({
          total,
          keywords: data,
          totalPage: Math.ceil(total / PAGE_SIZE)
        });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsKeywordListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************* */
  //* NOTE: delete keyword  */
  //* ********************* */

  deleteKeyword: async id => {
    get().setIsKeywordListLoading(true);

    try {
      const { data } = await API.delete('/keyword/' + id);

      get().setIsKeywordListLoading(false);

      if (data) {
        if (id === get().keywordToEdit?.id) {
          get().setKeywordToEdit(null);
        }

        set({
          selectedRows: get().selectedRows.filter(
            ({ id: thisId }) => thisId !== id
          )
        });

        get().getKeywords();

        return notification.success({
          message: 'Xoá keyword thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsKeywordListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********** */
  //* NOTE: other */
  //* *********** */

  updateKeywordStatus: async params => {
    get().setIsKeywordListLoading(true);

    try {
      const { data } = await API.patch('/keyword', params);

      get().setIsKeywordListLoading(false);

      if (data) {
        get().getKeywords();

        if (params.length === 1) {
          set({
            selectedRows: get().selectedRows.filter(
              ({ id: thisId }) => thisId !== params[0]?.id
            )
          });
        }

        return notification.success({
          message: 'Chỉnh sửa keyword thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsKeywordListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
