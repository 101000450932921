import { ApiOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Space,
  Tabs,
  Typography
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRole } from 'stores/useRole';
import shallow from 'zustand/shallow';
import RoleInfo from './RoleInfo';
import RolePanel from './RolePanel';

const { TabPane } = Tabs;

const RoleTab = () => {
  const [activeKey, setActiveKey] = useState();
  const { updateRolePermission, getRoles, roles, setCurrentRole, currentRole } =
    useRole(
      useCallback(
        ({
          updateRolePermission,
          getRoles,
          roles,
          setCurrentRole,
          currentRole
        }) => ({
          updateRolePermission,
          getRoles,
          roles,
          setCurrentRole,
          currentRole
        }),
        []
      ),
      shallow
    );

  const [form] = Form.useForm();
  const isUpdateRole = useMemo(() => currentRole, [currentRole]);

  useEffect(() => {
    getRoles({}, true);
  }, [getRoles]);

  useEffect(() => {
    if (currentRole) {
      setActiveKey(currentRole.id.toString());
    }
  }, [currentRole]);

  const onChangeTab = key => {
    setActiveKey(key);
    const currentRole = roles.find(role => role.id.toString() === key);
    setCurrentRole(currentRole);
  };

  const onUpdatePermission = async () => {
    try {
      const values = await form.validateFields();
      const params = values?.permissions.filter(
        value => typeof value === 'number'
      );
      await updateRolePermission({ permissionIds: params }, currentRole.id);
    } catch (e) {}
  };

  return (
    <Tabs
      onChange={onChangeTab}
      activeKey={activeKey}
      tabBarExtraContent={{
        right: (
          <Button
            type="primary"
            ghost
            onClick={() => setCurrentRole(null)}
            disabled={!isUpdateRole}
          >
            Thêm vai trò
          </Button>
        )
      }}
      style={{ background: 'white', padding: 16, borderRadius: 4 }}
    >
      {roles.map(role => (
        <TabPane tab={role.roleName} key={role.id} disabled={!isUpdateRole}>
          <Row gutter={32}>
            <Col flex="auto">
              <Row style={{ padding: 16 }}>
                <Col span={12}>
                  <Space size="small">
                    <ApiOutlined style={{ fontSize: 16 }} />
                    <Typography.Text strong>Quyền Thao Tác</Typography.Text>
                  </Space>
                </Col>
                <Col span={12}>
                  <Row justify="space-between">
                    <Space size="small">
                      <FundProjectionScreenOutlined style={{ fontSize: 16 }} />
                      <Typography.Text strong>Quyền Màn Hình</Typography.Text>
                    </Space>
                    <Button
                      type="primary"
                      onClick={onUpdatePermission}
                      disabled={!isUpdateRole}
                    >
                      Cập nhật quyền
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Form form={form}>
                <Form.Item name="permissions">
                  <Checkbox.Group className="role-group">
                    <RolePanel form={form} />
                  </Checkbox.Group>
                </Form.Item>
              </Form>
            </Col>
            <Col>
              <RoleInfo form={form} />
            </Col>
          </Row>
        </TabPane>
      ))}
    </Tabs>
  );
};

export default RoleTab;
