import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useUser = create((set, get) => ({
  isUsersFormLoading: false,
  setIsUsersFormLoading: isUsersFormLoading => set({ isUsersFormLoading }),
  isUsersListLoading: false,
  setIsUsersListLoading: isUsersListLoading => set({ isUsersListLoading }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  //NOTE: login history
  logDrawerVisible: false,
  setLogDrawerVisible: logDrawerVisible => set({ logDrawerVisible }),

  //* ************** */
  //* NOTE: get user */
  //* ************** */
  userToEdit: null,
  setUserToEdit: userToEdit => set({ userToEdit }),
  isCreatingUser: false,
  createUser: async params => {
    get().setIsUsersFormLoading(true);

    try {
      const { data } = await API.post('/users', params);
      get().setIsUsersFormLoading(false);
      set({ isCreatingUser: false });

      if (data) {
        get().getUsers();

        notification.success({
          message: 'Tạo biên tập viên thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsUsersFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* **************** */
  //* NOTE: get users  */
  //* **************** */

  users: [],
  totalPage: 1,
  getUsers: async params => {
    get().setIsUsersListLoading(true);

    try {
      const {
        data: { data, total }
      } = await API.get('/users', {
        params: {
          PageSize: PAGE_SIZE,
          ...params,
          ...getQueryParams()
        }
      });

      get().setIsUsersListLoading(false);

      if (data) {
        set({ users: data, totalPage: Math.ceil(total / PAGE_SIZE), total });
        return data.result;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsUsersListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ****************** */
  //* NOTE: update users */
  //* ****************** */

  updateUser: async (params, id) => {
    get().setIsUsersFormLoading(true);

    try {
      const { data } = await API.put(`/users/${id}`, params);

      get().setIsUsersFormLoading(false);

      if (data) {
        get().getUsers();

        notification.success({
          message: 'Cập nhật biên tập viên thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsUsersFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw data;
    }
  },

  //* ******************* */
  //* NOTE: delete users  */
  //* ******************* */

  deleteUsers: async params => {
    try {
      const {
        data: { data }
      } = await API.delete('/user', { data: params });

      if (data) {
        get().getUsers();
        return notification.success({
          message: 'Xoá biên tập viên thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************ */
  //* NOTE: other  */
  //* ************ */

  changePassword: async (id, params) => {
    try {
      const { data } = await API.put(`/users/${id}/changepassword`, params);

      if (data) {
        return notification.success({
          message: 'Đổi mật khẩu biên tập viên thành công!'
        });
      }

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  changeUserStatus: async params => {
    try {
      const { data } = await API.patch('/users', params);

      if (data) {
        get().getUsers();

        return notification.success({
          message: 'Đổi trạng thái biên tập viên thành công!'
        });
      }

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  attachSyllabusesToUser: async (params, id) => {
    try {
      const { data } = await API.put(`/users/${id}/syllabus`, params);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getUserLoginHistory: async id => {
    try {
      const { data } = await API.get(`/users/${id}/login-history`);

      if (data) {
        return data.data;
      }

      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
