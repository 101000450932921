import { Input, Form } from 'antd';
import MasterDataSelect from 'containers/MasterDataSelect';
import React, { useCallback, useEffect } from 'react';
import { useFeedback } from 'stores/useFeedback';
import { get } from 'utils/lodash';
import shallow from 'zustand/shallow';
import FeedbackImages from './FeedbackImages';
const FeedbackDetail = () => {
  const { feedbackToView } = useFeedback(
    useCallback(
      ({ feedbackToView }) => ({
        feedbackToView
      }),
      []
    ),
    shallow
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (feedbackToView) {
      form.setFieldsValue({
        title: feedbackToView.title,
        comment: feedbackToView.comment,
        target: feedbackToView.target
      });
    }
  }, [feedbackToView, form]);

  const flag = get(feedbackToView, 'image', []);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item label="Tiêu đề" name="title">
        <Input readOnly />
      </Form.Item>

      <Form.Item label="Nội dung" name="comment">
        <Input.TextArea rows={5} readOnly />
      </Form.Item>

      <Form.Item name="target" label="Đối tượng">
        <MasterDataSelect
          open={false}
          groupCode="resource"
          placeholder="Chọn đối tượng"
          allowClear={false}
          showSearch={false}
        />
      </Form.Item>

      {flag.length !== 0 && <FeedbackImages images={feedbackToView?.image} />}
    </Form>
  );
};

export default FeedbackDetail;
