import React, { useCallback, useEffect } from 'react';
import useAuth from 'stores/useAuth';
import shallow from 'zustand/shallow';
import 'moment/locale/vi';
import LoginHistoryList from 'components/LoginHistoryList';

const LoginHistory = () => {
  const { getLoginHistory, loggingHistory } = useAuth(
    useCallback(
      ({ getLoginHistory, loggingHistory }) => ({
        getLoginHistory,
        loggingHistory
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getLoginHistory();
  }, [getLoginHistory]);

  return <LoginHistoryList loggingHistory={loggingHistory} />;
};

export default LoginHistory;
