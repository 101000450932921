import React, { useCallback, useEffect, useState } from 'react';

import shallow from 'zustand/shallow';
import { Select } from 'antd';

import { usePublisher } from 'stores/usePublisher';

const PublisherSelect = props => {
  const [options, setOptions] = useState([]);
  const { fetchPublishers } = usePublisher(
    useCallback(
      ({ fetchPublishers }) => ({
        fetchPublishers
      }),
      []
    ),
    shallow
  );

  const handleFetchRoles = useCallback(async () => {
    const { data } = await fetchPublishers({
      PageSize: 1000,
      Page: 0
    });

    setOptions(data.map(({ id, name }) => ({ value: id + '', label: name })));
  }, [fetchPublishers]);

  useEffect(() => {
    handleFetchRoles();
  }, [handleFetchRoles]);

  return (
    <Select
      options={options}
      placeholder="Chọn nhà xuất bản"
      style={{ width: '100%' }}
      allowClear
      showSearch
      optionFilterProp="label"
      {...props}
    />
  );
};

export default PublisherSelect;
