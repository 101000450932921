import { Col, Row, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import ArticleQuantity from './ArticleQuantity';
import Articles from './Articles';
import Feedback from './Feedback';

import UsersContainer from './UsersContainer';

const Dashboard = () => {
  const { getDashboard, feedbackStatus, users, articles } = useDashboard(
    useCallback(
      ({ getDashboard, feedbackStatus, users, articles }) => ({
        getDashboard,
        feedbackStatus,
        users,
        articles
      }),
      []
    ),
    shallow
  );
  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  return (
    <div>
      <Typography.Title>Dashboard</Typography.Title>
      <ArticleQuantity />
      <Row gutter={16}>
        <Col span={12}>{feedbackStatus && <Feedback />}</Col>
        <Col span={12}>{users ? <UsersContainer /> : null}</Col>
      </Row>
      {articles && <Articles articles={articles} />}
    </div>
  );
};

export default Dashboard;
