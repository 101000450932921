import { Input, Modal, Typography, Space, Button } from 'antd';
import React, { useMemo, useState } from 'react';
import converter from 'mathml2latex';

import { MathComponent } from 'mathjax-react';
const MathMLConvertModal = ({ visible, closeModal, pushContent }) => {
  const [content, setContent] = useState('');

  const convertedContent = useMemo(() => {
    try {
      return content ? converter.convert(content) : null;
    } catch (e) {
      return null;
    }
  }, [content]);

  const onOk = isMathJax => {
    if (!convertedContent) {
      return;
    }

    const target = isMathJax
      ? document.getElementById('math-ml-preview-mjx')
      : document.getElementById('math-ml-preview-latex');
    if (!target) return;

    target.removeAttribute('id');
    target.removeAttribute('style');

    let htmlString = '';

    if (isMathJax) {
      htmlString = target.children[0].innerHTML;
    } else {
      htmlString = target.outerHTML;
    }

    pushContent(htmlString);
    closeModal();
    setContent('');
  };

  const closeFileManagerModal = () => {
    closeModal();
    setContent('');
  };

  const onInputChange = ({ target: { value } }) => {
    try {
      setContent(value);
    } catch (e) {}
  };

  return (
    <Modal
      maskClosable={false}
      keyboard={true}
      visible={visible}
      onOk={onOk}
      onCancel={closeFileManagerModal}
      title={'Thêm MathML'}
      cancelText="Hủy"
      width="90%"
      destroyOnClose
      footer={
        <Space>
          <Button onClick={closeFileManagerModal}>Cancel</Button>

          <Button type="primary" onClick={() => onOk(false)}>
            Thêm bằng LateX
          </Button>

          <Button type="primary" onClick={() => onOk(true)}>
            Thêm bằng MathJax
          </Button>
        </Space>
      }
    >
      <Typography.Title level={5}>Nhập MathML:</Typography.Title>

      <Input.TextArea
        rows={10}
        style={{ marginBottom: 16 }}
        value={content}
        onChange={onInputChange}
      />

      <Typography.Title level={5}>Công thức LaTex:</Typography.Title>

      <Input.TextArea
        rows={2}
        style={{ marginBottom: 16 }}
        value={convertedContent}
        onChange={onInputChange}
        readOnly
      />

      {convertedContent && (
        <>
          <Typography.Title level={5}>Preview LaTeX:</Typography.Title>

          <img
            id="math-ml-preview-latex"
            alt={convertedContent}
            src={`https://latex.codecogs.com/gif.latex?${convertedContent}`}
          />
        </>
      )}

      {content && (
        <>
          <Typography.Title level={5} style={{ marginTop: 16 }}>
            Preview MathJax:
          </Typography.Title>

          <span id="math-ml-preview-mjx">
            <MathComponent
              display={false}
              mathml={content}
              onError={e => {
                return e;
              }}
            />
          </span>
        </>
      )}
    </Modal>
  );
};

export default MathMLConvertModal;
