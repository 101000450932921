import { Form } from 'antd';
import { FILTER_PARAMS_PREFIX } from 'appConstants';
import ClassSelect from 'containers/ClassSelect';
import SubjectSelect from 'containers/SubjectSelect/SubjectSelect';
import React from 'react';

const ClassSubjectFilter = () => {
  return (
    <>
      <Form.Item name={FILTER_PARAMS_PREFIX + 'classId'} label="Lớp">
        <ClassSelect />
      </Form.Item>

      <Form.Item name={FILTER_PARAMS_PREFIX + 'subjectId'} label="Môn học">
        <SubjectSelect />
      </Form.Item>
    </>
  );
};

export default ClassSubjectFilter;
