import { Select } from 'antd';
import React from 'react';

const options = [
  {
    label: 'Âm nhạc',
    value: 'amnhac'
  },
  {
    label: 'Công nghệ',
    value: 'congnghe'
  },
  {
    label: 'Địa lý',
    value: 'dialy'
  },
  {
    label: 'Giáo dục công dân',
    value: 'giaoduccongdan'
  },
  {
    label: 'Giáo dục thể chất',
    value: 'giaoducthechat'
  },
  {
    label: 'Hóa học',
    value: 'hoahoc'
  },
  {
    label: 'Hoạt động trải nghiệm hướng nghiệp',
    value: 'hoatdongtrainghiemhuongnghiep'
  },
  {
    label: 'Khoa học tự nhiên',
    value: 'khoahoctunhien'
  },
  {
    label: 'Mỹ thuật',
    value: 'mythuat'
  },
  {
    label: 'Ngữ văn',
    value: 'nguvan'
  },
  {
    label: 'Sinh học',
    value: 'sinhhoc'
  },
  {
    label: 'Tiếng anh',
    value: 'tienganh'
  },
  {
    label: 'Tin học',
    value: 'tinhoc'
  },
  {
    label: 'Toán',
    value: 'toan'
  },
  {
    label: 'Tự nhiên xã hội',
    value: 'tunhienxahoi'
  },
  {
    label: 'Vật lý',
    value: 'vatly'
  },
  {
    label: 'Lịch sử',
    value: 'lichsu'
  }
];

const SubjectImgSelect = ({ ...props }) => {
  return (
    <Select
      options={options}
      placeholder="Chọn vai trò"
      style={{ width: '100%' }}
      allowClear
      showSearch
      optionFilterProp="label"
      {...props}
    />
  );
};

export default SubjectImgSelect;
