import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { useSyllabus } from 'stores/useSyllabus';
import shallow from 'zustand/shallow';
import { Empty, Typography } from 'antd';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MenuOutlined } from '@ant-design/icons';

const reOrder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SyllabusOrderContent = React.forwardRef(({ classId, subjectId }, ref) => {
  useImperativeHandle(ref, () => ({
    syllabuses
  }));

  const [syllabuses, setSyllabuses] = useState([]);

  const { fetchSyllabuses } = useSyllabus(
    useCallback(
      ({ fetchSyllabuses }) => ({
        fetchSyllabuses
      }),
      []
    ),
    shallow
  );

  const handleFetchSyllabuses = useCallback(async () => {
    if (classId && subjectId) {
      const { data } = await fetchSyllabuses({
        PageSize: 1000,
        Sort: 'Priority',
        Page: 0,
        classId,
        subjectId
      });

      setSyllabuses(
        data.map(({ id, ...other }) => ({ id: id + '', ...other })).reverse()
      );
    }
  }, [fetchSyllabuses, classId, subjectId]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    setSyllabuses(syllabuses =>
      reOrder(syllabuses, result.source.index, result.destination.index)
    );
  };

  useEffect(() => {
    handleFetchSyllabuses();
  }, [handleFetchSyllabuses]);

  return syllabuses.length === 0 ? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {syllabuses.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="subject-dnd-item">
                      <span className="subject-dnd-index">{index + 1}</span>

                      {/* <UserAvatar
                        avatar={item.image}
                        fullName={item.name}
                        size={40}
                        className="subject-dnd-item-img"
                      /> */}

                      <Typography.Text className="subject-dnd-item-text">
                        {item.name}
                      </Typography.Text>

                      <MenuOutlined className="subject-dnd-item-icon" />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default SyllabusOrderContent;
