import { Collapse, Typography, Row, Button, Form } from 'antd';
import React, { useCallback, useContext, useEffect } from 'react';
import { useArticleDetail } from 'stores/useArticleDetail';

import shallow from 'zustand/shallow';
import ArticleAutoComplete from 'containers/ArticleAutoComplete';
import { ArticleDetailContext } from 'pages/ArticleDetail';

const ArticleDetailLinkingArticle = () => {
  const { form } = useContext(ArticleDetailContext);
  const { linkArticle, article } = useArticleDetail(
    useCallback(
      ({ linkArticle, article }) => ({
        linkArticle,
        article
      }),
      []
    ),
    shallow
  );

  const onSave = () => {
    const { subArticleIds } = form.getFieldsValue();

    linkArticle(article?.id, subArticleIds);
  };

  useEffect(() => {
    if (article?.relatedArticles && article.relatedArticles.length > 0) {
      form.setFieldsValue({
        subArticleIds: article.relatedArticles.map(({ id }) => id)
      });
    }
  }, [article, form]);

  const defaultValue =
    article?.relatedArticles && article.relatedArticles.length > 0
      ? article.relatedArticles
      : null;

  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel
        header={<Typography.Text strong>Bài học liên kết</Typography.Text>}
        key="1"
      >
        <>
          <Form.Item name="subArticleIds">
            <ArticleAutoComplete
              article={defaultValue}
              disableValue={article?.id}
              mode="multiple"
            />
          </Form.Item>

          <Row justify="end">
            <Button onClick={onSave} type="primary">
              Lưu
            </Button>
          </Row>
        </>
      </Collapse.Panel>
    </Collapse>
  );
};

export default ArticleDetailLinkingArticle;
