import React from 'react';

import { Typography } from 'antd';
import ClassList from './components/ClassList';
import ClassForm from './ClassForm';

const Class = props => {
  return (
    <div className="spacing-container">
      <Typography.Title>Lớp</Typography.Title>

      <ClassList />

      <ClassForm />
    </div>
  );
};

export default Class;
