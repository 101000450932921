import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType
} from '@microsoft/signalr';

export const setupSignalRConnection = async (
  connectionHub,
  actionEventMap = []
) => {
  console.log('setupSignalRConnection');
  const options = {
    transport: HttpTransportType.WebSockets
  };
  const connection = new HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .configureLogging(LogLevel.Information)
    .build();

  actionEventMap.forEach(({ code, callback }) => {
    connection.on(code, callback);
  });

  // connection.on('ReceiveMessage', (user, message) => {
  //   console.log({ user, message });
  // });

  // connection.on('CountMember', message => {
  //   console.log({ message });
  // });

  await connection.start();

  return connection;
};
