import { Button, Collapse, Row, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ArticleAutoComplete from 'containers/ArticleAutoComplete';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useExerciseDetail } from 'stores/useExerciseDetail';
import shallow from 'zustand/shallow';
import { BAI_TAP } from 'appConstants';
import { useCurrentUser } from 'stores/useCurrentUser';

const ExerciseDetailArticle = ({ exerciseId }) => {
  const [value, setValue] = useState([]);
  const [articles, setArticles] = useState([]);

  const { getArticlesByExercise, attachExerciseToArticles } = useExerciseDetail(
    useCallback(
      ({ getArticlesByExercise, attachExerciseToArticles }) => ({
        getArticlesByExercise,
        attachExerciseToArticles
      }),
      []
    ),
    shallow
  );

  const { bookTypes } = useCurrentUser(
    useCallback(
      ({ bookTypes }) => ({
        bookTypes
      }),
      []
    ),
    shallow
  );

  const onChange = v => {
    setValue(v);
  };

  const fetchValue = useCallback(async () => {
    try {
      const data = await getArticlesByExercise(exerciseId);

      setValue(data.map(({ id }) => id));
      setArticles(data);
    } catch (e) {
      throw e;
    }
  }, [getArticlesByExercise, exerciseId]);

  useEffect(() => {
    fetchValue();
  }, [fetchValue]);

  const onSave = async () => {
    try {
      await attachExerciseToArticles(value, exerciseId);
    } catch (e) {
      throw e;
    }
  };

  const bookTypeCondition = useMemo(
    () => bookTypes.find(({ slug }) => slug === BAI_TAP)?.id,
    [bookTypes]
  );

  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel key="1" header="Thêm vào bài viết">
        <Row style={{ marginBottom: 8 }}>
          <ArticleAutoComplete
            onChange={onChange}
            mode="multiple"
            value={value}
            article={articles}
            bookType={bookTypeCondition}
          />
        </Row>

        <Tag color="blue">
          <InfoCircleOutlined /> Chỉ hiển thị những bài viết có loại bài là "Bài
          tập"
        </Tag>

        <Button type="primary" block style={{ marginTop: 24 }} onClick={onSave}>
          Lưu
        </Button>
      </Collapse.Panel>
    </Collapse>
  );
};

export default ExerciseDetailArticle;
