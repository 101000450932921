import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { debounce } from 'utils/lodash';
import { DEBOUNCE_VALUE } from 'appConstants';

const AutoComplete = ({
  defaultOption,
  labelKey,
  valueKey,
  handleSearch,
  disableValue,
  ...props
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (Array.isArray(defaultOption)) {
      defaultOption && setOptions(defaultOption);
    } else {
      defaultOption && setOptions([defaultOption]);
    }
  }, [defaultOption]);

  const onSearch = async searchText => {
    if (!searchText) {
      return;
    }

    const results = await handleSearch(searchText);

    if (!results) {
      return;
    }

    return setOptions(
      results.map(result => ({
        label: result[labelKey],
        value: result[valueKey]
      }))
    );
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const renderOptions = () => {
    return options.map(({ value, label }) => (
      <Select.Option
        value={value}
        key={value}
        disabled={disableValue === value}
      >
        {label}
      </Select.Option>
    ));
  };

  return (
    <Select
      filterOption={false}
      notFoundContent={null}
      showSearch
      onSearch={debouncedOnSearch}
      {...props}
    >
      {renderOptions()}
    </Select>
  );
};

export default AutoComplete;
