import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Space } from 'antd';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import shallow from 'zustand/shallow';

import { columns } from '../columns';
import { useSection } from 'stores/useSection';

import {
  ORDER_BY_SELECT_VALUE,
  PAGE_SIZE,
  PERMISSION_ACTION
} from 'appConstants';

import Table from 'components/Table';
import SortSelect from 'components/SortSelect';
import PermissionButton from 'containers/PermissionButton';
import { PlusOutlined } from '@ant-design/icons';
import ListCard from 'components/ListCard';

const SectionList = () => {
  const {
    getSections,
    sections,
    isSectionListLoading,
    total,
    setDrawerVisible
  } = useSection(
    useCallback(
      ({
        getSections,
        sections,
        isSectionListLoading,
        total,
        setDrawerVisible
      }) => ({
        getSections,
        sections,
        isSectionListLoading,
        total,
        setDrawerVisible
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-phan',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-phan',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    getSections();
  }, [getSections, search]);

  return (
    <ListCard
      left="Danh sách phần"
      right={
        <Space>
          <PermissionButton keyAction={PERMISSION_ACTION.CREATE}>
            <Button
              onClick={() => setDrawerVisible(true)}
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </PermissionButton>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={sections.map(section => ({ ...section, key: section.id }))}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: sections?.length
        }}
        rowSelection={{
          rowSelection
        }}
        loading={isSectionListLoading}
      />
    </ListCard>
  );
};

export default SectionList;
