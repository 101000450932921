import React, { useMemo } from 'react';
import { Typography } from 'antd';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';

import { useLocation } from 'react-router';

import ModuleList from './components/ModuleList';
import ModuleFilter from './ModuleFilter';
import ModuleForm from './ModuleForm';
import { generateFilterParamsCount } from 'utils/other';

const Module = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div>
      <Typography.Title>Chương</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={ModuleList}
        secondary={ModuleFilter}
        filterDrawerTitle="Lọc chương"
      />

      <ModuleForm />
    </div>
  );
};

export default Module;
