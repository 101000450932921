import AutoComplete from 'components/AutoComplete';
import { useServices } from 'hooks/useServices';
import React, { useCallback, useMemo } from 'react';
import shallow from 'zustand/shallow';

const ExerciseAutoComplete = ({ exercise, ...props }) => {
  const { searchForExercises } = useServices(
    useCallback(
      ({ searchForExercises }) => ({
        searchForExercises
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      exercise
        ? Array.isArray(exercise)
          ? exercise.map(({ id, title }) => ({ value: id, label: title }))
          : { value: exercise.id, label: exercise.title }
        : null,
    [exercise]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeholder="Tìm bài tập"
      handleSearch={searchForExercises}
      labelKey="title"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ExerciseAutoComplete;
