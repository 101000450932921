import create from 'zustand';
import API from 'api';

import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

export const useSyllabusDetail = create((set, get) => ({
  isSyllabusDetailLoading: false,
  setIsSyllabusDetailLoading: isSyllabusDetailLoading =>
    set({ isSyllabusDetailLoading }),

  //* ****************** */
  //* NOTE: get syllabus */
  //* ****************** */

  syllabus: null,

  getSyllabus: async id => {
    get().setIsSyllabusDetailLoading(true);

    try {
      const { data } = await API.get(`/syllabus/${id}`);

      get().setIsSyllabusDetailLoading(false);

      if (data) {
        set({ syllabus: data });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSyllabusDetailLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
