import React, { useCallback, useEffect, useState } from 'react';

import shallow from 'zustand/shallow';
import { Select } from 'antd';

import { useSubject } from 'stores/useSubject';

const SubjectSelect = ({ condition, ...props }) => {
  const [options, setOptions] = useState([]);
  const { fetchSubjects } = useSubject(
    useCallback(
      ({ fetchSubjects }) => ({
        fetchSubjects
      }),
      []
    ),
    shallow
  );

  const handleFetchSubjects = useCallback(async () => {
    const { data } = await fetchSubjects({
      PageSize: 1000,
      Page: 0,
      ClassId: condition
    });

    setOptions(data.map(({ id, name }) => ({ value: id + '', label: name })));
  }, [fetchSubjects, condition]);

  useEffect(() => {
    handleFetchSubjects();
  }, [handleFetchSubjects]);

  return (
    <Select
      options={options}
      placeholder="Chọn môn học"
      style={{ width: '100%' }}
      allowClear
      showSearch
      optionFilterProp="label"
      {...props}
    />
  );
};

export default SubjectSelect;
