import create from 'zustand';
import API from 'api';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { getQueryParams } from 'utils/other';

export const useSection = create((set, get) => ({
  isSectionFormLoading: false,
  setIsSectionFormLoading: isSectionFormLoading =>
    set({ isSectionFormLoading }),
  isSectionListLoading: false,
  setIsSectionListLoading: isSectionListLoading =>
    set({ isSectionListLoading }),

  //* ***************** */
  //* NOTE: get section */
  //* ***************** */

  sectionToEdit: null,
  setSectionToEdit: sectionToEdit => set({ sectionToEdit }),

  drawerVisible: false,
  setDrawerVisible: drawerVisible => set({ drawerVisible }),

  updateSection: async (params, id) => {
    get().setIsSectionFormLoading(true);

    try {
      const { data } = await API.put(`/section/${id}`, params);

      get().setIsSectionFormLoading(false);

      if (data) {
        get().getSections();

        notification.success({
          message: 'Cập nhật phần thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSectionFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************** */
  //* NOTE: create section */
  //* ******************** */

  createSection: async params => {
    get().setIsSectionFormLoading(true);

    try {
      const { data } = await API.post('/section', params);

      get().setIsSectionFormLoading(false);

      if (data) {
        notification.success({
          message: 'Tạo phần thành công!'
        });

        get().getSections();

        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSectionFormLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: get sections  */
  //* ******************* */

  sections: [],
  totalPage: 1,
  fetchSections: async (params = {}) => {
    try {
      const { data } = await API.get('/section', {
        params
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSectionListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getSections: async (params = {}) => {
    get().setIsSectionListLoading(true);

    try {
      const res = await get().fetchSections({
        PageSize: PAGE_SIZE,
        ...params,
        ...getQueryParams()
      });

      get().setIsSectionListLoading(false);

      if (res) {
        const { total, data } = res;
        set({ total, sections: data, totalPage: Math.ceil(total / PAGE_SIZE) });
        return res;
      }

      notification.error({
        message: res?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSectionListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************* */
  //* NOTE: delete section  */
  //* ********************* */

  deleteSection: async id => {
    get().setIsSectionListLoading(true);

    try {
      const { data } = await API.delete('/section/' + id);

      get().setIsSectionListLoading(false);

      if (data) {
        if (id === get().sectionToEdit?.id) {
          get().setSectionToEdit(null);
        }

        get().getSections();

        return notification.success({
          message: 'Xoá phần thành công!'
        });
      }

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsSectionListLoading(false);

      notification.error({
        message: data?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
