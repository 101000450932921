import { Typography } from 'antd';
import DynamicFlexLayout from 'containers/DynamicFlexLayout';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { generateFilterParamsCount } from 'utils/other';
import ClassSubjectFilter from './ClassSubjectFilter';
import ClassSubjectForm from './ClassSubjectForm';
import ClassSubjectList from './ClassSubjectList';

const ClassSubject = () => {
  const { search } = useLocation();

  const count = useMemo(() => generateFilterParamsCount(search), [search]);

  return (
    <div className="spacing-container">
      <Typography.Title>Môn - Lớp</Typography.Title>

      <DynamicFlexLayout
        badgeCount={count}
        primary={ClassSubjectList}
        secondary={ClassSubjectFilter}
        filterDrawerTitle="Lọc môn - lớp"
      />

      <ClassSubjectForm />
    </div>
  );
};

export default ClassSubject;
