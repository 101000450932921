import React from 'react';
import { Tabs } from 'antd';

import PersonalInfo from './components/PersonalInfo';
import RoleInfo from './components/RoleInfo';
import { CommonContainer } from 'components/StyledComponents';
import { useHistory, useParams } from 'react-router-dom';
import ChangePassword from './components/ChangePassword';
import CurrentUserAvatar from './components/CurrentUserAvatar';
import LoginHistory from './LoginHistory';
// import DepartmentInfo from './DepartmentInfo';
// import LoginHistory from './LoginHistory';

const { TabPane } = Tabs;

const PERSONAL_INFO_URL_VALUES = {
  PERSONAL_INFO: 'thong-tin-ca-nhan',
  DEPARTMENT: 'phong-ban',
  ROLE: 'vai-tro',
  LOGIN_HISTORY: 'lich-su-dang-nhap'
};

const CurrentUserDetail = () => {
  const history = useHistory();
  const { id } = useParams();

  const onChange = value => history.push('/ca-nhan/' + value);

  return (
    <>
      <CurrentUserAvatar />

      <CommonContainer>
        <Tabs
          onChange={onChange}
          activeKey={id}
          type="card"
          className="current-user-tabs"
        >
          <TabPane
            tab="Thông tin cá nhân"
            key={PERSONAL_INFO_URL_VALUES.PERSONAL_INFO}
          >
            <>
              <PersonalInfo />
              <ChangePassword />
            </>
          </TabPane>

          <TabPane tab="Vai trò" key={PERSONAL_INFO_URL_VALUES.ROLE}>
            <RoleInfo />
          </TabPane>
          {/* 
          <TabPane tab="Phòng ban" key={PERSONAL_INFO_URL_VALUES.DEPARTMENT}>
            <DepartmentInfo />
          </TabPane> */}

          <TabPane
            tab="Lịch sử đăng nhập"
            key={PERSONAL_INFO_URL_VALUES.LOGIN_HISTORY}
          >
            <LoginHistory />
          </TabPane>
        </Tabs>
      </CommonContainer>
    </>
  );
};

export default CurrentUserDetail;
